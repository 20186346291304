import { defineStore } from "pinia";
import { adminManager } from "@/services/admin/admin-manager";

export const useSkillStore = defineStore({
  id: "Skill",
  state: () => {
    return {
      skillConfig: [],
    };
  },
  actions: {
    fetchAdminSkillConfig() {
      return adminManager.getSkillConfig().then((response) => {
        this.skillConfig = response;
      });
    },
  },
});
