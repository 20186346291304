<template>
  <div class="widget">
    <div class="widget-heading">
      <h2 class="textStyle">
        {{ $t("admin.news.list.title") }} {{ isAdminRoute() ? "STUDEA" : "" }}
      </h2>
      <div
        v-if="isAdminRoute()"
        class="d-flex align-items-center"
        v-tooltip="{ title: 'Activer les notifications' }"
      >
        <FontAwesomeIcon class="iconStyle fs-5" icon="fa-light fa-bell" />
        <SwitchTypeComponent :model="notifModel" />
      </div>
    </div>
    <div class="widget-content">
      <div class="news">
        <div class="news__item">
          <NewsCardComponent
            v-for="item in news"
            :key="item"
            :news="item"
          ></NewsCardComponent>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <router-link
          v-if="!loading"
          :to="{ name: isAdminRoute() ? 'adminNewsList' : 'newsList' }"
          class="btn btnStyle btn-success"
          >{{ $t("admin.news.list.see_all_news") }}
        </router-link>
      </div>
    </div>
    <BlockLoader :loading="loading"></BlockLoader>
  </div>
</template>

<script>
import { dateFormatter } from "@/services/utilities/date-formatter";
import SwitchTypeComponent from "@/components/form/SwitchTypeComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import NewsCardComponent from "@/components/blocks/NewsCardComponent.vue";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { routeManager } from "@/services/utilities/route-manager";

export default {
  name: "NewsBlockComponent",
  props: {
    news: {
      type: Array,
      required: true,
    },
    loading: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  components: {
    FontAwesomeIcon,
    SwitchTypeComponent,
    NewsCardComponent,
    BlockLoader,
  },
  data() {
    return {
      notifModel: {
        vars: {
          name: "notif",
          id: "notif",
          block_prefixes: ["", "switch"],
        },
      },
    };
  },
  methods: {
    format(date) {
      return dateFormatter.format(date, "short");
    },

    isAdminRoute() {
      return routeManager.isNewsAdminRoute(this.$route.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.news {
  &__item {
    .news__card:first-child {
      margin-top: 0;
    }
  }
}
</style>
