<template>
  <div class="container-fluid">
    <SideMenuComponent :menu="menu" />
    <div class="content-wrapper">
      <ErrorView v-if="errorStatus" />
      <router-view v-else></router-view>
    </div>
  </div>
</template>

<script>
import SideMenuComponent from "@/components/SideMenuComponent.vue";
import { mapActions, mapState } from "pinia";
import ErrorView from "@/views/Error/ErrorView.vue";
import { colorManager } from "@/services/platform/color-manager";
import { trainingCourseManager } from "@/services/training-course/training-course-manager";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { useStudeaManagerStore } from "@/store/studea_manager/studea-manager";
import { useErrorStore } from "@/store/error/error";
import { usePlatformStore } from "@/store/platform/platform";
import { useMainStore } from "@/store/main/main";

export default {
  name: "LayoutView",
  components: {
    ErrorView,
    SideMenuComponent,
  },
  computed: {
    ...mapState(useTrainingCourseStore, {
      trainingCourseList: (store) => store.trainingCourseList,
      trainingCourse: (store) => store.trainingCourse,
    }),
    ...mapState(useStudeaManagerStore, {
      menu: (store) => store.menu,
    }),
    ...mapState(useErrorStore, {
      errorStatus: (store) => store.status,
    }),
    ...mapState(usePlatformStore, {
      design: (store) => store.design,
      needUpdate: (store) => store.needUpdate,
    }),
  },
  beforeRouteUpdate() {
    this.getMenu(this.$route.params.trainingCourseId, false);
  },
  created() {
    this.getMenu(this.$route.params.trainingCourseId, true);
  },
  mounted() {
    if (this.needUpdate) {
      this.getConfig();
    }
    localStorage.setItem(
      "studea-oldTrainingCourse",
      this.$route.params.trainingCourseId,
    );
    if (
      Object.keys(this.trainingCourseList).length &&
      this.trainingCourse &&
      this.trainingCourse.id !== parseInt(this.$route.trainingCourseId)
    ) {
      useTrainingCourseStore().fetchTrainingCourseTriads({
        id: parseInt(this.$route.params.trainingCourseId),
        toggle: true,
        archive: 1,
      });
    }

    if (
      !this.trainingCourseList ||
      !Object.keys(this.trainingCourseList).length
    ) {
      useTrainingCourseStore()
        .fetchTrainingCourseList({})
        .then(() => {
          useMainStore().setAppLoading(false);
          if (this.trainingCourse) {
            this.setCfaDesign();
          }
        });
    }

    if (this.trainingCourse && Object.keys(this.trainingCourse).length) {
      this.setCfaDesign();
    }
  },

  updated() {
    if (this.needUpdate) {
      this.getConfig();
    }
  },

  methods: {
    getMenu(id, refresh) {
      useStudeaManagerStore().fetchStudeaManagerMenu({
        trainingCourseId: id,
        refresh: refresh,
      });
    },

    setCfaDesign() {
      colorManager.setPlatformStyle(this.trainingCourse);
      trainingCourseManager.setNewCfaLogo(this.trainingCourse);
    },
    getConfig() {
      usePlatformStore().fetchConfig();
      usePlatformStore().setNeedUpdate(false);
    },

    ...mapActions(useTrainingCourseStore, {
      fetchTrainingCourseTriads: "fetchTrainingCourseTriads",
      fetchTrainingCourseList: "fetchTrainingCourseList",
    }),
    ...mapActions(useMainStore, { setAppLoading: "setAppLoading" }),
    ...mapActions(useStudeaManagerStore, {
      fetchStudeaManagerMenu: "fetchStudeaManagerMenu",
    }),
    ...mapActions(usePlatformStore, {
      fetchConfig: "fetchConfig",
      setNeedUpdate: "setNeedUpdate",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
