<template>
  <div v-for="action in actions" :key="action" class="d-inline-block">
    <div
      v-if="
        (action.condition && row[action.condition] === action.conditionValue) ||
        (!action.condition && !action.conditionValue)
      "
    >
      <button
        v-if="!action.actionType || action.actionType === 'button'"
        :class="setColor(action)"
        v-access:[permissions]="action.access"
        v-tooltip="{
          title: setTooltip(action),
        }"
        @click="clickAction(action, row, id)"
      >
        <FontAwesomeIcon :icon="setIcon(action)" />
      </button>
      <router-link
        v-else-if="action.actionType === 'router'"
        v-access:[permissions]="action.access"
        :class="
          'btn me-2 mt-2 btn-' +
          action.type +
          (action.cfaStyle ? ' btnStyle' : '')
        "
        :to="{
          name: action.action,
          params: { id: id, alias: action.alias },
          query: action.query ? action.query : {},
        }"
        v-tooltip="{
          title: $t(
            action.translation,
            translationParams(action.translationParams, row),
          ),
        }"
      >
        <FontAwesomeIcon :icon="'fa-light fa-' + action.icon" />
      </router-link>
      <a
        v-else-if="action.actionType === 'externalLink' && action.downloadKey"
        v-access:[permissions]="action.access"
        :class="
          'btn me-2 mt-2 btn-' +
          action.type +
          (action.cfaStyle ? ' btnStyle' : '')
        "
        :href="getDownloadSrc(row, action.downloadKey)"
        v-tooltip="{
          title: $t(
            action.translation,
            translationParams(action.translationParams, row),
          ),
        }"
        target="_blank"
      >
        <FontAwesomeIcon :icon="'fa-light fa-' + action.icon" />
      </a>
    </div>
  </div>
</template>

<script>
import { fileManager } from "@/services/file/file-manager";
import { mapState } from "pinia";
import { useUserStore } from "@/store/user/user";

export default {
  name: "ListActionsBlockComponent",
  props: {
    actions: {
      type: Array,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
  },
  emits: ["click"],
  computed: {
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
    }),
  },
  methods: {
    translationParams(params, row) {
      let response = {};
      if (params && Object.keys(params).length) {
        Object.entries(params).forEach(([key, value]) => {
          response[key] = row[value];
        });
      }
      return response;
    },

    clickAction(action, row, id) {
      this.$emit("click", { action: action, row: row, id: id });
    },

    getDownloadSrc(row, downloadKey) {
      return fileManager.getDownloadSrc(row[downloadKey]);
    },

    setColor(action) {
      if (
        action.secondaryType &&
        action.switchCondition &&
        action.referenceIndex &&
        this.row[action.referenceIndex] === action.switchCondition
      ) {
        return (
          "btn me-2 mt-2 btn-" +
          action.secondaryType +
          (action.cfaStyle ? " btnStyle" : "") +
          " bg-" +
          (action.value === "boolean"
            ? this.row[action.field]
              ? "success"
              : "warning"
            : action.secondaryType)
        );
      } else {
        return (
          "btn me-2 mt-2 btn-" +
          action.type +
          (action.cfaStyle ? " btnStyle" : "") +
          " bg-" +
          (action.value === "boolean"
            ? this.row[action.field]
              ? "success"
              : "warning"
            : action.type)
        );
      }
    },

    setTooltip(action) {
      if (
        action.secondaryTranslation &&
        action.switchCondition &&
        action.referenceIndex &&
        this.row[action.referenceIndex] === action.switchCondition
      ) {
        return this.$t(
          action.secondaryTranslation,
          this.translationParams(action.translationParams, this.row),
        );
      } else {
        return this.$t(
          action.translation,
          this.translationParams(action.translationParams, this.row),
        );
      }
    },

    setIcon(action) {
      if (
        action.secondaryIcon &&
        action.switchCondition &&
        action.referenceIndex &&
        this.row[action.referenceIndex] === action.switchCondition
      ) {
        return (
          "fa-light fa-" +
          (action.value === "boolean"
            ? this.row[action.field]
              ? "check"
              : "ban"
            : action.secondaryIcon)
        );
      } else {
        return (
          "fa-light fa-" +
          (action.value === "boolean"
            ? this.row[action.field]
              ? "check"
              : "ban"
            : action.icon)
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  width: 15px !important;
  height: 15px !important;
}
</style>
