<template>
  <div v-if="answer" :class="'ps-3 ps-md-2 gridRow --' + answer.writerProfile">
    <div class="row align-items-center">
      <div
        class="col-md-3 textStyle mb-2 my-md-0 -text__mobile__bold"
        v-html="answer.writerName"
      ></div>
      <div class="col-md-9">
        <div class="gridRow__values">
          <div
            v-for="(column, index) in cluster.valueList"
            :key="column"
            class="gridRow__value"
          >
            <div class="textStyle icon__container">
              <FontAwesomeIcon
                v-if="
                  isCheckboxRadio &&
                  answer.arrayValue.answers[index] === column.value
                "
                icon="fa-light fa-check"
              />
              <span v-else>{{ answer.arrayValue.answers[index] }}</span>
            </div>
            <div class="textStyle d-block d-md-none">
              {{ cluster.valueList[index].value }}
            </div>
          </div>
          <div v-if="cluster.comments" class="gridRow__value comments"></div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>--</div>
</template>

<script>
export default {
  name: "QuestionnaireRowReplyComponent",
  // components: { FontAwesomeIcon },
  props: {
    answer: {
      type: Array,
      required: true,
    },
    cluster: {
      type: Array,
      required: true,
    },
    row: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isCheckboxRadio() {
      return [3, 4].includes(this.cluster.type);
    },
  },
};
</script>

<style lang="scss" scoped>
.gridRow {
  padding: 10px 20px 10px 0;
  position: relative;
  border: none;

  &.--2 {
    background: $light-blue;
  }

  &.--1 {
    background: $light-green;
  }

  &.--3 {
    background: $light-red;
  }

  &.--4 {
    background: $light-orange;
  }

  &__values {
    display: flex;
    align-items: center;

    @include up-to-md {
      flex-direction: column;
      align-items: start;
    }
  }

  &__value {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    @include up-to-md {
      align-items: start;
    }

    &.comments {
      padding-right: 15px;
    }
  }

  //&__value + &__value {
  //  margin-left: 10px;
  //}
}

.icon__container {
  width: 25px;
  text-align: start;

  @include up-to-md {
    text-align: center;
  }
}

.-text__mobile__bold {
  @include up-to-md {
    font-weight: 700;
  }
}
</style>
