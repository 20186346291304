import { apiConnection } from "@/services/api-connection";
import { trainingCourseStore } from "@/main";
import { useTriadStore } from "@/store/triad/triad";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { messengerMessageManager } from "@/services/messenger-message/messenger-message-manager";

const URL_EVENT_LIST = "/app/event/list/";

class AbsenceManager {
  loadEventList = (id, params) => {
    return apiConnection
      .get(URL_EVENT_LIST + id, params)
      .then((response) => response);
  };

  loadAbsenceList = (params) => {
    const triad = useTriadStore().triad.id;
    const trainingCourse = useTrainingCourseStore().trainingCourse.id;
    return apiConnection
      .get("/app/" + trainingCourse + "/absence/list/" + triad, params)
      .then((response) => response);
  };

  justifyForm = (id, type) => {
    return apiConnection
      .get(
        "/app/" +
          trainingCourseStore.trainingCourse.id +
          "/absence/justify/form/" +
          type +
          "/" +
          id,
      )
      .then((response) => response);
  };

  putJustifyForm = (id, params) => {
    return apiConnection
      .put(
        "/app/" +
          trainingCourseStore.trainingCourse.id +
          "/absence/justify/put/" +
          id,
        params,
      )
      .then((response) => response);
  };

  export = (listParams, url) => {
    return apiConnection
      .get(
        "/app/studea-manager/" +
          trainingCourseStore.trainingCourse.id +
          "/absence" +
          url,
        listParams,
      )
      .then((res) => {
        messengerMessageManager.processMessage(res);
      });
  };
}

export const absenceManager = new AbsenceManager();
