import axios from "axios";
import router from "@/router";
import { loginManager } from "@/services/security/login-manager";
import { platformManager } from "@/services/platform/platform-manager";
import { errorStore, mainStore, platformStore, themeStore } from "@/main";
import { useMainStore } from "@/store/main/main";
import { userManager } from "@/services/security/user-manager";
import { notificationManager } from "@/services/utilities/notification-manager";
import { i18n } from "@/i18n";

class ApiConnection {
  token = "";
  calling = null;
  callingMethod = null;

  constructor() {
    loginManager.reloadToken();
  }

  get(path, params = {}, security = true) {
    const urlParams = new URLSearchParams(params);
    var url = loginManager.apiPath + "/" + themeStore.locale + path;
    if (Object.keys(params).length) {
      url += "?" + urlParams.toString();
    }
    return this.fetchApi("get", url, null, security);
  }

  post(path, params = {}, security = true) {
    const url = loginManager.apiPath + "/" + themeStore.locale + path;
    return this.fetchApi("post", url, params, security);
  }

  put(path, params = {}, security = true) {
    const url = loginManager.apiPath + "/" + themeStore.locale + path;
    return this.fetchApi("put", url, params, security);
  }

  delete(path, params = {}, security = true) {
    const url = loginManager.apiPath + "/" + themeStore.locale + path;
    return this.fetchApi("delete", url, params, security);
  }

  fetchApi(method, path, params, security = true, platformFetch = false) {
    const headers = {};
    const magicLink = this.getMagicLinkToken();
    const magicLinkResend = path.includes("magic-link/resend");

    if (magicLinkResend) {
      headers["Resend-Link"] = magicLink;
    }

    headers["Content-Type"] = "application/json;charset=utf-8";
    headers["Access-Control-Allow-Origin"] = "*";

    if (!loginManager.getApiPath() && !loginManager.apiPath && !platformFetch) {
      return platformManager.loadPlatform().then((platform) => {
        if (platform && platform.hostname && platform.name) {
          loginManager.setApiPath(platform);
          platformStore.setPlatformName(platform.name);
          if (magicLink) {
            window.location.reload();
          } else {
            path = loginManager.apiPath + path;
            this.fetchApi(method, path, params, security);
          }
        }
      });
    } else {
      if (security) {
        if (loginManager.token) {
          headers["Authorization"] = "Bearer " + loginManager.token;
        }

        if (magicLink && !magicLinkResend) {
          const magicOrigin = this.getMagicOrigin();
          headers["Magic-Link"] = magicLink;
          headers["Magic-Origin"] = magicOrigin;
        }
      }

      const store = useMainStore();
      if (
        store.callingPath &&
        store.callingPath === path.split("?")[0] &&
        store.callingMethod &&
        store.callingMethod === method
      ) {
        this.source.cancel();
      }

      store.setCallingPath(path.split("?")[0]);
      store.setCallingMethod(method);
      const cancelToken = axios.CancelToken;
      this.source = cancelToken.source();

      const call = axios({
        method: method,
        url: path,
        data: params,
        headers: headers,
        origin: "*",
        withCredentials: true,
        cancelToken: this.source.token,
      });

      return new Promise((resolve, reject) => {
        call
          .then((response) => {
            mainStore.setInternet(true);
            this.calling = null;
            this.callingMethod = null;
            errorStore.setError(false);
            resolve(response.data);
          })
          .catch((error) => {
            if (error.code === "ERR_NETWORK") {
              mainStore.setInternet(false);
            } else {
              mainStore.setInternet(true);
              const responseObject = error.response
                ? error.response.data
                : null;
              if (responseObject && responseObject.code === 401) {
                mainStore.setInternet(true);
                if (
                  responseObject.message === "Expired JWT Token" ||
                  responseObject.message === "JWT Token not found"
                ) {
                  loginManager
                    .refreshConnection()
                    .then((success) => {
                      if (success.success && success.token) {
                        loginManager.token = success.token;
                      }
                      this.fetchApi(method, path, params, security).then(
                        (response) => {
                          router.push({
                            name: router.currentRoute.value.name,
                            params: router.currentRoute.value.params,
                            replace: true,
                          });
                          resolve(response);
                        },
                      );
                    })
                    .catch(() => {
                      userManager.logout(true);
                    });
                } else if (responseObject) {
                  reject(responseObject);
                } else {
                  router.push("/login");
                }
              } else if (responseObject && responseObject.status === 403) {
                userManager.logout(true);
              } else if (responseObject && responseObject.status > 401) {
                errorStore.setErrorCode(responseObject.status);
                errorStore.setMessage(responseObject.detail);
                errorStore.setError(true);

                if (
                  responseObject.detail ===
                  "Unable to load and decrypt the token."
                ) {
                  userManager.logout(true);
                }
              } else if (error.response && error.response.status === 503) {
                store.setMaintenance(true);
                router.push({ name: "Maintenance" });
              } else if (error.response && error.response.status === 401) {
                if (
                  error.response &&
                  error.response.data &&
                  error.response.data.detail
                ) {
                  switch (error.response.data.detail) {
                    case "Magic link is expired":
                      this.expiredMagicLinkAlert();
                      break;
                    default:
                      userManager.logout();
                      break;
                  }
                }
              }
            }
            this.calling = null;
            this.callingMethod = null;
          });
      });
    }
  }

  expiredMagicLinkAlert() {
    notificationManager
      .showAlert(
        "info",
        i18n.global.t("magic_link_expired"),
        i18n.global.t("magic_link_expired_subtitle"),
        true,
        true,
        i18n.global.t("send_magic_link"),
        i18n.global.t("reconnect"),
        "",
        "magicLink",
      )
      .then((res) => {
        if (res.isConfirmed) {
          this.post("/app/magic-link/resend").then((res) => {
            if (res && res.success) {
              notificationManager.showNotification(
                "success",
                i18n.global.t("magic_link_sent"),
              );
              userManager.logout();
            } else {
              notificationManager.showNotification(
                "error",
                i18n.global.t("magic_link_sent"),
              );
            }
          });
        } else {
          userManager.logout();
        }
      });
  }

  getMagicLinkToken() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("magic-link")) {
      return urlParams.get("magic-link");
    }
    return null;
  }

  getMagicOrigin() {
    return window.location.host + window.location.pathname;
  }
}

export const apiConnection = new ApiConnection();
