import { colorManager } from "@/services/platform/color-manager";
import { platformManager } from "@/services/platform/platform-manager";
import { pictureManager } from "@/services/utilities/picture-manager";
import { loginManager } from "@/services/security/login-manager";
import { defineStore } from "pinia";
import { useMainStore } from "@/store/main/main";

export const usePlatformStore = defineStore({
  id: "Platform",
  state: () => {
    return {
      platforms: {},
      platform: {},
      design: {},
      dataAgreement: {},
      documents: {},
      questionnaireConfig: {},
      logoCfa: null,
      logoCfaPromotion: null,
      logoHomepage: null,
      apprenticeProfileLabel: {},
      tutorProfileLabel: {},
      apprenticeMasterProfileLabel: {},
      companyManagerProfileLabel: {},
      studeaManagerProfileLabel: {},
      needUpdate: true,
      name: window.localStorage.getItem("studea-platformName")
        ? window.localStorage.getItem("studea-platformName")
        : "Studea",
    };
  },

  actions: {
    fetchPlatformFromUser(username) {
      return platformManager.loadWebsites(username).then((platforms) => {
        this.platforms = platforms;
      });
    },

    fetchDataAgreement() {
      if (useMainStore().isInternet) {
        return platformManager.loadDataAgreement().then((dataAgreement) => {
          this.dataAgreement = dataAgreement;
        });
      }
    },

    fetchDocuments() {
      if (useMainStore().isInternet) {
        return platformManager.loadDocuments().then((documents) => {
          this.documents = documents;
        });
      }
    },

    fetchDesign(payload) {
      return platformManager.loadDesign().then((design) => {
        this.fetchDataAgreement();
        this.fetchDocuments();
        if (!design) {
          this.fetchDesign({ disableLoading: false });
        }
        this.setDesign(design);
        colorManager.setPlatformStyle();
        pictureManager.preloadImages(design).then(() => {
          if (payload.disableLoading) {
            useMainStore().setAppLoading(false);
          }
        });
      });
    },

    fetchConfig() {
      if (useMainStore().isInternet) {
        return platformManager.loadQuestionnaireConfig().then((data) => {
          if (data) {
            this.questionnaireConfig = data.questionnaireConfig;
            this.apprenticeProfileLabel = data.userProfileTitleApprentice;
            this.tutorProfileLabel = data.userProfileTitleTutor;
            this.apprenticeMasterProfileLabel =
              data.userProfileTitleApprenticeMaster;
            this.studeaManagerProfileLabel = data.userProfileTitleStudeaManager;
            this.companyManagerProfileLabel =
              data.userProfileTitleCompanyManager;
          }
        });
      }
    },

    setDesign(design) {
      this.design = design;
      this.logoCfa =
        design && design.logoCfa
          ? pictureManager.setPicture(design.logoCfa, "login")
          : "";
      this.logoHomepage =
        design && design.logoHomepage
          ? pictureManager.setPicture(design.logoHomepage, "home")
          : "";
    },

    setNeedUpdate(needUpdate) {
      this.needUpdate = needUpdate;
    },

    setPlatform(platform) {
      this.platform = platform;
      loginManager.setApiPath(platform);
    },

    setPlatforms(platforms) {
      this.platforms = platforms;
    },

    setPlatformName(name) {
      window.localStorage.setItem("studea-platformName", name);
      this.name = name;
    },

    setLogoCfaPromotion(logo) {
      this.logoCfaPromotion = logo;
    },

    init() {
      this.platforms = {};
      this.website = {};
      this.logo = null;
    },
  },
});
