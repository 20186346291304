<template>
  <div
    v-if="reply"
    :class="
      (reply.icon !== 'lock' && reply.icon !== 'check' ? 'disabled' : '') +
      ' reply'
    "
  >
    <button
      v-if="reply.signedAt"
      v-tooltip="{
        title: $t('studea_manager.dashboard.questionnaire.cancel_signature'),
      }"
      @click="manage(reply, questionnaire.id, triad, profile)"
    >
      <span class="success"><FontAwesomeIcon icon="fa-light fa-check" /> </span>
      {{ formatDate(reply.signedAt) }}
    </button>
    <button
      v-else-if="reply.icon && reply.signedAt === null && !reply.before"
      v-tooltip="{ title: getTooltip(reply.icon) }"
      @click="manage(reply, questionnaire.id, triad, profile)"
    >
      <FontAwesomeIcon :icon="'fa-light fa-' + reply.icon" />
    </button>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { dateFormatter } from "@/services/utilities/date-formatter";

export default {
  name: "AccordionPersonReplyComponent",
  components: { FontAwesomeIcon },
  props: {
    reply: {
      type: Array,
      required: true,
    },
    triad: {
      type: Array,
      required: true,
    },
    questionnaire: {
      type: Array,
      required: true,
    },
    profile: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      models: [],
      modalTitle: "",
      isConfirmed: false,
    };
  },
  methods: {
    manage(reply, questionnaire, triad, profile) {
      this.$emit("manage", { reply, questionnaire, triad, profile });
    },

    formatDate(date) {
      return dateFormatter.format(date, "short");
    },

    getTooltip(icon) {
      switch (icon) {
        case "lock":
          return this.$t(
            "studea_manager.dashboard.questionnaire.unlock_questionnaire",
          );
        case "hourglass-start":
          return this.$t(
            "studea_manager.dashboard.questionnaire.send_email_reminder",
          );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reply {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }

  &__date {
    margin-left: 5px;
  }

  button {
    background: none;
    border: none;
    color: $black;
  }
}

.success {
  color: $green;
}
</style>
