<template>
  <TabsTitleComponent :items="tabs" />
  <FormBlockComponent
    :key="componentKey"
    :loading="loading"
    :models="models"
    :title="title"
    @formCancel="cancel"
    @submit="submit"
    @update="update"
  />
</template>

<script>
import FormBlockComponent from "@/components/blocks/FormBlockComponent.vue";
import { apiConnection } from "@/services/api-connection";
import { formManager } from "@/services/form/form-manager";
import { useMeta } from "@/composables/use-meta";
import { formValidator } from "@/services/form/form-validator";
import TabsTitleComponent from "@/components/blocks/TabsTitleComponent.vue";
import { notificationManager } from "@/services/utilities/notification-manager";

export default {
  name: "SkillAddEditView",
  data() {
    return {
      componentKey: 0,
      loading: false,
      models: [],
      oldNbSessionToCreate: null,
      type: "",
      id: null,
      params: {},
      title: "",
      success: "",
      entity: "",
      backPath: "",
      maxSkill: null,
      tabs: [
        {
          id: 0,
          title: this.$t("studea_manager.skill_configuration.title"),
          icon: "gear",
          disabled: false,
          path: "adminModuleSkillConfiguration",
        },
        {
          id: 1,
          title: this.$t("studea_manager.skill_referential.title"),
          icon: "book",
          disabled: false,
          path: "adminModuleSkillReferential",
        },
        {
          id: 2,
          title: this.$t("admin.skill_session.generate"),
          icon: "medal",
          disabled: false,
          path: "adminModuleSkillGenerateSession",
          params: {
            alias: "skill-session",
          },
        },
      ],
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    maxSkill() {
      if (
        this.models["skillLevels"].vars.value.length >
        this.models["skillLevels"].vars.attr["data-max-tag"]
      ) {
        let iteration =
          this.models["skillLevels"].vars.choices.length -
          this.models["skillLevels"].vars.attr["data-max-tag"];
        for (let i = 0; i < iteration; i++) {
          this.models["skillLevels"].vars.value.pop();
        }
      }
    },
  },
  methods: {
    init() {
      this.id = this.$route.params.id ? this.$route.params.id : null;
      this.backPath = this.$route.meta.backPath;
      this.set();
      if (this.id) {
        this.params["id"] = this.id;
      }

      if (this.$route.query.date) {
        this.params["date"] = this.$route.query.date;
      }

      this.$route.meta.title = this.title;
      useMeta({ title: this.title });
      window.localStorage.setItem(
        "studea-pageTitle",
        JSON.stringify({ title: this.title, name: this.$route.name }),
      );
      this.loading = true;
      apiConnection
        .get("/app/admin/skill/" + this.type + "-form", this.params)
        .then((data) => {
          if (data) {
            this.loading = false;
            this.models = data;
            this.oldNbSessionToCreate = Number(data.nbSkillSession.vars.value);
            this.componentKey++;
            this.update();
          }
        });
    },

    update() {
      const maxSkillLevel = this.models["maxSkillLevel"];
      const skillLevels = this.models["skillLevels"];
      if (maxSkillLevel && skillLevels) {
        let nb = maxSkillLevel.vars.value.data
          ? maxSkillLevel.vars.value.data
          : maxSkillLevel.vars.value;
        nb = parseInt(nb);
        skillLevels.vars.attr["data-min-tag"] = nb;
        skillLevels.vars.attr["data-max-tag"] = nb;
        const nbLevel = Object.keys(skillLevels.vars.value).length;
        if (
          nb &&
          nb !== nbLevel &&
          !Object.keys(skillLevels.vars.value).length
        ) {
          if (nb >= 2) {
            skillLevels.vars.choices = [
              {
                value: this.$t("studea_manager.skill.default.not_acquired"),
                data: this.$t("studea_manager.skill.default.not_acquired"),
                label: this.$t("studea_manager.skill.default.not_acquired"),
              },
            ];
          }
          if (nb >= 3) {
            skillLevels.vars.choices.push({
              value: this.$t("studea_manager.skill.default.being_acquired"),
              data: this.$t("studea_manager.skill.default.being_acquired"),
              label: this.$t("studea_manager.skill.default.being_acquired"),
            });
          }
          if (nb >= 2) {
            skillLevels.vars.choices.push({
              value: this.$t("studea_manager.skill.default.acquired"),
              data: this.$t("studea_manager.skill.default.acquired"),
              label: this.$t("studea_manager.skill.default.acquired"),
            });
          }
          if (nb >= 4) {
            skillLevels.vars.choices.push({
              value: this.$t("studea_manager.skill.default.expert"),
              data: this.$t("studea_manager.skill.default.expert"),
              label: this.$t("studea_manager.skill.default.expert"),
            });
          }
          if (nb >= 5) {
            skillLevels.vars.choices = [
              {
                value: this.$t(
                  "studea_manager.skill.default.insufficient_mastery",
                ),
                data: this.$t(
                  "studea_manager.skill.default.insufficient_mastery",
                ),
                label: this.$t(
                  "studea_manager.skill.default.insufficient_mastery",
                ),
              },
              {
                value: this.$t("studea_manager.skill.default.partial_mastery"),
                data: this.$t("studea_manager.skill.default.partial_mastery"),
                label: this.$t("studea_manager.skill.default.partial_mastery"),
              },
              {
                value: this.$t("studea_manager.skill.default.average_mastery"),
                data: this.$t("studea_manager.skill.default.average_mastery"),
                label: this.$t("studea_manager.skill.default.average_mastery"),
              },
              {
                value: this.$t("studea_manager.skill.default.good_mastery"),
                data: this.$t("studea_manager.skill.default.good_mastery"),
                label: this.$t("studea_manager.skill.default.good_mastery"),
              },
              {
                value: this.$t(
                  "studea_manager.skill.default.excellent_mastery",
                ),
                data: this.$t("studea_manager.skill.default.excellent_mastery"),
                label: this.$t(
                  "studea_manager.skill.default.excellent_mastery",
                ),
              },
            ];
          }
        }
      }
      this.maxSkill = skillLevels.vars.attr["data-max-tag"];
      formValidator.validModel(skillLevels);
    },

    set() {
      if (this.$route.path.search("configuration") !== -1) {
        this.title = this.$t("studea_manager.skill_configuration.title");
        this.success = this.$t("studea_manager.skill_configuration.success");
        this.type = "config";
        this.entity = "skill-config";
      } else if (this.$route.path.search("generate-questionnaire") !== -1) {
        this.title = this.$t("studea_manager.skill_generate.title");
        this.success = this.$t("studea_manager.skill_generate.success");
      }
    },

    submit() {
      const result = formManager.processForm(this.models);
      this.loading = true;
      const url = "/app/admin/generic/" + this.entity + "/unique-post";
      if (this.models.nbSkillSession.vars.value < this.oldNbSessionToCreate) {
        notificationManager
          .showAlert(
            "question",
            this.$t("warning"),
            this.$t("admin.skill_session.existing_session"),
            true,
            true,
          )
          .then((response) => {
            if (response.isConfirmed) {
              apiConnection.put(url, result).then((data) => {
                const route = {
                  name: "adminModuleSkillReferential",
                };
                formManager.processResponse(
                  data,
                  route,
                  this.success,
                  this.models,
                );
                this.componentKey++;
              });
            }
          });
      } else {
        apiConnection.put(url, result).then((data) => {
          const route = {
            name: "adminModuleSkillReferential",
          };
          formManager.processResponse(data, route, this.success, this.models);
          this.componentKey++;
        });
      }
      this.loading = false;
    },

    cancel() {
      this.$router.push({ name: this.backPath });
    },
  },
  components: {
    TabsTitleComponent,
    FormBlockComponent,
  },
};
</script>

<style lang="scss" scoped></style>
