<template>
  <TabsTitleComponent :items="tabs" />
  <FormBlockComponent
    :key="componentKey"
    :loading="loading"
    :models="models"
    @formCancel="cancel"
    @submit="submit"
    @update="update"
  >
    <template v-slot:infos
      ><SwitchTypeComponent :model="switchModel" @update="setAdminConfig"
    /></template>
  </FormBlockComponent>
</template>

<script>
import FormBlockComponent from "@/components/blocks/FormBlockComponent.vue";
import { apiConnection } from "@/services/api-connection";
import { formManager } from "@/services/form/form-manager";
import { useMeta } from "@/composables/use-meta";
import { formValidator } from "@/services/form/form-validator";
import { mapActions, mapState } from "pinia";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import TabsTitleComponent from "@/components/blocks/TabsTitleComponent.vue";
import { i18n } from "@/i18n";
import SwitchTypeComponent from "@/components/form/SwitchTypeComponent.vue";

export default {
  name: "SkillAddEditView",
  data() {
    return {
      componentKey: 0,
      loading: false,
      models: [],
      type: "",
      id: null,
      params: {},
      title: "",
      success: "",
      entity: "",
      backPath: "",
      maxSkill: null,
      tabs: [],
      useSkillConfigAdmin: false,
      adminConfigModels: [],
      studeaManagerConfigModels: [],
      switchModel: {},
    };
  },
  computed: {
    ...mapState(useTrainingCourseStore, {
      trainingCourse: (store) => store.trainingCourse,
    }),
  },
  mounted() {
    this.init();
    this.initUseSkillConfigAdmin();
    this.initTabs();
    this.setSwitchModel();
  },
  watch: {
    maxSkill() {
      if (
        this.models["skillLevel"].vars.value.length >
        this.models["skillLevel"].vars.attr["data-max-tag"]
      ) {
        let iteration =
          this.models["skillLevel"].vars.choices.length -
          this.models["skillLevel"].vars.attr["data-max-tag"];
        for (let i = 0; i < iteration; i++) {
          this.models["skillLevel"].vars.value.pop();
        }
      }
    },

    trainingCourse() {
      this.initUseSkillConfigAdmin();
      this.setSwitchModel();
      this.initTabs();
    },

    adminConfigModels() {
      this.setModels();
    },

    studeaManagerConfigModels() {
      this.setModels();
    },

    useSkillConfigAdmin() {
      this.setSwitchModel();
      this.setModels();
    },
  },
  methods: {
    init() {
      this.id = this.$route.params.id ? this.$route.params.id : null;
      this.backPath = this.$route.meta.backPath;
      this.set();
      if (this.id) {
        this.params["id"] = this.id;
      }

      if (this.$route.query.date) {
        this.params["date"] = this.$route.query.date;
      }

      this.$route.meta.title = this.title;
      useMeta({ title: this.title });
      window.localStorage.setItem(
        "studea-pageTitle",
        JSON.stringify({ title: this.title, name: this.$route.name }),
      );
      this.loading = true;
      this.getAdminConfig();
      this.getStudeaManagerConfig();
      if (this.adminConfigModels && this.studeaManagerConfigModels) {
        this.setModels();
        if (this.models && Object.keys(this.models).length) {
          this.componentKey++;
          this.update();
        }
      }
      if (this.trainingCourse && !Object.keys(this.trainingCourse).length) {
        this.fetchTrainingCourse(this.$route.params.trainingCourseId);
      }
    },

    initUseSkillConfigAdmin() {
      if (this.trainingCourse && this.trainingCourse.useSkillConfigAdmin) {
        this.useSkillConfigAdmin = true;
      }
    },

    getAdminConfig() {
      apiConnection
        .get(
          "/app/studea-manager/" +
            this.$route.params.trainingCourseId +
            "/skill/" +
            this.type +
            "-form/1",
        )
        .then((data) => {
          if (data) {
            this.adminConfigModels = data;
          }
        });
    },

    getStudeaManagerConfig() {
      apiConnection
        .get(
          "/app/studea-manager/" +
            this.$route.params.trainingCourseId +
            "/skill/" +
            this.type +
            "-form",
        )
        .then((data) => {
          if (data) {
            this.studeaManagerConfigModels = data;
          }
          this.loading = false;
        });
    },

    update() {
      if (this.models && this.models["skillLevel"]) {
        const maxSkillLevel = this.models["maxSkillLevel"];
        const skillLevels = this.models["skillLevel"];
        if (maxSkillLevel && skillLevels) {
          let nb = maxSkillLevel.vars.value.data
            ? maxSkillLevel.vars.value.data
            : maxSkillLevel.vars.value;
          nb = parseInt(nb);
          skillLevels.vars.attr["data-min-tag"] = nb;
          skillLevels.vars.attr["data-max-tag"] = nb;
          const nbLevel = Object.keys(skillLevels.vars.value).length;
          if (
            nb &&
            nb !== nbLevel &&
            !Object.keys(skillLevels.vars.value).length
          ) {
            if (nb >= 2) {
              skillLevels.vars.choices = [
                {
                  value: this.$t("studea_manager.skill.default.not_acquired"),
                  data: this.$t("studea_manager.skill.default.not_acquired"),
                  label: this.$t("studea_manager.skill.default.not_acquired"),
                },
              ];
            }
            if (nb >= 3) {
              skillLevels.vars.choices.push({
                value: this.$t("studea_manager.skill.default.being_acquired"),
                data: this.$t("studea_manager.skill.default.being_acquired"),
                label: this.$t("studea_manager.skill.default.being_acquired"),
              });
            }
            if (nb >= 2) {
              skillLevels.vars.choices.push({
                value: this.$t("studea_manager.skill.default.acquired"),
                data: this.$t("studea_manager.skill.default.acquired"),
                label: this.$t("studea_manager.skill.default.acquired"),
              });
            }
            if (nb >= 4) {
              skillLevels.vars.choices.push({
                value: this.$t("studea_manager.skill.default.expert"),
                data: this.$t("studea_manager.skill.default.expert"),
                label: this.$t("studea_manager.skill.default.expert"),
              });
            }
            if (nb >= 5) {
              skillLevels.vars.choices = [
                {
                  value: this.$t(
                    "studea_manager.skill.default.insufficient_mastery",
                  ),
                  data: this.$t(
                    "studea_manager.skill.default.insufficient_mastery",
                  ),
                  label: this.$t(
                    "studea_manager.skill.default.insufficient_mastery",
                  ),
                },
                {
                  value: this.$t(
                    "studea_manager.skill.default.partial_mastery",
                  ),
                  data: this.$t("studea_manager.skill.default.partial_mastery"),
                  label: this.$t(
                    "studea_manager.skill.default.partial_mastery",
                  ),
                },
                {
                  value: this.$t(
                    "studea_manager.skill.default.average_mastery",
                  ),
                  data: this.$t("studea_manager.skill.default.average_mastery"),
                  label: this.$t(
                    "studea_manager.skill.default.average_mastery",
                  ),
                },
                {
                  value: this.$t("studea_manager.skill.default.good_mastery"),
                  data: this.$t("studea_manager.skill.default.good_mastery"),
                  label: this.$t("studea_manager.skill.default.good_mastery"),
                },
                {
                  value: this.$t(
                    "studea_manager.skill.default.excellent_mastery",
                  ),
                  data: this.$t(
                    "studea_manager.skill.default.excellent_mastery",
                  ),
                  label: this.$t(
                    "studea_manager.skill.default.excellent_mastery",
                  ),
                },
              ];
            }
          }
        }
        this.maxSkill = skillLevels.vars.attr["data-max-tag"];
        formValidator.validModel(skillLevels);
      }
    },

    set() {
      if (this.$route.path.search("configuration") !== -1) {
        this.title = this.$t("studea_manager.skill_configuration.title");
        this.success = this.$t("studea_manager.skill_configuration.success");
        this.type = "config";
        this.entity = "training-course";
        this.id = this.$route.params.trainingCourseId;
      } else if (this.$route.path.search("generate-questionnaire") !== -1) {
        this.title = this.$t("studea_manager.skill_generate.title");
        this.success = this.$t("studea_manager.skill_generate.success");
      }
    },

    submit() {
      let result = formManager.processForm(this.models);
      if (this.$route.name === "studeaManagerSkillConfiguration") {
        if (this.useSkillConfigAdmin === true) {
          result = {};
        }
        result["useSkillConfigAdmin"] = this.useSkillConfigAdmin;
      }
      this.loading = true;
      const url =
        "/app/studea-manager/" +
        this.$route.params.trainingCourseId +
        "/generic/" +
        this.entity +
        "/post" +
        (this.id ? "/" + this.id : "");
      (this.id
        ? apiConnection.put(url, result)
        : apiConnection.post(url, result)
      ).then((data) => {
        const route = {
          name: "studeaManagerSkillReferentialConfiguration",
        };
        formManager.processResponse(data, route, this.success, this.models);
        this.componentKey++;
        this.loading = false;
        if (this.type === "config") {
          this.fetchTrainingCourse(this.$route.params.trainingCourseId);
        }
      });
    },

    cancel() {
      this.$router.push({ name: this.backPath });
    },

    initTabs() {
      this.tabs = [
        {
          id: 0,
          title: this.$t("studea_manager.skill_configuration.title"),
          icon: "gear",
          disabled: false,
          path: "studeaManagerSkillConfiguration",
          params: {
            alias: "skill-config",
            id: this.$route.params.trainingCourseId,
          },
        },
        {
          id: 1,
          title: this.$t("studea_manager.skill_referential.title"),
          icon: "book",
          disabled: false,
          path: "studeaManagerSkillReferentialConfiguration",
          params: {
            alias: "skill-referential",
            id: this.$route.params.trainingCourseId,
          },
        },
        {
          id: 2,
          title: this.$t("studea_manager.skillSession.gestion"),
          icon: "medal",
          disabled: false,
          path: "studeaManagerSkillList",
          params: {
            alias: "skill-session",
            id: this.$route.params.trainingCourseId,
          },
        },
      ];
    },

    setModels() {
      if (this.useSkillConfigAdmin) {
        this.models = this.adminConfigModels;
      } else {
        this.models = this.studeaManagerConfigModels;
      }
      this.componentKey++;
    },

    setAdminConfig(payload) {
      this.useSkillConfigAdmin = !!payload;
    },

    setSwitchModel() {
      this.switchModel = {
        vars: {
          hide: false,
          value: this.useSkillConfigAdmin,
          label: i18n.global.t("studea_manager.skill_configuration.use_admin"),
          disabled: false,
        },
      };
    },

    ...mapActions(useTrainingCourseStore, {
      fetchTrainingCourse: "fetchTrainingCourse",
    }),
  },
  components: {
    SwitchTypeComponent,
    TabsTitleComponent,
    FormBlockComponent,
  },
};
</script>

<style lang="scss" scoped></style>
