<template>
  <div class="widget-content">
    <div class="dashboard">
      <AccordionHeadlineComponent :categories="categories" :type="type" />
      <div
        ref="crews"
        class="dashboard__crews widget"
        @scroll="scrollPosition(this.$refs.crews)"
      >
        <div v-for="(crew, index) in crews" :key="crew.id">
          <div
            v-if="crew.triads.length"
            :class="
              'dashboard__crew p-4 crew-' + crew.id + (!index ? ' on' : '')
            "
            :style="
              '--max-height:' +
              137 * (crew.triads.length ? crew.triads.length : 1) +
              'px'
            "
          >
            <h3 class="mb-0">
              <button
                class="dashboard__crew__title"
                @click="toggle(crew.id, 'crew-', true)"
              >
                {{ crew.title }}
                <FontAwesomeIcon
                  icon="fa-light fa-chevron-down"
                ></FontAwesomeIcon>
              </button>
            </h3>
            <div class="dashboard__crew__item">
              <div
                v-for="triad in crew.triads"
                :key="triad.id"
                class="dashboard__triad"
              >
                <div
                  class="dashboard__triad__apprentice d-flex align-items-center"
                  v-if="triad.apprentice"
                  v-tooltip="{
                    title: triad.apprentice.fullName,
                  }"
                >
                  <span
                    class="badge -apprentice"
                    v-if="
                      apprenticeProfileLabel &&
                      apprenticeProfileLabel.titleAbbreviation
                    "
                    >{{
                      apprenticeProfileLabel.titleAbbreviation.toUpperCase()
                    }}</span
                  >
                  <span
                    v-if="triad.apprentice && triad.apprentice.fullName"
                    class="name"
                  >
                    {{ triad.apprentice.fullName }}
                  </span>
                  <span v-else>--</span>
                </div>
                <div
                  v-if="triad.tutor"
                  class="d-flex align-items-center"
                  v-tooltip="{
                    title: triad.tutor.fullName,
                  }"
                >
                  <span
                    class="badge -tutor"
                    v-if="
                      tutorProfileLabel && tutorProfileLabel.titleAbbreviation
                    "
                    >{{
                      tutorProfileLabel.titleAbbreviation.toUpperCase()
                    }}</span
                  >
                  <span v-if="triad.tutor && triad.tutor.fullName" class="name">
                    {{ triad.tutor.fullName }}
                  </span>
                  <span v-else>--</span>
                </div>
                <div
                  class="d-flex align-items-center"
                  v-tooltip="{
                    title: triad.apprenticeMaster
                      ? triad.apprenticeMaster.fullName
                      : '',
                  }"
                >
                  <span
                    class="badge -apprenticeMaster"
                    v-if="
                      apprenticeMasterProfileLabel &&
                      apprenticeMasterProfileLabel.titleAbbreviation
                    "
                    >{{
                      apprenticeMasterProfileLabel.titleAbbreviation.toUpperCase()
                    }}</span
                  >
                  <span
                    v-if="
                      triad.apprenticeMaster && triad.apprenticeMaster.fullName
                    "
                    class="name"
                  >
                    {{ triad.apprenticeMaster.fullName }}
                  </span>
                  <span v-else>--</span>
                </div>
                <div
                  class="d-flex align-items-center"
                  v-if="
                    studeaManagerProfileLabel && studeaManagerProfileLabel.title
                  "
                >
                  <span class="badge -leaManager">CL</span>
                  {{ studeaManagerProfileLabel.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        ref="replies"
        class="dashboard__replies__wrapper"
        @scroll="scrollPosition(this.$refs.replies)"
      >
        <div class="dashboard__replies">
          <div
            v-for="category in categories"
            :key="category.id"
            :class="
              'dashboard__replies__item on widget mb-0 category-' +
              category.id +
              (type === 'skill' ? ' -skill' : '')
            "
          >
            <div class="dashboard__replies__questionnaires">
              <AccordionColumnsComponent
                :category="category"
                :type="type"
                :crews="crews"
                @manage="manage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import AccordionHeadlineComponent from "@/components/table/accordion/AccordionHeadlineComponent.vue";
import AccordionColumnsComponent from "@/components/table/accordion/AccordionColumnsComponent.vue";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import { mapState } from "pinia";
import { usePlatformStore } from "@/store/platform/platform";

export default {
  name: "AccordionTableComponent",
  components: {
    AccordionColumnsComponent,
    AccordionHeadlineComponent,
    FontAwesomeIcon,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
    },
    crews: {
      type: Array,
    },
  },
  data() {
    return {
      active: null,
      crewActive: null,
      crewScroll: 0,
      replieScroll: 0,
      skillMaxWidth: 1,
      questionnaireMaxWidth: 1,
      totalColumn: 0,
      totalColumnArray: [],
    };
  },
  computed: {
    ...mapState(usePlatformStore, {
      apprenticeProfileLabel: (store) => store.apprenticeProfileLabel,
      tutorProfileLabel: (store) => store.tutorProfileLabel,
      apprenticeMasterProfileLabel: (store) =>
        store.apprenticeMasterProfileLabel,
      studeaManagerProfileLabel: (store) => store.studeaManagerProfileLabel,
    }),
  },
  watch: {
    crewScroll() {
      this.$refs.replies.scrollTo(0, this.crewScroll);
    },

    replieScroll() {
      this.$refs.crews.scrollTo(0, this.replieScroll);
    },
  },
  methods: {
    toggle(item, name, isMultipleElement) {
      studeaManagerManager.toggle(item, name, isMultipleElement);
    },

    scrollPosition(item) {
      if (item === this.$refs.crews) {
        this.crewScroll = item.scrollTop;
      } else if (item === this.$refs.replies) {
        this.replieScroll = item.scrollTop;
      }
    },

    manage(payload) {
      this.$emit("manage", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  overflow: scroll;
  height: auto;
  margin-left: 260px;
  border-radius: 6px;

  &__form {
    position: absolute;
    top: 260px;
    left: 20px;
    width: 250px;
  }

  &__crews {
    max-height: 500px;
    overflow: scroll;
    width: 250px;
    position: absolute;
    height: fit-content;
    left: 20px;
    z-index: 5;

    @include mobile {
      width: 230px;
    }
  }

  &__crew {
    border-bottom: 1px solid #e0e6ed;
    text-align: center;

    &__title {
      width: 100%;
      background-color: var(--primary-color);
      color: $white;
      border-radius: 6px;
      padding: 5px;
      border: none;

      svg {
        transform: rotate(0deg);
        transition: all 300ms ease-in-out;
        margin-left: 10px;
      }
    }

    &__item {
      transition: all 300ms ease-in-out;
      overflow: hidden;
      height: 0;
    }

    &.on {
      .dashboard__crew__item {
        height: var(--max-height);
      }

      .dashboard__crew__title {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
  }

  &__triad {
    margin-top: 15px;
    border-bottom: 1px dashed #e0e6ed;
    height: 125px;

    div {
      display: flex;
      justify-content: start;
      margin-top: 5px;
      margin-left: 20px;
    }

    .name {
      display: block;
      max-width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }

    &__apprentice {
      font-weight: 700;
    }
  }

  &__replies__wrapper {
    width: fit-content;
    max-height: 500px;
    min-height: 500px;
    overflow: scroll;
    height: 100%;
    display: flex;
  }

  &__replies {
    display: flex;
    height: fit-content;

    &__item {
      margin-right: 10px;
      min-width: 130px;
      overflow: hidden;
      transition: all 300ms ease-in-out;

      &:last-child {
        margin-right: 0;
      }

      &.on {
        min-width: 200px;
      }

      &.-skill {
        &.on {
          min-width: 175px;
        }
      }
    }

    &__questionnaires {
      display: flex;
      width: fit-content;
    }
  }
}

.badge {
  width: 20px;
  height: 20px;
  font-size: 0.5rem;
  padding: 3px 4px;
  margin-right: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: default;
  }

  &.-apprentice {
    background: $green;
  }

  &.-tutor {
    background: $purple;
  }

  &.-apprenticeMaster {
    background: $red;
  }

  &.-leaManager {
    background: $orange;
  }
}

.offset {
  height: 40px;
}
</style>
