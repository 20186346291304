import { apiConnection } from "@/services/api-connection";
import { pictureManager } from "@/services/utilities/picture-manager";
import router from "@/router";
import { platformStore, triadStore } from "@/main";

const URL = "/app/training-course/list";
const URL_ONE = "/app/training-course/";

class TrainingCourseManager {
  MODULE_STUDEA_SKILL = "MODULE_STUDEA_SKILL";
  MODULE_STUDEA_QUESTIONNAIRE = "MODULE_STUDEA_QUESTIONNAIRE";
  MODULE_STUDEA_ABSENCE = "MODULE_STUDEA_ABSENCE";
  MODULE_STUDEA_DOCUMENT = "MODULE_STUDEA_DOCUMENT";
  MODULE_STUDEA_MESSAGE = "MODULE_STUDEA_MESSAGE";
  MODULE_STUDEA_COURSE = "MODULE_STUDEA_COURSE";
  MODULE_STUDEA_NOTE = "MODULE_STUDEA_NOTE";
  MODULE_STUDEA_NOTEPAD = "MODULE_STUDEA_NOTEPAD";
  MODULE_STUDEA_REGISTRATION = "MODULE_STUDEA_REGISTRATION";
  MODULE_STUDEA_WEBLINKS = "MODULE_STUDEA_WEBLINKS";

  loadTrainingCourseList = (archive = 1, search = "", page = 0, id = null) => {
    return apiConnection
      .get(
        URL,
        {
          archive: archive,
          length: 10,
          page: page ? page : 0,
          search: search,
          id: id,
        },
        true,
      )
      .then((response) => response);
  };

  loadTrainingCourseListWithQuery = (params) => {
    return apiConnection.get(URL, params).then((response) => response);
  };

  loadTrainingCourse = (id) => {
    return apiConnection.get(URL_ONE + id).then((response) => response);
  };

  getPageDatas(code) {
    const triad = triadStore.triad;
    let menu = null;
    if (triad && triad.trainingCourse && triad.trainingCourse.menu) {
      menu = Object.values(triad.trainingCourse.menu).find(
        (menu) => menu.code === code,
      );
    }
    return menu && menu.title ? menu.title : "";
  }

  getPageHelp(code) {
    const triad = triadStore.triad;
    let menu = null;
    if (triad && triad.trainingCourse && triad.trainingCourse.menu) {
      menu = Object.values(triad.trainingCourse.menu).find(
        (menu) => menu.code === code,
      );
    }
    return menu && menu.helpDocument ? menu.helpDocument : null;
  }

  setPageTitle(code) {
    const title = this.getPageDatas(code);
    router.currentRoute.value.meta.title = title;
    return title;
  }

  setNewCfaLogo(trainingCourse) {
    if (trainingCourse && trainingCourse.training) {
      platformStore.setLogoCfaPromotion(
        pictureManager.setPicture(
          trainingCourse.training.institution.logoInstitution,
        ),
      );
    }
  }
}

export const trainingCourseManager = new TrainingCourseManager();
