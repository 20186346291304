<template>
  <div :class="'avatar avatar-sm me-1 ' + size + ' ' + type">
    <span
      v-if="user && user.photo && !disablePicture"
      class="avatar-title rounded-circle picture"
    >
      <img
        :alt="getName()"
        :class="
          Object.keys(connectedUser).length && user.id === connectedUser.id
            ? 'user-avatar-picture'
            : ''
        "
        :src="picture"
      />
    </span>
    <span
      v-else
      :style="'background: ' + backgroundColor"
      class="avatar-title rounded-circle text-white"
    >
      {{ getInitials() }}
    </span>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { pictureManager } from "@/services/utilities/picture-manager";
import { usePlatformStore } from "@/store/platform/platform";
import { useUserStore } from "@/store/user/user";

export default {
  name: "AvatarComponent",
  props: {
    size: {
      type: String,
      required: false,
      default: "small",
    },
    type: {
      type: String,
      required: false,
      default: "circle",
    },
    user: {
      type: Object,
      required: false,
      default: () => null,
    },
    disablePicture: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    profile: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      picture: null,
    };
  },
  computed: {
    ...mapState(usePlatformStore, {
      design: (store) => store.design,
    }),
    ...mapState(useUserStore, {
      connectedUser: (store) => store.user,
      userPicture: (store) => store.userPicture,
    }),
    backgroundColor() {
      return this.getColor();
    },
  },
  watch: {
    user() {
      this.setPicture();
    },
    userPicture() {
      this.setPicture();
    },
  },
  mounted() {
    this.setPicture();
  },
  methods: {
    getName() {
      if (this.user && Object.keys(this.user).length) {
        return this.user
          ? this.user.fullName
            ? this.user.fullName
            : this.user.firstName + " " + this.user.lastName
          : "";
      } else {
        return this.name;
      }
    },

    setPicture() {
      if (!this.disablePicture) {
        this.picture =
          this.user && this.user.id === this.connectedUser.id
            ? this.userPicture
            : pictureManager.setPicture(this.user.photo);
      }
    },

    getInitials() {
      const username = this.getName();
      let res = "";
      if (username) {
        const usernameParts = username.split(" ", 2);

        const initials = usernameParts.map((e) => {
          return e.slice(0, 1).toUpperCase();
        });

        if (initials.length > 3) return initials.slice(0, 3).join("");

        res = initials.join("");
      }

      return res;
    },
    getColor() {
      let color;
      if (
        this.user &&
        Object.keys(this.user).length &&
        this.user.roles &&
        this.user.roles.length
      ) {
        if (this.user.roles.includes("ROLE_STUDEA_USER")) {
          color = "#6ac7a4";
        } else if (this.user.roles.includes("ROLE_STUDEA_ADMIN")) {
          color = "#f8a007";
        }
      }

      if (this.profile) {
        switch (this.profile) {
          case "apprentice":
          case 1:
            color = "#6ac7a4";
            break;
          case "tutor":
          case 2:
            color = "#546bd1";
            break;
          case "masterApprentice":
          case 3:
            color = "#da5164";
            break;
        }
      }

      return color;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  width: 30px;
  height: 30px;
  font-size: 14px;
  line-height: normal;
  flex-shrink: 0;

  &.large {
    width: 75px;
    height: 75px;
    font-size: 30px;
  }

  &.fit {
    width: 100%;
    height: 100%;
    font-size: 40px;
  }

  &.square {
    .rounded-circle {
      border-radius: 9px !important;
    }
  }
}

.navbar .navbar-item .nav-item.user-profile-dropdown .nav-link img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.picture {
  overflow: hidden;
}
</style>
