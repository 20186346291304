<template>
  <div class="indicator__container">
    <div class="d-flex mb-1 justify-content-between align-items-center">
      <span class="textStyle">{{
        data && data.label ? data.label : $t("dashboard.indicators.fill_rate")
      }}</span>
      <button
        v-if="data.sub_indicators && Object.keys(data.sub_indicators).length"
        class="btn-icon iconStyle"
        @click="show(data)"
        v-tooltip="{ title: $t('dashboard.indicators.see_details') }"
      ></button>
      <button
        v-if="this.index === 3"
        class="btn-icon iconStyle"
        @click="goToStatisticList"
        v-tooltip="{
          title: $t('dashboard.indicators.see_details_incomplete_triad'),
        }"
      >
        <FontAwesomeIcon icon="fa-light fa-eye" />
      </button>
    </div>
    <StatisticBarBlockComponent :data="data" />
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import StatisticBarBlockComponent from "@/components/blocks/statistic/StatisticBarBlockComponent.vue";
import { accessManager } from "@/services/security/access-manager";

export default {
  name: "StatisticBlockComponent",
  components: { StatisticBarBlockComponent, FontAwesomeIcon },
  props: {
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    show(indicator) {
      this.$emit("show", indicator);
    },
    accessManager() {
      return accessManager;
    },
    async goToStatisticList() {
      this.$router.push({
        name: "studeaManagerStatisticIncompleteTriad",
        params: { index: this.index },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.indicator__container {
  padding: 10px;
  border-radius: 6px;
}

.subIndicator {
  padding-left: 10px;
  padding-right: 10px;
  height: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: all 300ms ease-in-out;
}
</style>
