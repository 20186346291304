<template>
  <div v-if="type === 'questionnaire' || type === 'note'">
    <AccordionHeadlineQuestionnaireComponent
      :categories="categories"
      :type="type"
    />
  </div>
  <div v-if="type === 'skill'">
    <AccordionHeadlineSkillComponent />
  </div>
</template>

<script>
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import AccordionHeadlineQuestionnaireComponent from "@/components/table/accordion/AccordionHeadlineQuestionnaireComponent.vue";
import AccordionHeadlineSkillComponent from "@/components/table/accordion/AccordionHeadlineSkillComponent.vue";

export default {
  name: "AccordionHeadlineComponent",
  components: {
    AccordionHeadlineSkillComponent,
    AccordionHeadlineQuestionnaireComponent,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  methods: {
    toggle(item, name, isMultipleElement) {
      studeaManagerManager.toggle(item, name, isMultipleElement);
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  &__categories {
    display: flex;
    width: fit-content;
  }
}
</style>
