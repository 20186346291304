<template>
  <div class="container-fluid">
    <TabsTitleComponent :items="tabs" />
    <div class="row">
      <div class="col-lg-9 order-1 order-lg-0">
        <div class="widget p-4">
          <BlockLoader :loading="loading" />
          <ItemTabsBlock
            :currentItem="currentSession"
            :itemList="skills"
            @changeItem="changeSession"
            @deleteItem="deleteSession"
          />
          <div class="loader-block" v-if="skills && skills.length">
            <VueDraggableNext
              v-if="currentSession && currentSession.categories"
              :animation="200"
              :delay="200"
              :delay-on-touch-only="true"
              :group="{ name: 'categories' }"
              :list="currentSession.categories"
              handle=".grip"
              :disabled="
                !accessManager().isAvailableForUser(
                  this.$route,
                  studeaManagerManager().STUDEA_MANAGER_SKILLS,
                  accessManager().EDIT,
                )
              "
              @change="sortCategory"
            >
              <SkillCategoryComponent
                v-if="
                  currentSession &&
                  currentSession.categories &&
                  currentSession.categories.length
                "
                :skills="currentSession.categories"
                :currentSession="currentSession.id"
                type="partial-access"
                @init="init"
              />
              <div
                class="widget empty"
                v-else-if="currentSession && currentSession.id"
              >
                <div v-if="!isConfigured()" class="mb-2">
                  <FontAwesomeIcon
                    icon="fa-light fa-triangle-exclamation"
                    class="text-warning fs-1"
                  />
                </div>
                <h6 class="textStyle mb-3" v-if="isConfigured()">
                  {{
                    $tc("global.empty.title", 2, {
                      name1: this.$tc(
                        "studea_manager.skill.title",
                        1,
                      ).toLowerCase(),
                      name2: this.$tc(
                        "studea_manager.skillCategory.title",
                        1,
                      ).toLowerCase(),
                    })
                  }}
                </h6>
                <h6 class="textStyle mb-3" v-else>
                  {{ $t("studea_manager.skill_configuration.warning") }}
                </h6>
                <router-link
                  v-if="isConfigured()"
                  class="btn btnStyle btn-primary"
                  :to="{
                    name: 'studeaMananerSkillAddSessionReferential',
                    query: { currentSession: this.currentSession.id },
                  }"
                  v-access:[permissions]="accessManager().EDIT"
                >
                  <FontAwesomeIcon icon="fa-light fa-plus" />
                  <div class="ms-2">
                    {{ this.$tc("studea_manager.skillReferential.use") }}
                  </div>
                </router-link>

                <router-link
                  v-else
                  class="btn btnStyle btn-primary"
                  :to="{
                    name: 'studeaManagerSkillConfiguration',
                  }"
                  v-access:[permissions]="accessManager().EDIT"
                >
                  <FontAwesomeIcon icon="fa-light fa-cog" />
                  <div class="ms-2">
                    {{ $t("studea_manager.skill_configuration.title") }}
                  </div>
                </router-link>
              </div>
            </VueDraggableNext>
          </div>
          <div class="widget empty" v-else>
            <div class="widget-content">
              <div class="text-center">
                <div class="noResult blockStyle">
                  {{ $t("No data available") }}<br />
                  {{ $t("Please click on the add button") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 order-0 order-lg-1">
        <div class="sticky">
          <div id="action-blocks">
            <ActionsBlock
              :actions="skillActions"
              :collapsable="false"
              :title="$t('studea_manager.skill.actions.session_actions')"
              collapseId="skillActions"
              @removeSkills="removeSkills"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { notificationManager } from "@/services/utilities/notification-manager";
import { VueDraggableNext } from "vue-draggable-next";
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import SkillCategoryComponent from "@/components/skills/SkillCategoryComponent.vue";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { apiConnection } from "@/services/api-connection";
import ItemTabsBlock from "@/components/blocks/ItemTabsBlock.vue";
import { mapActions, mapState } from "pinia";
import { accessManager } from "@/services/security/access-manager";
import { useTriadStore } from "@/store/triad/triad";
import { useUserStore } from "@/store/user/user";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import TabsTitleComponent from "@/components/blocks/TabsTitleComponent.vue";

export default {
  name: "SkillListView",
  components: {
    TabsTitleComponent,
    ItemTabsBlock,
    FontAwesomeIcon,
    BlockLoader,
    SkillCategoryComponent,
    ActionsBlock,
    VueDraggableNext,
  },
  data() {
    return {
      tabs: [],
      skillActions: [],
      importExportActions: [],
      questionnaireModel: this.questionnaire,
      showModal: false,
      models: [],
      list: {},
      categories: [],
      years: {},
      skills: [],
      loading: false,
    };
  },
  computed: {
    ...mapState(useTriadStore, {
      currentSession: (store) => store.currentSession,
    }),
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourse: (store) => store.trainingCourse,
    }),
  },
  watch: {
    trainingCourse() {
      this.initTabs();
    },
  },
  mounted() {
    if (this.currentSession && !Object.keys(this.currentSession).length) {
      this.setCurrentSession(null);
    }
    this.init();
    this.initTabs();
  },
  methods: {
    studeaManagerManager() {
      return studeaManagerManager;
    },
    accessManager() {
      return accessManager;
    },
    sortCategory(event) {
      const id = event.moved.element.id;
      const position = event.moved.newIndex;
      studeaManagerManager
        .sort(
          "skill-category",
          this.$route.params.trainingCourseId,
          id,
          position,
        )
        .then(() => {
          this.init();
        });
    },

    isConfigured() {
      if (this.trainingCourse && this.trainingCourse.useSkillConfigAdmin) {
        return true;
      } else
        return !!(
          this.trainingCourse &&
          this.trainingCourse.maxSkillLevel &&
          Object.keys(this.trainingCourse.skillRoles).length &&
          Object.keys(this.trainingCourse.skillLevels).length
        );
    },

    removeSkills() {
      notificationManager
        .showAlert(
          "error",
          this.$t("studea_manager.skill_remove.title"),
          this.$t("studea_manager.skill_remove.sub_title"),
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            apiConnection
              .get(
                "/app/studea-manager/" +
                  this.$route.params.trainingCourseId +
                  "/skill/remove-repository",
              )
              .then((response) => {
                if (response) {
                  notificationManager.showNotification(
                    "success",
                    this.$t("studea_manager.skill_remove.success"),
                  );
                } else {
                  notificationManager.showNotification(
                    "error",
                    this.$t("signed_error"),
                  );
                }
              });
          }
        });
    },

    exportSkills() {
      studeaManagerManager.export("skill", this.$route.params.trainingCourseId);
    },

    exportTaught() {
      studeaManagerManager.export(
        "subject",
        this.$route.params.trainingCourseId,
      );
    },

    init() {
      this.loading = true;
      studeaManagerManager
        .list("skill", this.$route.params.trainingCourseId, false, [])
        .then((res) => {
          this.skills = res;
          this.loading = false;
          if (this.skills && this.skills.length) {
            if (
              (this.currentSession && !this.currentSession.id) ||
              !this.currentSession
            ) {
              this.setCurrentSession(
                this.skills.find((skill) => skill.currentSkillSession === true),
              );
            } else {
              let skillsToAdd = this.skills.find(
                (session) => session.id === this.currentSession.id,
              );
              if (skillsToAdd) {
                this.currentSession.categories = skillsToAdd.categories;
              }
            }
            if (this.currentSession && this.currentSession.id) {
              this.initActions();
            }
          }
        });
    },

    changeSession(id) {
      this.setCurrentSession(this.skills.find((skill) => skill.id === id));
      if (this.currentSession && this.currentSession.id) {
        this.initActions();
      }
    },

    deleteSession(payload) {
      notificationManager
        .showAlert(
          "error",
          this.$t("studea_manager.delete_session_confirmation"),
          "",
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            const url =
              "/app/studea-manager/" +
              this.$route.params.trainingCourseId +
              "/generic/skill-session/delete/" +
              payload;
            apiConnection.delete(url).then(() => {
              this.loading = true;
              this.setCurrentSession(null);
              this.init();
            });
          }
        });
    },

    initActions() {
      this.skillActions = [
        {
          translation: "global.add.title",
          translationGender: 2,
          translationParams: {
            name: this.$tc(
              "studea_manager.skillSession.title",
              1,
            ).toLowerCase(),
          },
          icon: "plus",
          type: "success",
          actionType: "router",
          action: "studeaManagerSkillSessionAdd",
          cfaStyle: false,
          params: { alias: "skill-session" },
          access: accessManager.EDIT,
        },
        {
          translation: "studea_manager.skillReferential.use",
          translationGender: 1,
          icon: "plus",
          type: "success",
          actionType: "router",
          action: "studeaMananerSkillAddSessionReferential",
          cfaStyle: false,
          params: { alias: "skill-referential" },
          query: { currentSession: this.currentSession.id },
          access: accessManager.EDIT,
        },
        {
          translation: "studea_manager.skill_generate.title",
          icon: "refresh",
          type: "success",
          actionType: "router",
          action: "studeaManagerSkillGenerateQuestionnaire",
          cfaStyle: false,
          params: { currentSessionId: this.currentSession.id },
          access: accessManager.EDIT,
        },
      ];
    },

    initTabs() {
      this.tabs = [
        {
          id: 0,
          title: this.$t("studea_manager.skill_configuration.title"),
          icon: "gear",
          disabled: false,
          path: "studeaManagerSkillConfiguration",
          params: {
            alias: "skill-config",
            id: this.$route.params.trainingCourseId,
          },
        },
        {
          id: 1,
          title: this.$t("studea_manager.skill_referential.title"),
          icon: "book",
          disabled: false,
          path: "studeaManagerSkillReferentialConfiguration",
          params: {
            alias: "skill-referential",
            id: this.$route.params.trainingCourseId,
          },
        },
        {
          id: 2,
          title: this.$t("studea_manager.skillSession.gestion"),
          icon: "medal",
          disabled: false,
          path: "studeaManagerSkillList",
          params: {
            alias: "skill-session",
            id: this.$route.params.trainingCourseId,
          },
        },
      ];
    },

    ...mapActions(useTriadStore, ["setCurrentSession"]),
    ...mapActions(useTriadStore, { setCurrentSession: "setCurrentSession" }),
  },
};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;
  padding: 20px;
}

.empty-session {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.noResult {
  padding: 20px;
}
</style>
