<template>
  <div
    :class="itemModel.itemType === 'cluster' ? 'mb-4' : ''"
    @click="stopPropagation"
    @dblclick="stopPropagation"
  >
    <span
      v-if="itemModel.tipActive && itemModel.tip"
      class="form-info ms-1"
      v-tooltip="{ title: itemModel.tip }"
    >
      <FontAwesomeIcon icon="fa-light fa-circle-question" />
    </span>
    <div class="fw-bold mb-2">
      {{ $t("studea_manager.questionnaire.option_settings") }}
    </div>
    <VueDraggableNext
      :animation="200"
      :delay="200"
      :delay-on-touch-only="true"
      :list="itemModel.valueList"
      handle=".choiceGrip"
    >
      <div
        v-for="(choice, index) in itemModel.valueList"
        :key="choice"
        :class="
          'text-color radio-primary custom-control choice ' +
          (itemModel.type === 3 ? 'custom-radio' : '') +
          (itemModel.type === 4 ? 'custom-checkbox' : '')
        "
      >
        <div class="choiceGrip">
          <FontAwesomeIcon icon="fa-light fa-grip-lines-vertical" />
        </div>
        <input
          v-if="itemModel.type !== 2"
          :id="'radio-' + itemModel.title + '-' + choice.mark"
          :name="itemModel.title"
          :type="itemModel.type === 3 ? 'radio' : 'checkbox'"
          :value="choice.mark"
          class="custom-control-input"
          disabled
        />
        <span v-else class="me-2">{{ index + 1 }}.</span>
        <label
          :class="
            'd-inline-flex' +
            (itemModel.type === 2 ? '' : ' custom-control-label')
          "
          :for="'radio-' + itemModel.title + '-' + choice.mark"
        >
          <input
            v-model="choice.value"
            :name="choice.value"
            :placeholder="$t('studea_manager.questionnaire.option_placeholder')"
            class="form-control ms-3"
            type="text"
            @input="update"
          />
          <input
            v-model="choice.mark"
            v-if="itemModel.displayMark"
            :name="choice.mark"
            :placeholder="$t('studea_manager.questionnaire.value_placeholder')"
            class="form-control ms-3 mark"
            type="text"
            @input="update"
          />
        </label>
        <button
          v-if="Object.keys(itemModel.valueList).length > 1"
          class="btn btn-danger ms-2"
          @click="removeChoice($event, choice)"
        >
          <FontAwesomeIcon icon="fa-light fa-trash" />
        </button>
      </div>
    </VueDraggableNext>
    <button class="btn btnStyle btn-success mt-2" @click="addChoice">
      <FontAwesomeIcon icon="fa-light fa-plus" />
    </button>
  </div>
</template>

<script>
import { VueDraggableNext } from "vue-draggable-next";

export default {
  name: "QuestionnaireChoicesComponent",
  components: {
    VueDraggableNext,
  },
  data() {
    return {
      itemModel: this.item,
    };
  },
  props: {
    item: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: false,
      default: null,
    },
  },
  methods: {
    addChoice(e) {
      e.stopPropagation();
      this.itemModel.valueList.push({
        value: "",
        mark: "",
      });
      this.$emit("update");
    },

    removeChoice(e, choice) {
      e.stopPropagation();
      const index = this.itemModel.valueList.indexOf(choice);
      this.itemModel.valueList.splice(index, 1);
      this.$emit("update");
    },

    stopPropagation(e) {
      e.stopPropagation();
    },

    update() {
      this.$emit("update");
    },
  },
};
</script>

<style lang="scss" scoped>
.choice + .choice {
  margin-top: 10px;
}

.choice {
  padding-left: 40px;
  position: relative;
  width: auto;

  &:not(.custom-checkbox, .custom-radio) {
    padding-left: 30px;
  }
}

.choiceGrip {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: grab;
}

.mark {
  max-width: 100px;
  background-color: $white;
}
</style>
