<template>
  <AccordionSubColumnComponent
    v-if="type === 'questionnaire' || type === 'note'"
    :category="category"
    :crews="crews"
    :type="type"
    @manage="manage"
  />
  <AccordionSkillSubColumnComponent
    v-if="type === 'skill'"
    :category="category"
    :crews="crews"
    :type="type"
  />
</template>

<script>
import AccordionSubColumnComponent from "@/components/table/accordion/AccordionSubColumnComponent.vue";
import AccordionSkillSubColumnComponent from "@/components/table/accordion/AccordionSkillSubColumnComponent.vue";

export default {
  name: "AccordionColumnsComponent",
  components: { AccordionSkillSubColumnComponent, AccordionSubColumnComponent },
  props: {
    type: {
      type: String,
      require: true,
    },
    category: {
      required: true,
    },
    crews: {
      type: Array,
      required: true,
    },
  },
  methods: {
    manage(payload) {
      this.$emit("manage", payload);
    },
  },
};
</script>

<style lang="scss" scoped></style>
