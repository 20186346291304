<template>
  <div id="menu" ref="menu" class="d-md-none d-block menu">
    <button type="button" class="menu__close" @click="close">
      <FontAwesomeIcon icon="fa-light fa-xmark-large" />
    </button>
    <div class="menu__logo">
      <img
        :src="logoCfa ? logoCfa : '/assets/images/logo_studea.png'"
        alt="Logo Cfa"
        class="logo"
      />
      <div class="menu__version">
        v.{{ appWebVersion }}
        <span v-if="appBuildVersion"> - b.{{ appBuildVersion }}</span>
      </div>
    </div>
    <router-link :to="{ name: 'Dashboard' }" class="menu__item" @click="close">
      <span>
        <FontAwesomeIcon icon="fa-light fa-house" />
        <span class="menu__label">{{ $t("page.home") }}</span>
      </span>
      <FontAwesomeIcon icon="fa-light fa-chevron-right" />
    </router-link>
    <router-link
      v-if="Object.keys(trainingCourseList).length > 1"
      :to="{ name: 'TrainingCourseList' }"
      class="menu__item"
      @click="close"
    >
      <span>
        <FontAwesomeIcon icon="fa-light fa-users" />
        <span class="menu__label">{{ $t("Training courses list") }}</span>
      </span>
      <FontAwesomeIcon icon="fa-light fa-chevron-right" />
    </router-link>
    <template v-if="triad && triad.trainingCourse && triad.trainingCourse.menu">
      <template v-for="item in triad.trainingCourse.menu" :key="item.id">
        <router-link
          v-if="moduleIsAvailable(item)"
          :to="{ name: item.code }"
          class="menu__item"
          @click="close"
        >
          <span>
            <FontAwesomeIcon :icon="'fa-light fa-' + item.icon" />
            <span class="menu__label">{{ item.title }}</span>
          </span>
          <FontAwesomeIcon icon="fa-light fa-chevron-right" />
        </router-link>
      </template>
    </template>
    <button class="menu__logout" @click="logout">
      <FontAwesomeIcon icon="fa-light fa-arrow-right-from-bracket" />
      <span class="menu__label">{{ $t("logout") }}</span>
    </button>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { userManager } from "@/services/security/user-manager";
import jsonPackage from "../../package.json";
import { useTriadStore } from "@/store/triad/triad";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { usePlatformStore } from "@/store/platform/platform";
import { useMainStore } from "@/store/main/main";

export default {
  name: "MobileMenuComponent",
  data() {
    return {
      appVersion: jsonPackage.version,
    };
  },
  computed: {
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourseList: (store) => store.trainingCourseList,
    }),
    ...mapState(usePlatformStore, {
      logoCfa: (store) => store.logoCfa,
    }),
    ...mapState(useMainStore, {
      appBuildVersion: (store) => store.appBuildVersion,
      appWebVersion: (store) => store.appWebVersion,
    }),
  },
  methods: {
    close() {
      this.$refs.menu.classList.remove("show");
    },

    moduleIsAvailable(item) {
      return ![
        "MODULE_STUDEA_REGISTRATION",
        "MODULE_STUDEA_GENERAL",
        "MODULE_STUDEA_QUESTIONNAIRE",
        "MODULE_STUDEA_CONTROL",
        "MODULE_EMAILS",
        "MODULE_YPAREO",
        "MODULE_STUDEA_DRIVER_LICENCE",
      ].includes(item.code);
    },

    logout() {
      this.close();
      userManager.logout();
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  background: $white;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 50px 20px;
  font-size: 16px;
  font-weight: 700;
  overflow: scroll;
  transform: translateX(100%);
  transition: all 300ms ease-in-out;

  &.show {
    transform: translateX(0);
  }

  &__close {
    position: absolute;
    background: none;
    border: none;
    top: 15px;
    right: 15px;
    color: var(--primary-color);

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__logo {
    width: 100%;
    display: block;
    margin: 0 auto 10px auto;
    padding: 10px 30px 35px 30px;
    max-width: 350px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__logout {
    color: var(--primary-color);
    background: none;
    border: none;
    font-weight: 700;
    text-align: center;
    margin-top: 25px;
    width: 100%;
  }

  &__item {
    border: none;
    width: 100%;
    border-bottom: 1px solid $dark-grey;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;

    svg {
      width: 20px;
      height: 20px;
    }

    .fa-chevron-right {
      color: $dark-grey;
    }

    &:nth-child(4n + 2) {
      color: $purple;
    }

    &:nth-child(4n + 3) {
      color: $blue;
    }

    &:nth-child(4n + 4) {
      color: $green;
    }

    &:nth-child(4n + 1) {
      color: $red;
    }
  }

  &__label {
    margin-left: 10px;
  }

  &__version {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    color: var(--primary-color);
  }
}
</style>
