<template>
  <div
    :class="
      message.sender && message.sender.id === user.id ? 'me blockStyle' : 'you'
    "
    v-if="!message.censorship"
    class="bubble"
  >
    <div
      class="sender"
      v-if="Object.keys(message.messageRecipients).length > 2"
    >
      {{ message.sender.fullName }}
    </div>
    <div
      :class="
        message.sender && message.sender.id === user.id ? '' : 'textStyle'
      "
      v-html="message.text"
    ></div>
    <a
      v-if="message.messageFile"
      class="w-100 badge badge-primary btnStyle mb-1"
      :href="fileSrc"
    >
      <span v-if="this.fileLoading" class="spinner-border loader-sm"></span>
      <FontAwesomeIcon
        v-if="!isPicture(message)"
        icon="fa-light fa-paperclip"
      />
      <div v-if="isPicture(message)">
        <img :alt="message.messageFile.originalName" :src="imgSrc" />
      </div>
      <div v-else>{{ message.messageFile.originalName }}</div>
    </a>
    <div class="d-flex justify-content-between align-items-center">
      <div class="date">
        <span v-if="this.loading" class="spinner-border"></span>
        <FontAwesomeIcon v-if="!loading" icon="fa-light fa-check" />
        {{ formatDate(message.date) }}
      </div>
      <button class="report" type="button" @click="report(message)">
        {{ $t("chat.report") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { fileManager } from "@/services/file/file-manager";
import { notificationManager } from "@/services/utilities/notification-manager";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { dateFormatter } from "@/services/utilities/date-formatter";
import { pictureManager } from "@/services/utilities/picture-manager";
import { usePlatformStore } from "@/store/platform/platform";
import { useUserStore } from "@/store/user/user";
import { useMessageStore } from "@/store/message/message";

export default {
  name: "ChatMessageComponent",
  components: { FontAwesomeIcon },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fileLoading: false,
      loading: false,
      imgSrc: "",
      fileSrc: "",
    };
  },
  computed: {
    ...mapState(usePlatformStore, {
      design: (store) => store.design,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
  },
  mounted() {
    this.getDownloadSrc();
  },
  methods: {
    isPicture() {
      const isPicture = new RegExp("([./])(gif|jpe?g|png|svg+xml)$").test(
        this.message.messageFile.originalName,
      );
      if (isPicture && !this.fileLoading && !this.imgSrc) {
        this.getPictureSrc(this.message);
      }
      return isPicture;
    },

    formatDate(date) {
      return dateFormatter.format(date, "short");
    },

    getPictureSrc() {
      this.imgSrc = pictureManager.setPicture(this.message.messageFile);
    },

    getDownloadSrc() {
      if (this.message && this.message.messageFile) {
        this.fileSrc = fileManager.getDownloadSrc(this.message.messageFile);
      }
    },

    report(message) {
      notificationManager
        .showAlert(
          "warning",
          this.$t("chat.report"),
          this.$t("chat.report_confirm"),
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            this.loading = true;
            this.reportMessage(message).then(() => {
              notificationManager.showNotification(
                "success",
                this.$t("chat.report_success"),
              );
              this.loading = false;
            });
          }
        });
    },

    ...mapActions(useMessageStore, { reportMessage: "reportMessage" }),
  },
};
</script>

<style lang="scss" scoped>
.date {
  font-size: 11px;

  .spinner-border {
    width: 10px;
    height: 10px;
  }
}

.you {
  .report {
    color: var(--primary-color);
  }
}

.report {
  background: none;
  border: none;
  font-size: 12px;
}

.badge {
  padding: 8px;
  cursor: pointer;

  img {
    width: 100%;
  }
}

.sender {
  font-size: 14px;
  font-weight: 700;
}
</style>
