<template>
  <div id="triadTab" class="triadTab">
    <div
      v-if="isNotTrainingCourseList() && displayTriadSelectButton()"
      class="triadTab__item -triadList"
      :class="{
        studeaManagerAdmin:
          isStudeaManagerAdmin() || !displayStudeaAdminButton(),
        '-dashboard': !isStudeaManagerAdmin(),
      }"
    >
      <div
        class="triadTab__button btnStyle"
        v-tooltip="{ title: $t('triadList') }"
        @click="openContent($event)"
      >
        <FontAwesomeIcon class="triadTab__icon" icon="fa-light fa-users" />
      </div>
      <div class="triadTab__content -triadList">
        <TriadUsersListComponent
          :lateralTab="true"
          :search="true"
          :trainingCourse="trainingCourse"
          @triadLoaded="resetContent"
        />
      </div>
    </div>
    <div
      v-if="displayStudeaAdminButton() && trainingCourseId"
      class="triadTab__item"
    >
      <router-link
        :to="{
          name: 'studeaManagerLayout',
          params: { trainingCourseId: trainingCourseId },
        }"
        class="triadTab__button btnStyle"
        v-tooltip="{
          title:
            $t('studea_manager.home.title') +
            (this.design && this.design.label
              ? this.design.label.toLowerCase()
              : ''),
        }"
        @click="resetContent"
      >
        <FontAwesomeIcon class="triadTab__icon" icon="fa-light fa-gear" />
      </router-link>
    </div>
    <div
      v-if="
        trainingCourses &&
        Object.keys(trainingCourses).length &&
        trainingCourse &&
        trainingCourse.crews &&
        trainingCourse.isStudeaManager &&
        !isTrainingCourseList()
      "
      class="triadTab__item"
    >
      <router-link
        :to="{ name: 'TrainingCourseList' }"
        class="triadTab__button btnStyle"
        v-tooltip="{ title: $t('page.training_course_list') }"
        @click="resetContent"
      >
        <FontAwesomeIcon
          class="triadTab__icon"
          icon="fa-light fa-graduation-cap"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import TriadUsersListComponent from "@/components/triad/TriadUsersListComponent.vue";
import { mapState, mapActions } from "pinia";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { useTriadStore } from "@/store/triad/triad";
import { useUserStore } from "@/store/user/user";
import { usePlatformStore } from "@/store/platform/platform";

export default {
  name: "TriadSelectComponent",
  components: { TriadUsersListComponent },
  data() {
    return {
      content: "",
      label: "",
      trainingCourseId: null,
    };
  },
  computed: {
    ...mapState(useTrainingCourseStore, {
      trainingCourses: (store) => store.trainingCourseList,
      trainingCourse: (store) => store.trainingCourse,
    }),
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
    ...mapState(usePlatformStore, {
      design: (store) => store.design,
    }),
  },
  watch: {
    triad() {
      this.setTrainingCourseId();
    },
  },

  mounted() {
    this.setTrainingCourseId();
  },

  methods: {
    setTrainingCourseId() {
      if (this.triad && this.triad.id) {
        this.trainingCourseId = this.triad.trainingCourse.id;
      } else if (window.localStorage.getItem("studea-oldTrainingCourse")) {
        this.trainingCourseId = parseInt(
          window.localStorage.getItem("studea-oldTrainingCourse"),
        );
      }
      if (this.trainingCourseId) {
        window.localStorage.setItem(
          "studea-oldTrainingCourse",
          this.trainingCourseId,
        );
      }
    },

    openContent(event) {
      const target = event.currentTarget.parentNode;
      if (!target.classList.contains("expanded")) {
        const backdrop = document.getElementById("backdrop");
        const triadTab = document.getElementById("triadTab");
        const $this = this;

        this.resetContent();
        backdrop.classList.add("show");
        triadTab.classList.add("open");
        backdrop.addEventListener("click", function () {
          $this.resetContent();
          backdrop.classList.remove("show");
          setTimeout(function () {
            triadTab.classList.remove("open");
          }, 600);
        });

        setTimeout(function () {
          target.classList.add("expanded");
        }, 300);
      }
    },

    displayStudeaAdminButton() {
      return (
        this.trainingCourse &&
        this.trainingCourse.id &&
        Object.keys(this.trainingCourses).length &&
        this.trainingCourse.crews &&
        Object.keys(this.trainingCourse.crews).length &&
        this.trainingCourse.isStudeaManager &&
        !this.isStudeaManagerAdmin() &&
        this.isNotTrainingCourseList()
      );
    },

    displayTriadSelectButton() {
      console.log(this.trainingCourse.isStudeaManager);
      return (
        this.trainingCourse &&
        this.trainingCourse.id &&
        Object.keys(this.trainingCourses).length &&
        this.trainingCourse.crews &&
        this.trainingCourse.isStudeaManager
      );
    },

    resetContent() {
      const items = [].slice.call(document.querySelectorAll(".triadTab__item"));
      const backdrop = document.getElementById("backdrop");
      const triadTab = document.getElementById("triadTab");
      if (triadTab.classList.contains("open")) {
        setTimeout(function () {
          triadTab.classList.remove("open");
        }, 600);
      }
      if (backdrop) {
        backdrop.classList.remove("show");
      }
      items.map((item) => {
        item.classList.remove("expanded");
        item.classList.add("closing");
        setTimeout(function () {
          item.classList.remove("closing");
        }, 600);
      });
    },

    isStudeaManagerAdmin() {
      return !!this.$route.matched.find(
        (item) => item.name === "studeaManagerLayout",
      );
    },

    isTrainingCourseList() {
      return this.$route.name === "TrainingCourseList";
    },

    isNotTrainingCourseList() {
      return this.$route.name !== "TrainingCourseList";
    },

    ...mapActions(useTrainingCourseStore, {
      fetchTrainingCourseTriads: "fetchTrainingCourseTriads",
    }),
  },
};
</script>

<style lang="scss" scoped>
.triadTab {
  position: fixed;
  display: inline-block;
  bottom: 0;
  right: 15px;
  z-index: 1051;
  min-height: 60px;
  max-width: 600px;
  min-width: 0;
  transition: all 300ms ease-in-out;

  &.open {
    height: auto;
  }

  @include up-to-md {
    display: none;
  }

  &__item {
    max-height: 60px;
    max-width: 60px;
    width: 100%;
    position: relative;
    right: 0;
    transform: translateY(100%);
    transition-property: max-width, transform;
    transition-duration: 300ms, 300ms;
    transition-timing-function: ease, ease-in-out;

    &:nth-child(1) {
      right: 150px;
      top: 0;

      &.studeaManagerAdmin {
        right: 75px;
      }
    }

    &:nth-child(2),
    &:nth-child(3) {
      position: absolute;
      right: 0;
      bottom: 0;
    }

    &:nth-child(3) {
      right: 75px;
    }

    &:only-child {
      .triadTab__button {
        right: 25px;
      }
    }

    &.-login {
      right: 25px;
    }

    &.-triadList {
      right: 75px;

      &.-dashboard {
        right: 150px;
      }
    }

    @include up-to-lg {
      max-height: 600px;
    }

    &.closing {
      transition-delay: 300ms, 0ms;
    }

    &.expanded {
      transform: translateY(0);
      max-width: 600px;
      max-height: 600px;
      transition-delay: 0ms, 300ms;

      .triadTab__button {
        box-shadow: none;
      }

      &.-triadList {
        right: 125px;

        &.-dashboard {
          right: 200px;
        }
      }
    }
  }

  &__label {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
    color: $blue;

    @include dark-theme {
      color: $white;
    }
  }

  &__button {
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 60px;
    background: $red;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    border-radius: $radius $radius 0 0;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;

    &:hover {
      background: $white !important;

      .triadTab__icon {
        color: var(--secondary-color);
      }
    }

    @include dark-theme {
      background: $m-color_10;
      border: none;
    }
  }

  &__icon {
    font-size: 25px;
    color: $white;

    @include dark-theme {
      color: $white;
    }
  }

  &__content {
    background: $white;
    border-radius: $radius $radius 0 0;
    border: 1px solid $m-color_3;
    position: relative;
    z-index: 1;
    overflow: scroll;
    padding: 20px;
    min-height: 350px;
    max-height: 350px;

    &.-login {
      min-width: 500px;
      max-width: 500px;
    }

    .widget {
      margin-bottom: 0;
      border: none;
    }

    &.-triadList {
      max-width: 650px;
      min-width: 650px;
    }

    @include dark-theme {
      background: $m-color-10;
      color: $white;
      border: none;
    }
  }
}
</style>
