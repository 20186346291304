<template>
  <div :class="'signBadges' + (position ? ' -' + position : '')">
    <div
      v-if="
        openedQuestions
          ? itemModel.openedQuestionsAccess.includes('1') ||
            itemModel.openedQuestionsAccess.includes(1)
          : itemModel.access &&
            (itemModel.access.includes('1') || itemModel.access.includes(1))
      "
      class="badge -apprentice"
    >
      {{
        apprenticeProfileLabel.titleAbbreviation
          ? apprenticeProfileLabel.titleAbbreviation.toUpperCase()
          : "A"
      }}
    </div>
    <div
      v-if="
        openedQuestions
          ? itemModel.openedQuestionsAccess.includes('2') ||
            itemModel.openedQuestionsAccess.includes(2)
          : itemModel.access &&
            (itemModel.access.includes('2') || itemModel.access.includes(2))
      "
      class="badge -tutor"
    >
      {{
        tutorProfileLabel.titleAbbreviation
          ? tutorProfileLabel.titleAbbreviation.toUpperCase()
          : "T"
      }}
    </div>
    <div
      v-if="
        openedQuestions
          ? itemModel.openedQuestionsAccess.includes('3') ||
            itemModel.openedQuestionsAccess.includes(3)
          : itemModel.access &&
            (itemModel.access.includes('3') || itemModel.access.includes(3))
      "
      class="badge -apprenticeMaster"
    >
      {{
        apprenticeMasterProfileLabel.titleAbbreviation
          ? apprenticeMasterProfileLabel.titleAbbreviation.toUpperCase()
          : "M"
      }}
    </div>
    <div
      v-if="
        openedQuestions
          ? itemModel.openedQuestionsAccess.includes('4') ||
            itemModel.openedQuestionsAccess.includes(4)
          : itemModel.access &&
            (itemModel.access.includes('4') || itemModel.access.includes(4))
      "
      class="badge -studeaManager"
    >
      {{
        studeaManagerProfileLabel.titleAbbreviation
          ? studeaManagerProfileLabel.titleAbbreviation.toUpperCase()
          : "SM"
      }}
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { usePlatformStore } from "@/store/platform/platform";

export default {
  name: "QuestionnaireSignBadgeComponent",
  props: {
    position: {
      type: String,
      required: false,
      default: "",
    },
    openedQuestions: {
      type: Boolean,
      required: false,
      default: false,
    },
    itemModel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(usePlatformStore, {
      apprenticeProfileLabel: (store) => store.apprenticeProfileLabel,
      tutorProfileLabel: (store) => store.tutorProfileLabel,
      apprenticeMasterProfileLabel: (store) =>
        store.apprenticeMasterProfileLabel,
      studeaManagerProfileLabel: (store) => store.studeaManagerProfileLabel,
    }),
  },
};
</script>

<style lang="scss" scoped>
.signBadges {
  display: flex;
  position: absolute;
  right: 30px;
  top: 20px;
  flex-wrap: wrap;

  &.-bottomRight {
    top: initial;
    bottom: 20px;
  }

  &.-centered {
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    max-width: 50px;

    @include up-to-md {
      max-width: initial;
      top: -5px;
      transform: none;
    }

    .badge + .badge {
      margin: 0;
    }

    .badge:nth-child(2n) {
      margin-left: 3px;
      @include up-to-md {
        margin-left: 0;
      }
    }

    .badge:nth-child(3n),
    .badge:nth-child(4n) {
      margin-top: 3px;

      @include up-to-md {
        margin-top: 0;
      }
    }

    .badge + .badge {
      @include up-to-md {
        margin-left: 5px;
      }
    }
  }

  .badge {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23px;
    height: 23px;
    font-size: 10px;
    background: $orange;

    &.-apprentice {
      background: $green;
    }

    &.-tutor {
      background: $purple;
    }

    &.-apprenticeMaster {
      background: $red;
    }
  }

  .badge + .badge {
    margin-left: 5px;
  }
}
</style>
