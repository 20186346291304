<template>
  <div class="widget">
    <div class="widget-heading justify-content-start">
      <button
        class="btn-icon me-2"
        v-tooltip="{ title: $t('back') }"
        @click="$router.back()"
      >
        <FontAwesomeIcon icon="fa-light fa-arrow-left" />
      </button>
      <h2>
        {{
          $tc("global.view.title", 1, {
            name: $t("admin.mail.title"),
          })
        }}
      </h2>
    </div>
    <div class="widget-content">
      <template v-if="Object.keys(mail).length">
        <p class="m-0 fw-bold">{{ $t("admin.mail.recipient") }}</p>
        <p class="m-0" v-if="mail.user">
          {{ mail.user.fullName }} ({{ mail.email }})
        </p>
        <p class="d-flex align-items-center m-0">
          Statut d'envoi :
          <span
            class="dot ms-1"
            :class="{
              'bg-success': mail.status === 1,
              'bg-warning': mail.status === 0,
              'bg-danger': mail.status === 2,
            }"
          ></span>
        </p>
        <p class="m-0" v-if="mail.sendDate">
          {{ $t("admin.mail.sent_on") }}
          {{ dateFormatter().format(mail.sendDate, "short") }}
        </p>
        <p class="m-0" v-if="mail.sendDate">
          {{ $t("admin.mail.open_on") }}
          {{ dateFormatter().format(mail.sendDate, "short") }}
        </p>
        <h6 class="my-3 fw-bold">{{ mail.subject }}</h6>
        <p v-html="mail.body"></p>
      </template>
      <BlockLoader :loading="loading" />
    </div>
  </div>
</template>

<script>
import { adminManager } from "@/services/admin/admin-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { dateFormatter } from "../../../services/utilities/date-formatter";

export default {
  name: "MailViewView",
  components: { BlockLoader },
  data() {
    return {
      mail: {},
      loading: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    dateFormatter() {
      return dateFormatter;
    },
    init() {
      this.loading = true;
      this.getDatas();
    },

    getDatas() {
      adminManager.get("mail", this.$route.params.id).then((data) => {
        this.mail = data;
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
