<template>
  <h1 :class="{ start: !design.name }">
    <span class="fw-normal fs-6">{{
      design && design.name ? $t("loginTo") : $t("loginMobile")
    }}</span
    ><br />
    <span class="brand-name textStyle">{{ platformName }}</span>
  </h1>
  <div v-if="message" class="badge badge-danger me-1 w-100">
    {{ message }}
  </div>
  <form class="text-start">
    <div class="form">
      <div id="username-field" class="field-wrapper input">
        <svg
          class="feather feather-user"
          fill="none"
          height="24"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
          <circle cx="12" cy="7" r="4"></circle>
        </svg>
        <input
          v-model="login"
          :placeholder="$t('username')"
          class="form-control"
          type="text"
          @input="reset"
        />
        <div v-if="loginError" class="invalid-feedback d-block">
          {{ loginError }}
        </div>
      </div>
      <div v-if="Object.keys(choices).length > 1" class="field-wrapper input">
        <svg
          class="feather feather-globe"
          data-v-5522efca=""
          fill="none"
          height="24"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="2" x2="22" y1="12" y2="12"></line>
          <path
            d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"
          ></path>
        </svg>
        <select class="form-select" @change="select($event)">
          <option
            v-for="platform in choices"
            :key="platform.value"
            :value="platform.value"
          >
            {{ platform.label }}
          </option>
        </select>
      </div>

      <div v-if="platform" id="password-field" class="field-wrapper input mb-2">
        <svg
          class="feather feather-lock"
          fill="none"
          height="24"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect height="11" rx="2" ry="2" width="18" x="3" y="11"></rect>
          <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
        </svg>
        <PasswordTypeComponent
          :password="password"
          @checkInput="checkInput('password')"
          @changePassword="changePassword"
        />
        <div v-if="passwordError" class="invalid-feedback d-block">
          {{ passwordError }}
        </div>
      </div>
      <div class="d-sm-flex flex-column">
        <div class="field-wrapper text-center mt-3">
          <button
            class="btn btn-primary blockStyle"
            type="submit"
            @click="next($event)"
          >
            <span
              v-if="this.loading"
              class="spinner-border loader-sm me-2"
            ></span>
            <span v-if="!this.loading">{{
              this.platform ? $t("login") : $t("confirm")
            }}</span>
            <span v-else>{{ $t("loading") }}</span>
          </button>
        </div>
      </div>

      <div class="field-wrapper">
        <router-link
          class="forgot-pass-link"
          :to="{ name: 'loginResetPassword' }"
          >{{ $t("forgotPasswordLink") }}
        </router-link>
      </div>
    </div>
  </form>
  <div
    :class="{
      'form-footer': true,
      abs: Object.keys(choices).length <= 1,
    }"
  ></div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import "@suadelabs/vue3-multiselect/dist/vue3-multiselect.css";
import { loginManager } from "@/services/security/login-manager";
import { usePlatformStore } from "@/store/platform/platform";
import { useUserStore } from "@/store/user/user";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { useMainStore } from "@/store/main/main";
import PasswordTypeComponent from "@/components/form/PasswordTypeComponent.vue";

export default {
  name: "MobileLoginView",
  components: { PasswordTypeComponent },
  data() {
    return {
      message: "",
      choices: [],
      platform: "",
      login: "",
      loginError: "",
      password: "",
      passwordError: "",
      loading: false,
      showPassword: false,
      platformName: "",
    };
  },
  computed: {
    ...mapState(usePlatformStore, {
      design: (store) => store.design,
      platforms: (store) => store.platforms,
      logo: (store) => store.logoCfa,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourses: (store) => store.trainingCourseList,
    }),
  },
  created() {
    (this.platformName = window.localStorage.getItem("studea-platformName")
      ? window.localStorage.getItem("studea-platformName")
      : ""),
      setTimeout(() => {
        this.setAppLoading(false);
      }, 2000);
  },
  methods: {
    reset() {
      this.choices = [];
      this.platform = "";
      this.setPlatforms({});
      this.password = "";
      this.checkInput("login");
    },

    checkInput(input) {
      if (!this.$data[`${input}`]) {
        this.$data[`${input}Error`] = this.$t("requiredField");
      } else {
        this.$data[`${input}Error`] = "";
      }
    },

    next(e) {
      e.preventDefault();
      this.message = "";

      if (!this.login) {
        this.loginError = this.$t("requiredField");
      }

      this.loading = true;
      if (!Object.keys(this.choices).length && this.login) {
        this.fetchPlatformFromUser(this.login).then(() => {
          let choices = [];
          if (Object.keys(this.platforms).length) {
            this.platforms.forEach(function (platform) {
              choices.push({ label: platform.name, value: platform.hostname });
            });
            this.choices = choices;
            this.platform = choices[0];
            const platform = this.platforms.find(
              (item) => item.hostname === this.platform.value,
            );
            this.setPlatform(platform);
            this.platformName = platform.name;
            this.setPlatformName(platform.name);
            this.fetchDesign({ disableLoading: false });
          } else {
            this.message = this.$t("badCredentials");
          }
          this.loading = false;
        });
      } else if (this.platform && this.password) {
        loginManager.login(this.login, this.password, true).then((response) => {
          if (!response.success) {
            this.message = this.$t("badCredentials");
          } else {
            this.setAppLoading(true);
            window.localStorage.removeItem("studea-oldTriad");
            window.localStorage.removeItem("studea-oldTrainingCourse");
            this.message = "";
            this.fetchUserInfos().then(() => {
              this.fetchTrainingCourseList({}).then(() => {
                if (
                  this.user &&
                  this.user.roles &&
                  this.user.roles.includes("ROLE_STUDEA_ADMIN")
                ) {
                  this.setAppLoading(false);
                  window.localStorage.setItem(
                    "studea-returnPathName",
                    JSON.stringify({ name: "TrainingCourseList" }),
                  );
                  this.$router.push({ name: "adminHome" });
                } else if (Object.keys(this.trainingCourses).length > 1) {
                  this.setAppLoading(false);
                  this.$router.push({ name: "TrainingCourseList" });
                } else {
                  this.setAppLoading(false);
                  this.$router.push({ name: "Dashboard" });
                }
              });
            });
          }
          this.loading = false;
        });
      } else if (!this.password && this.login) {
        this.passwordError = this.$t("requiredField");
      }
    },

    select(e) {
      const platform = Object.values(this.platforms).find(
        (item) => item.hostname === e.target.value,
      );
      this.setPlatform(platform);
      this.platformName = platform.name;
      this.setPlatformName(platform.name);
      this.fetchDesign({ disableLoading: false });
    },

    changePassword(password) {
      this.password = password;
    },

    ...mapActions(usePlatformStore, {
      setPlatforms: "setPlatforms",
      setPlatform: "setPlatform",
      setPlatformName: "setPlatformName",
      fetchPlatformFromUser: "fetchPlatformFromUser",
      fetchDesign: "fetchDesign",
    }),
    ...mapActions(useMainStore, { setAppLoading: "setAppLoading" }),
    ...mapActions(useUserStore, { fetchUserInfos: "fetchUserInfos" }),
    ...mapActions(useTrainingCourseStore, {
      fetchTrainingCourseList: "fetchTrainingCourseList",
    }),
  },
};
</script>

<style lang="scss" scoped>
.form-footer {
  margin-bottom: 20px;

  &.abs {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin-bottom: 0;
  }
}
</style>
