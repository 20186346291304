import { notificationManager } from "@/services/utilities/notification-manager";
import router from "@/router";
import { loginManager } from "@/services/security/login-manager";
import { adminManager } from "@/services/admin/admin-manager";
import { userStore } from "@/main";
import { apiConnection } from "@/services/api-connection";

class AccessManager {
  ALL = 4;
  DELETE = 3;
  EDIT = 2;
  VIEW = 1;
  NONE = 0;

  checkRoleAccess(to, from) {
    return new Promise((resolve) => {
      const magicLink = apiConnection.getMagicLinkToken();
      if (to.meta && to.meta.authRequired && !magicLink) {
        let user = userStore.user;
        if (!Object.keys(user).length) {
          loginManager.refreshConnection().then(() => {
            userStore.fetchUserInfos().then(() => {
              let user = userStore.user;
              if (user && user.roles) {
                if (this.checkAccessByRoles(to, from, user)) {
                  resolve(
                    this.checkAccessByPermission(to, from).then((res) => res),
                  );
                }
              } else {
                router.push({ name: "Login" });
              }
            });
          });
        } else {
          if (user.roles) {
            if (this.checkAccessByRoles(to, from, user)) {
              resolve(
                this.checkAccessByPermission(to, from).then((res) => res),
              );
            }
          } else {
            router.push({ name: "Login" });
          }
        }
      } else {
        resolve(true);
      }
    });
  }

  checkAccessByRoles(to, from, user) {
    if (user && user.roles) {
      if (
        to.meta.required_role &&
        !user.roles.includes(to.meta.required_role) &&
        !user.roles.includes("ROLE_STUDEA_ADMIN")
      ) {
        this.redirect(from);
        return false;
      } else {
        return true;
      }
    }
  }

  checkAccessByPermission(to, from) {
    return new Promise((resolve) => {
      let permissions = userStore.permissions;
      let trainingCourse;
      if (
        userStore.user.roles &&
        userStore.user.roles.length &&
        !userStore.user.roles.includes("ROLE_STUDEA_ADMIN")
      ) {
        if (to.params.trainingCourseId) {
          trainingCourse = to.params.trainingCourseId;
        } else if (window.localStorage.getItem("studea-oldTrainingCourse")) {
          trainingCourse = window.localStorage.getItem(
            "studea-oldTrainingCourse",
          );
        }
      }
      if (
        !Object.values(permissions).length ||
        (trainingCourse &&
          trainingCourse.toString() !==
            window.localStorage.getItem("studea-oldTrainingCourse"))
      ) {
        userStore.fetchPermissions(trainingCourse).then(() => {
          resolve(this.checkPermission(to, from));
        });
      } else {
        resolve(this.checkPermission(to, from));
      }
    });
  }

  checkPermission(to, from) {
    const available = this.isAvailableForUser(to);
    if (!available) {
      this.redirect(from);
      return false;
    } else {
      return true;
    }
  }

  isAvailableForUser(to = null, code = null, access = null) {
    if (!code) {
      if (to && to.meta) {
        if (to.meta.code) {
          code = to.meta.code;
        } else if (to.meta.generic) {
          code = this.getGenericCode(to.meta.generic, to.params.alias);
        }
        if (to.meta.access) {
          access = to.meta.access;
        }
      } else if (router.currentRoute.value.meta.code) {
        code = router.currentRoute.value.meta.code;
      }
    }
    const permissions = userStore.permissions;

    if (code && access) {
      return (
        permissions[code] &&
        this.getPermissionLevel(permissions[code]) >= access
      );
    }
    return true;
  }

  getGenericCode(type, alias) {
    switch (type) {
      case "admin":
        switch (alias) {
          case " ":
            return adminManager.STUDEA_ADMIN_DEGREE;
          case "contract-type":
            return adminManager.STUDEA_ADMIN_CONTRACT_TYPE;
          case "plan-contract":
            return adminManager.STUDEA_ADMIN_PLAN_CONTRACT;
          case "title-rncp":
            return adminManager.STUDEA_ADMIN_TITLE_RNCP;
          case "document":
          case "document-category":
            return adminManager.STUDEA_ADMIN_DOCUMENT;
          case "template":
            return adminManager.STUDEA_ADMIN_TEMPLATE;
          case "news":
            return adminManager.STUDEA_ADMIN_NEWS;
        }
    }
  }

  getPermissionLevel(value) {
    switch (value) {
      case "ALL":
        return this.ALL;
      case "DELETE":
        return this.DELETE;
      case "EDIT":
        return this.EDIT;
      case "VIEW":
        return this.VIEW;
      case "NONE":
        return this.NONE;
    }
  }

  redirect(from) {
    router.push(from);
    notificationManager.showAlert(
      "error",
      "Accès non autorisé",
      "Attention, vous n'avez pas les autorisations nécessaires pour accéder à cette page",
    );
  }
}

export const accessManager = new AccessManager();
