<template>
  <div
    :class="
      'widget bgStyle' +
      (itemModel.selected && !child ? ' selected' : ' drag') +
      (front ? ' front' : '')
    "
    @click="selectBlock"
  >
    <div
      v-if="!front && !notSortable"
      :class="'grip' + (itemModel.selected ? ' drag' : '')"
    >
      <FontAwesomeIcon icon="fa-light fa-grip-lines-vertical" />
    </div>
    <div v-if="itemModel.selected" class="widget-header">
      <div class="question__grid">
        <div class="question__title">
          <InputTypeComponent :model="title" @update="updateTitle" />
        </div>
        <div v-if="!child" class="question__type">
          <SelectTypeComponent :model="fieldType" @update="selectType" />
        </div>
        <div class="question__settings">
          <div class="question__tools">
            <button
              class="btn btn-secondary"
              v-tooltip="{
                title: $t('studea_manager.questionnaire.row_settings'),
              }"
              type="button"
              @click="rights"
            >
              <FontAwesomeIcon icon="fa-light fa-users" />
            </button>
            <button
              v-if="isChoice"
              class="btn btn-secondary ms-2"
              v-tooltip="{
                title: $t('studea_manager.questionnaire.question_settings'),
              }"
              type="button"
              @click="settings"
            >
              <FontAwesomeIcon icon="fa-light fa-gears" />
            </button>
            <button
              v-if="isFieldSkillAvailable()"
              class="btn btn-secondary ms-2"
              v-tooltip="{
                title: $t('studea_manager.questionnaire.skill_settings'),
              }"
              type="button"
              @click="skills"
            >
              <FontAwesomeIcon icon="fa-light fa-medal" />
            </button>
            <button
              class="btn btn-success btnStyle ms-2"
              v-tooltip="{
                title: $t('studea_manager.questionnaire.duplicate_question'),
              }"
              type="button"
              @click="duplicate"
            >
              <FontAwesomeIcon icon="fa-light fa-copy" />
            </button>
            <button
              class="btn btn-danger ms-2"
              v-tooltip="{
                title: $t('studea_manager.questionnaire.remove_question'),
              }"
              type="button"
              @click="remove"
            >
              <FontAwesomeIcon icon="fa-light fa-trash" />
            </button>
            <div class="required">
              {{ $t("studea_manager.questionnaire.tip") }}
              <SwitchTypeComponent :model="tip" @update="setTip" />
            </div>
            <div class="required">
              {{ $t("studea_manager.questionnaire.required") }}
              <SwitchTypeComponent :model="required()" @update="setRequired" />
            </div>
            <button
              class="btn btn-success btnStyle ms-2"
              v-tooltip="{ title: $t('studea_manager.questionnaire.confirm') }"
              type="button"
              @click="close"
            >
              <FontAwesomeIcon icon="fa-light fa-check" />
            </button>
          </div>
        </div>
      </div>
      <div v-if="itemModel.tipActive" class="mt-2">
        <InputTypeComponent :model="tipField" @update="updateTip" />
      </div>
    </div>
    <div
      v-if="!itemModel.selected || (isChoice && itemModel.selected)"
      class="widget-content"
    >
      <ModelComponent
        v-if="displayModel()"
        :model="front ? model[this.itemModel.id] : field"
        @draft="saveDraft"
        @update="updateAnswer"
        @focused="focused"
      />
      <div
        v-if="
          this.itemModel.id === 'interviewDate' &&
          this.itemModel.answer &&
          !this.itemModel.enabled
        "
      >
        {{ itemModel.answer }}
      </div>
      <div class="mt-3" v-if="questionCommentModel">
        <ModelComponent
          :model="questionCommentModel"
          @draft="saveDraft"
          @update="updateComment"
        />
      </div>
      <QuestionnaireChoicesComponent
        v-if="isChoice && itemModel.selected"
        :index="index + 1"
        :item="itemModel"
        @update="choicesUpdated"
      />
      <QuestionnaireSignBadgeComponent
        v-if="!itemModel.selected"
        :itemModel="itemModel"
        :front="front"
      />
      <QuestionnaireAddCommentComponent
        v-if="
          front &&
          itemModel.answer &&
          itemModel.answer.answerComments &&
          !disabled()
        "
        :answer="answer"
        :commenting="commenting"
        :item="item"
        :onQuestion="true"
        @draft="saveDraft"
        @toggleComment="toggleComment"
        @isLoading="isLoading"
      />
    </div>
    <div v-if="!front && itemModel.titleError" class="error">
      <FontAwesomeIcon icon="fa-light fa-triangle-exclamation" />
      {{ $t("studea_manager.questionnaire.title_missing") }}
    </div>
    <div v-if="!front && itemModel.accessError" class="error">
      <FontAwesomeIcon icon="fa-light fa-triangle-exclamation" />
      {{ $t("studea_manager.questionnaire.access_missing") }}
    </div>
    <template v-if="front">
      <div
        v-for="answer in itemModel.fieldAnswers"
        :key="answer"
        class="answer-container"
      >
        <QuestionnaireAnswerComponent
          :answer="answer"
          :item="item"
          :disabled="disabled()"
          @draft="saveDraft"
          @commentAdded="commentAdded"
          @isLoading="setLoading"
        />
      </div>
    </template>
  </div>
  <QuestionnaireCommentComponent
    v-if="front && itemModel.answer && itemModel.answer.answerComments"
    :comments="itemModel.answer.answerComments"
  />
</template>

<script>
import InputTypeComponent from "@/components/form/InputTypeComponent.vue";
import ModelComponent from "@/components/form/ModelComponent.vue";
import SelectTypeComponent from "@/components/form/SelectTypeComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SwitchTypeComponent from "@/components/form/SwitchTypeComponent.vue";
import { notificationManager } from "@/services/utilities/notification-manager";
import QuestionnaireChoicesComponent from "@/components/questionnaire/editor/QuestionnaireChoicesComponent.vue";
import QuestionnaireSignBadgeComponent from "@/components/questionnaire/editor/QuestionnaireSignBadgeComponent.vue";
import { userManager } from "@/services/security/user-manager";
import QuestionnaireCommentComponent from "@/components/questionnaire/QuestionnaireCommentComponent.vue";
import QuestionnaireAddCommentComponent from "@/components/questionnaire/QuestionnaireAddCommentComponent.vue";
import { questionnaireManager } from "@/services/questionnaire/questionnaire-manager";
import { mapState, mapActions } from "pinia";
import { useQuestionnaireStore } from "@/store/questionnaire/questionnaire";
import { useTriadStore } from "@/store/triad/triad";
import QuestionnaireAnswerComponent from "@/components/questionnaire/QuestionnaireAnswerComponent.vue";

export default {
  name: "QuestionnaireQuestionComponent",
  components: {
    QuestionnaireAnswerComponent,
    QuestionnaireAddCommentComponent,
    QuestionnaireCommentComponent,
    QuestionnaireSignBadgeComponent,
    QuestionnaireChoicesComponent,
    SwitchTypeComponent,
    FontAwesomeIcon,
    SelectTypeComponent,
    ModelComponent,
    InputTypeComponent,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
      default: 0,
    },
    child: {
      type: Boolean,
      required: false,
      default: false,
    },
    front: {
      type: Boolean,
      required: false,
      default: false,
    },
    notSortable: {
      type: Boolean,
      required: false,
      default: false,
    },
    model: {
      type: Object,
      required: false,
    },
  },
  emits: [
    "draft",
    "update",
    "focused",
    "isLoading",
    "duplicate",
    "remove",
    "rights",
    "selectBlock",
    "settings",
    "skills",
  ],
  data() {
    return {
      itemModel: this.item,
      frontModel: [],
      answer: null,
      commenting: false,
      questionCommentModel: null,
      field: {
        vars: {
          value: "",
          id: this.item.title,
          name: this.item.title,
          label: this.item.title
            ? this.item.title
            : this.$t("studea_manager.questionnaire.question_title"),
          block_prefixes: [],
          required: false,
          disabled: true,
          columns: 12,
          help: this.item.tip,
          attr: {
            placeholder: "",
            class: "questionnaire-editor",
          },
          label_attr: {
            class: "position-relative",
          },
        },
      },
    };
  },

  watch: {
    "itemModel.type"() {
      this.updateField();
    },
    triad() {
      if (this.front) {
        this.setAnswer();
      }
    },
  },

  created() {
    this.updateField(false);
  },

  mounted() {
    if (this.front) {
      this.setAnswer();
      this.setQuestionCommentModel();
    }
  },

  computed: {
    isChoice() {
      return [2, 3, 4].includes(this.itemModel.type);
    },

    isFile() {
      return this.itemModel.type === 10;
    },

    block_prefixes() {
      let block_prefixes = [];
      switch (this.itemModel.type) {
        case 0:
          block_prefixes = ["", "text"];
          break;
        case 1:
          block_prefixes = ["", "text", "textarea", "zama_tiny_mce_type"];
          break;
        case 2:
        case 3:
        case 4:
          block_prefixes = ["", "choice"];
          break;
        case 8:
          block_prefixes = ["", "number"];
          break;
        case 10:
          block_prefixes = ["", "file"];
          break;
        case 11:
          block_prefixes = ["", "date"];
          break;
        case 12:
          block_prefixes = ["", "date", "datetime"];
          break;
      }
      return block_prefixes;
    },

    title() {
      return {
        vars: {
          value: this.itemModel.title,
          id: "title-" + this.index,
          name: "title-" + this.itemModel.title,
          block_prefixes: ["", "text"],
          required: false,
          columns: 12,
          attr: {
            placeholder: this.$t("studea_manager.questionnaire.question_title"),
          },
        },
      };
    },

    commentModel() {
      return {
        vars: {
          value: this.front
            ? ""
            : this.$t("studea_manager.questionnaire.comment_placeholder"),
          id: "commment-" + this.index,
          name: "commment-" + this.itemModel.title,
          block_prefixes:
            this.itemModel.commentsType === 1
              ? ["", "text"]
              : ["", "text", "textarea"],
          required: false,
          columns: 12,
          attr: {
            placeholder: this.$t(
              "studea_manager.questionnaire.comment_placeholder",
            ),
          },
        },
      };
    },

    tip() {
      return {
        vars: {
          value: this.itemModel.tipActive,
          id: "tip-" + this.index,
          name: "tip-" + this.itemModel.title,
          block_prefixes: ["", "text"],
          required: false,
          columns: 12,
          attr: {
            placeholder: this.$t(
              "studea_manager.questionnaire.infobulle_content",
            ),
          },
        },
      };
    },

    fieldType() {
      return {
        vars: {
          value: this.itemModel.type,
          id: "fieldType-" + this.index,
          name: "fieldType-" + this.itemModel.title,
          block_prefixes: ["", "choice"],
          expanded: false,
          multiple: false,
          required: false,
          columns: 12,
          attr: {
            placeholder: this.$t(
              "studea_manager.questionnaire.field_type_choose",
            ),
          },
          choices: [
            {
              label: this.$t("studea_manager.questionnaire.short_answer"),
              value: "0",
            },
            {
              label: this.$t("studea_manager.questionnaire.textarea"),
              value: "1",
            },
            {
              label: this.$t("studea_manager.questionnaire.number"),
              value: "8",
            },
            {
              label: this.$t("studea_manager.questionnaire.dropdown_list"),
              value: "2",
            },
            {
              label: this.$t("studea_manager.questionnaire.unique_choice"),
              value: "3",
            },
            {
              label: this.$t("studea_manager.questionnaire.multiple_choices"),
              value: "4",
            },
            {
              label: this.$t("studea_manager.questionnaire.file"),
              value: "10",
            },
            {
              label: this.$t("studea_manager.questionnaire.date"),
              value: "11",
            },
            {
              label: this.$t("studea_manager.questionnaire.date_time"),
              value: "12",
            },
          ],
        },
      };
    },

    tipField() {
      return {
        vars: {
          value: this.itemModel.tip,
          id: "tip-" + this.index,
          name: "tip-" + this.itemModel.title,
          block_prefixes: ["", "choice"],
          attr: {
            placeholder: this.$t(
              "studea_manager.questionnaire.infobulle_content",
            ),
          },
        },
      };
    },

    placeholder() {
      let placeholder = "";
      switch (this.itemModel.type) {
        case 0:
          placeholder = this.$t("studea_manager.questionnaire.short_answer");
          break;
        case 1:
          placeholder = this.$t("studea_manager.questionnaire.long_answer");
          break;
        case 2:
          placeholder = this.$t("studea_manager.questionnaire.choose_option");
          break;
        case 8:
          placeholder = "25";
          break;
        case 11:
          placeholder = "2024-01-01";
          break;
        case 12:
          placeholder = "2024-01-01 11:00";
          break;
      }
      return placeholder;
    },

    ...mapState(useQuestionnaireStore, {
      questionnaire: (store) => store.questionnaire,
    }),
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
  },
  methods: {
    selectBlock() {
      if (!this.itemModel.selected || this.child) {
        this.$emit("selectBlock", this.itemModel.index);
      }
    },

    required() {
      return {
        vars: {
          value: !!this.itemModel.required,
          id: "required-" + this.index,
          name: "required-" + this.itemModel.title,
          block_prefixes: ["", "choice"],
        },
      };
    },

    focused(id) {
      this.$emit("focused", id);
    },

    setRequired(value) {
      this.itemModel.required = value;
      this.$emit("update");
    },

    setTip(value) {
      this.itemModel.tipActive = value;
      if (!value) {
        this.itemModel.tip = "";
      }
      this.$emit("update");
    },

    setAnswer() {
      if (
        this.questionnaire.userReply &&
        this.questionnaire.userReply.answersValues
      ) {
        this.answer = Object.values(
          this.questionnaire.userReply.answersValues,
        ).find((item) => item.fieldId === this.itemModel.id.toString());
      }
    },

    disabled() {
      if (this.triad.userRoles) {
        const key = userManager.getProfileKey(this.triad.userRoles[0]);
        const reply = this.questionnaire.lastReply[key];
        return (
          (reply && !!reply.signedAt) ||
          (this.itemModel.access &&
            this.itemModel.access.filter((item) =>
              this.triad.userRoles.includes(parseInt(item)),
            ).length === 0)
        );
      }
    },

    remove(e) {
      e.stopPropagation();
      notificationManager
        .showAlert(
          "error",
          this.$t("studea_manager.questionnaire.remove_question_warning"),
          "",
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            this.$emit("remove", this.index);
          }
        });
    },

    displayModel() {
      return (
        !this.itemModel.selected &&
        (!this.front || this.model[this.itemModel.id])
      );
    },

    close(e) {
      e.stopPropagation();
      this.$emit("selectBlock", null);
    },

    duplicate(e) {
      e.stopPropagation();
      this.$emit("duplicate", { id: this.itemModel.index, index: this.index });
    },

    selectType(value) {
      this.itemModel.type = parseInt(value);
      this.$emit("update");
    },

    updateTitle(value) {
      this.itemModel.title = value;
      this.field.vars.label = value
        ? value
        : this.$t("studea_manager.questionnaire.question_title");
      if (!this.front) {
        questionnaireManager.validQuestion(this.itemModel);
      }
      this.$emit("update");
    },

    settings() {
      this.$emit("settings", { item: this.item });
    },

    skills() {
      this.$emit("skills", { item: this.item });
    },

    rights() {
      this.$emit("rights", { item: this.item });
    },

    updateTip(value) {
      this.itemModel.tip = value;
      this.field.vars.help = this.itemModel.tip;
      this.$emit("update");
    },

    updateField(update = true) {
      this.field["vars"]["block_prefixes"] = this.block_prefixes;
      this.field["vars"]["attr"]["placeholder"] = this.placeholder;
      this.field["vars"]["label"] = this.itemModel.title
        ? this.itemModel.title
        : this.$t("studea_manager.questionnaire.question_title");
      this.field["vars"]["value"] = this.placeholder;

      if (this.isChoice) {
        if (!this.itemModel.valueList) {
          this.itemModel["valueList"] = [];
          this.itemModel.valueList.push({
            value: "",
            mark: "",
          });
        }

        switch (this.itemModel.type) {
          case 2:
            this.field["vars"]["multiple"] = false;
            this.field["vars"]["expanded"] = false;
            break;
          case 3:
            this.field["vars"]["multiple"] = false;
            this.field["vars"]["expanded"] = true;
            break;
          case 4:
            this.field["vars"]["multiple"] = true;
            this.field["vars"]["expanded"] = true;
            break;
        }
        this.choicesUpdated(false);
      }

      if (update) {
        this.$emit("update");
      }
    },

    toggleComment() {
      this.commenting = !this.commenting;
    },

    isLoading(loading) {
      this.$emit("isLoading", loading);
    },

    updateAnswer(value) {
      this.storeSetAnswer({
        id: this.itemModel.id,
        value: value,
      });
      this.$emit("update", this.itemModel);
    },

    updateComment(value) {
      this.storeSetAnswer({
        id: this.itemModel.id,
        comments: value,
      });
      this.$emit("update", this.itemModel);
    },

    isFieldSkillAvailable() {
      return (
        this.isChoice &&
        this.itemModel.displayMark &&
        this.itemModel.valueList[0].mark
      );
    },

    saveDraft(model) {
      this.$emit("draft", model);
    },

    choicesUpdated(update = true) {
      let choices = [];
      let i = 1;
      this.itemModel.valueList.forEach((value) => {
        choices.push({
          label: value.value,
          value: value.mark ? value.mark : i,
          data: value.mark ? value.mark : i,
        });
        i++;
      });
      this.field.vars.choices = choices;
      if (update) {
        this.$emit("update", this.itemModel);
      }
    },

    setQuestionCommentModel() {
      if (
        this.itemModel.enabled &&
        this.itemModel.comments &&
        this.model[this.itemModel.id]
      ) {
        this.questionCommentModel = {
          vars: {
            value: this.model[this.itemModel.id].vars["comments"],
            label: this.itemModel.commentsLabel,
            id: "comment-" + this.itemModel.id,
            name: "comment-" + this.itemModel.title,
            block_prefixes:
              this.itemModel.commentsType === 1
                ? ["", "text"]
                : ["", "text", "textarea"],
            attr: {
              placeholder: this.itemModel.commentsLabel,
            },
            disabled: this.disabled(),
          },
        };
      }
    },

    setLoading(loading) {
      this.$emit("isLoading", loading);
    },

    ...mapActions(useQuestionnaireStore, {
      storeSetAnswer: "setAnswer",
    }),
  },
};
</script>

<style lang="scss" scoped>
.widget {
  position: relative;
  padding-right: 10px;
  --profile-color: #000;

  &.selected {
    &:after {
      content: "";
      position: absolute;
      width: 5px;
      height: 100%;
      left: 0;
      top: 0;
      background: var(--primary-color);
      border-radius: 6px 0 0 6px;
    }

    .widget-content {
      padding-top: 0;
    }
  }

  .widget-content {
    padding-top: 20px;
  }
}

.widget:not(.selected, .front) {
  cursor: grab;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    background: var(--primary-color);
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    transition: all 150ms ease-in-out;
  }

  &:hover {
    &:before {
      opacity: 0.15;
    }
  }
}

.grip {
  position: absolute;
  right: 0;
  top: 0;
  color: $dark-grey;
  cursor: grab;
  height: 100%;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.question {
  &__grid {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include up-to-xl {
      flex-wrap: wrap;
    }
  }

  &__title {
    flex: 1;
    max-width: 30%;
    padding-right: 15px;
    width: 100%;

    @include up-to-xl {
      max-width: 50%;
    }

    @include up-to-md {
      max-width: 100%;
      flex: initial;
      padding-right: 0;
    }
  }

  &__type {
    flex: 1;
    max-width: 25%;
    width: 100%;
    padding-right: 15px;

    @include up-to-xl {
      max-width: 50%;
      padding-right: 0;
    }

    @include up-to-md {
      max-width: 100%;
      flex: initial;
      margin-top: 10px;
    }
  }

  &__settings {
    flex: 1;
    width: 552px;

    @include up-to-xl {
      width: 100%;
      flex: initial;
      margin-top: 10px;
    }
  }

  &__tools {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include up-to-md {
      flex-wrap: wrap;
    }

    button {
      @include up-to-md {
        order: 0;
      }
    }

    .required {
      @include up-to-md {
        order: 1;
        border: none;
        margin-left: 0;
        margin-top: 10px;
      }
    }

    @include dark-theme {
      color: $white;
    }
  }
}

.required {
  display: flex;
  align-items: center;
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid $grey;

  @include dark-theme {
    color: $white;
  }
}

.error {
  color: $red;
  font-size: 14px;
  font-weight: 700;
  margin-left: 20px;
  position: relative;
  top: -10px;
}
</style>
