<template>
  <div
    v-if="
      institution[0] &&
      institution[0].institution &&
      institution[0].trainingCourses[0]
    "
    class="institution"
  >
    <router-link
      class="list__link"
      :to="{
        name: 'studeaManagerHome',
        params: {
          trainingCourseId: institution[0].trainingCourses[0].id,
        },
      }"
      >{{ institution[0].institution.name }} - {{ institution[0].name }} :
      {{ institution[0].trainingCourses[0].startYear }}
      -
      {{ institution[0].trainingCourses[0].endYear }}
    </router-link>
  </div>
  <div
    v-else-if="institution[0] && institution[0].id"
    class="d-flex align-items-start"
  >
    <router-link
      :to="{
        name: 'studeaManagerHome',
        params: {
          trainingCourseId: institution[0].id,
        },
      }"
      class="list__link"
      >{{ institution[0].displayName }}
    </router-link>
  </div>
  <button
    v-if="
      institution.length > 1 ||
      (institution[0] &&
        institution[0].trainingCourses &&
        institution[0].trainingCourses.length > 1)
    "
    class="list__link"
    @click="initModel(row, column.index, column.name)"
  >
    {{ $t("global.see_more") }}
  </button>
  <span v-else-if="!institution.length">--</span>
</template>

<script>
export default {
  name: "SortTableInstitutionCellComponent",
  props: {
    row: {
      type: Array,
      required: true,
    },
    institution: {
      type: Array,
      required: true,
    },
    column: {
      type: [Object],
      required: true,
    },
  },
  emits: ["initModel"],
  methods: {
    initModel(row, columnIndex, columnName) {
      this.$emit("initModel", row, columnIndex, columnName);
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  &__link {
    background: none;
    border: none;
    padding: 0;
    font-size: 0.8rem;
    color: #515365;
    font-weight: 700;

    &.-red {
      color: $red;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
