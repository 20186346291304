<template>
  <div
    v-for="skill in category.skills"
    :key="skill.skillId"
    :style="
      '--max-width:' +
      (skill.skillBlocks && skill.skillBlocks.length
        ? 175 * skill.skillBlocks.length
        : 175) +
      'px'
    "
    class="dashboard__replies__questionnaires__item"
  >
    <div
      v-if="skill.skillBlocks && skill.skillBlocks.length"
      class="dashboard__replies__questionnaires__container"
    >
      <div
        v-for="item in skill.skillBlocks"
        :key="item.skillBlockId"
        class="dashboard__replies__questionnaires__border"
      >
        <div
          v-for="crew in data.crews"
          :key="crew.id"
          :class="'dashboard__replies__crew on crew-' + crew.id"
          :style="
            '--max-height:' +
            135 * (crew.triads.length ? crew.triads.length : 1) +
            'px'
          "
        >
          <div class="offset"></div>
          <div class="dashboard__replies__crew__triads">
            <div
              v-for="triad in crew.triads"
              :key="triad.id"
              class="dashboard__replies__triad"
            >
              <AccordionCellComponent
                :crew="crew"
                :questionnaire="skill"
                :triad="triad"
                :type="type"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        v-for="crew in crews"
        :key="crew.id"
        :class="'dashboard__replies__crew on crew-' + crew.id"
        :style="
          '--max-height:' +
          135 * (crew.triads.length ? crew.triads.length : 1) +
          'px'
        "
      >
        <div class="offset"></div>
        <div class="dashboard__replies__crew__triads">
          <div
            v-for="triad in crew.triads"
            :key="triad.id"
            class="dashboard__replies__triad"
          >
            <AccordionCellComponent
              :crew="crew"
              :questionnaire="skill"
              :triad="triad"
              :type="type"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccordionCellComponent from "@/components/table/accordion/AccordionCellComponent.vue";
import skill from "@/model/dashboard/skill";

export default {
  name: "AccordionSkillSubColumnComponent",
  components: { AccordionCellComponent },
  props: {
    type: {
      type: String,
      require: true,
    },
    category: {
      required: true,
    },
    crews: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      data: skill,
    };
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  &__replies {
    &__item {
      &.on {
        .dashboard__replies__questionnaires__item {
          width: var(--max-width);
          border-right: 2px solid #e0e6ed;

          &:last-child {
            border: none;
          }
        }

        .dashboard__replies__crew__triads {
          width: 130px;
        }
      }
    }

    &__questionnaires {
      &__item {
        text-align: center;
        transition: width 300ms ease-in-out;
        width: 0;
      }

      &__container {
        display: flex;
      }

      &__border {
        border-right: 1px solid #e0e6ed;

        &:last-child {
          border: none;
        }
      }
    }

    &__crew {
      min-width: 173px;
      padding: 60px 20px 20px 20px;
      border-bottom: 1px solid #e0e6ed;

      &.on {
        .dashboard__replies__crew__triads {
          height: var(--max-height);
        }
      }

      &__triads {
        height: 0;
        overflow: hidden;
        width: 0;
        transition: all 300ms ease-in-out;
      }

      &:last-child {
        border: none;
      }
    }

    &__triad {
      border-bottom: 1px dashed #e0e6ed;
      margin-top: 15px;
      height: 125px;
      overflow: hidden;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      &:first-child {
        margin-top: 10px;
      }
    }
  }
}
</style>
