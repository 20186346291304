<template>
  <div class="row flex-lg-nowrap">
    <div
      :class="
        'container-widget order-lg-0 order-1 ' +
        (isFullScreen && windowWidth > 991 ? 'full-screen' : 'col-lg-10')
      "
    >
      <div class="widget">
        <div class="widget-heading -list">
          <h1 class="textStyle">
            {{
              $tc("global.list.title", 1, {
                name: this.$tc("admin.training.title", 2).toLowerCase(),
              })
            }}
            {{
              isFiltered
                ? "(" +
                  (maxResult ? maxResult : Object.keys(datas).length) +
                  " résultat" +
                  ((maxResult ? maxResult : Object.keys(datas).length) > 1
                    ? "s"
                    : "") +
                  " filtré" +
                  ((maxResult ? maxResult : Object.keys(datas).length)
                    ? "s"
                    : "") +
                  ")"
                : ""
            }}
          </h1>
          <div>
            <button
              v-if="isFiltered"
              class="btn-icon me-1"
              v-tooltip="{ title: $t('init_search') }"
              type="button"
              @click="resetSearch"
            >
              <FontAwesomeIcon icon="fa-light fa-arrows-rotate" />
            </button>
            <button
              class="btn-icon"
              v-tooltip="{ title: $t('search_motor') }"
              type="button"
              @click="openSearch"
            >
              <FontAwesomeIcon icon="fa-light fa-magnifying-glass" />
            </button>
          </div>
        </div>
        <div class="widget-content">
          <SortTableComponent
            :actions="actions"
            :apiFilters="true"
            :columns="columns"
            :datas="datas"
            :idKey="5"
            :loading="loading"
            :maxResult="maxResult"
            :keyword="keyword"
            :isSearchAvailable="true"
            :defaultLimit="50"
            type="training"
            ref="sortTable"
            path="adminTrainingCourseEdit"
            @remove="remove"
            @duplicateTrainingCourse="duplicateTrainingCourse"
            @duplicateTrainingCourseOnNextYear="
              duplicateTrainingCourseOnNextYear
            "
            @removeTrainingCourse="removeTrainingCourse"
            @refresh="refresh"
            @openSearch="openSearch"
          />
        </div>
      </div>
    </div>
    <div
      :class="
        'col-lg-2 accordion order-lg-1 order-0 position-relative ' +
        (isFullScreen && windowWidth > 991 ? 'action__full-screen' : '')
      "
      id="accordionAction"
    >
      <button
        :class="
          'toggle_action ' + (isFullScreen && windowWidth > 991 ? '' : '-on')
        "
        @click="toggleAction"
        v-tooltip="{ title: $t('global.show_hide_actions') }"
      >
        <FontAwesomeIcon
          icon="fa-light fa-chevron-left"
          :class="'toggle_icon ' + (isFullScreen ? '' : 'rotate')"
        />
      </button>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingAction">
          <button
            :disabled="windowWidth > 991"
            :class="
              'accordion-button ' + (windowWidth < 991 ? 'collapsed' : '')
            "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseAction"
            aria-expanded="true"
            aria-controls="collapseAction"
          >
            {{ $t("actions") }}
          </button>
        </h2>
        <div
          id="collapseAction"
          :class="
            'accordion-collapse collapse ' + (windowWidth > 991 ? 'show' : '')
          "
          aria-labelledby="headingAction"
          data-bs-parent="#accordionAction"
        >
          <div class="accordion-body">
            <router-link
              :to="{
                name: 'adminTrainingsAdd',
                params: { alias: 'training' },
              }"
              v-access:[permissions]="accessManager().EDIT"
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-plus" />
              {{
                $tc("global.add.title", 2, {
                  name: this.$tc("admin.training.title", 1).toLowerCase(),
                })
              }}
            </router-link>
            <button
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
              type="button"
              @click="generateTrainingTest"
              v-access:[permissions]="accessManager().EDIT"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-plus" />
              {{ $t("admin.training.ufa_add_test") }}
            </button>
            <router-link
              :to="{
                name: 'adminTrainingsImport',
                params: { alias: 'training' },
              }"
              v-access:[permissions]="accessManager().EDIT"
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-download" />
              {{
                $tc("global.import.title", 2, {
                  name: this.$tc("admin.training.title", 2).toLowerCase(),
                })
              }}
            </router-link>
            <router-link
              v-access:[permissions]="accessManager().EDIT"
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
              type="button"
              :to="{
                name: 'adminTrainingImportTriad',
                params: { alias: 'triad' },
              }"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-download" />
              {{ $t("admin.training.import_triad") }}
            </router-link>
            <button
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
              type="button"
              v-access:[permissions]="accessManager().EDIT"
              @click="exportForm"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-upload" />
              {{
                $tc("global.export.title", 2, {
                  name: this.$tc("admin.training.title", 2).toLowerCase(),
                })
              }}
            </button>
            <button
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
              type="button"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-upload" />
              {{
                $tc("global.export.title", 2, {
                  name: this.$tc("admin.registration.title", 2).toLowerCase(),
                })
              }}
            </button>
            <button
              class="btn btn-primary mb-2 me-2 btnStyle w-100"
              type="button"
              @click="generateTriadTest"
              v-access:[permissions]="accessManager().EDIT"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-users" />
              <span>{{ $t("admin.training.genere_triad_test") }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalFormComponent
    :models="filterModels"
    :title="modalTitle"
    :storageKey="sessionStorageKey"
    :submitText="$t('global.export.title_alone')"
    @search="search"
    @submit="submit"
  />
</template>

<script>
import { mapState } from "pinia";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SortTableComponent from "@/components/table/sortTable/SortTableComponent.vue";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import { adminManager } from "@/services/admin/admin-manager";
import { sortableTableManager } from "@/services/utilities/sortable-table-manager";
import { formManager } from "@/services/form/form-manager";
import { notificationManager } from "@/services/utilities/notification-manager";
import { accessManager } from "@/services/security/access-manager";
import { useUserStore } from "@/store/user/user";
import { messengerMessageManager } from "@/services/messenger-message/messenger-message-manager";

export default {
  name: "TrainingsListView",
  components: {
    ModalFormComponent,
    SortTableComponent,
    FontAwesomeIcon,
  },
  data() {
    return {
      isFullScreen: true,
      loading: false,
      listParams: [],
      datas: [],
      isSearchOpen: false,
      isFiltered: false,
      sessionStorageKey: "",
      maxResult: 0,
      modalTitle: "",
      modalType: "",
      keyword: "",
      actions: [
        {
          translation: "admin.modify",
          translationParams: { name: "name", id: "id" },
          icon: "pen",
          type: "success",
          actionType: "router",
          action: "adminTrainingsEdit",
          cfaStyle: false,
          alias: "training",
          access: accessManager.EDIT,
        },
        {
          translation: "admin.delete",
          translationParams: { name: "name", id: "id" },
          icon: "trash",
          type: "danger",
          action: "remove",
          cfaStyle: false,
          access: accessManager.DELETE,
        },
      ],
      columns: [],
      filterModels: [],
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
    }),
  },
  watch: {
    windowWidth() {
      this.updateWindowWidth();
    },
  },
  mounted() {
    this.init();
    window.addEventListener("resize", this.updateWindowWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
  beforeRouteUpdate() {
    this.init();
    window.addEventListener("resize", this.updateWindowWidth);
  },
  methods: {
    accessManager() {
      return accessManager;
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },

    setKeyword() {
      if (this.$route.query.search) {
        this.keyword = this.$route.query.search;
      } else if (
        window.sessionStorage.getItem(this.sessionStorageKey + "-keywords")
      ) {
        this.keyword = window.sessionStorage.getItem(
          this.sessionStorageKey + "-keywords",
        );
      }
      this.listParams["keywords"] = this.keyword;
    },

    remove(row) {
      adminManager.remove("training", row.id, 2, false).then(() => {
        this.init();
      });
    },

    refresh(params) {
      this.listParams = params;
      window.sessionStorage.setItem(
        this.sessionStorageKey + "-keywords",
        this.listParams.keywords,
      );
      this.keyword = this.listParams.keywords;
      this.init();
    },

    removeTrainingCourse(payload) {
      adminManager.remove("training-course", payload.id, 2, false).then(() => {
        this.init();
      });
    },

    duplicateTrainingCourse(payload) {
      adminManager.duplicate("training-course", payload.id, 2).then(() => {
        this.init();
      });
    },

    duplicateTrainingCourseOnNextYear(payload) {
      adminManager
        .duplicate("training-course", payload.id, 2, { nextYear: 1 })
        .then(() => {
          this.init();
        });
    },

    search(params) {
      if (this.modalType === "search") {
        this.loading = true;
        this.$refs.sortTable.activePage = 1;
        this.listParams["offset"] = 0;
        this.listParams["search"] = params;
        this.getDatas();
        this.isFiltered = true;
        this.filterModels = [];
      }
    },

    submit() {
      if (this.modalType === "export") {
        const result = formManager.processForm(this.filterModels);
        adminManager.postExport("training", result).then((res) => {
          messengerMessageManager.processMessage(res);
        });
      }
    },

    init() {
      this.loading = true;
      this.setParams();
      this.getDatas(true);
    },

    setParams() {
      if (!this.listParams || !Object.keys(this.listParams).length) {
        this.listParams = {
          search: "",
          sortByField: 0,
          sortOrder: "ASC",
          limit: 50,
          offset: 0,
        };
      }
    },

    getDatas() {
      if (window.sessionStorage.getItem(this.sessionStorageKey)) {
        this.listParams["search"] = JSON.parse(
          window.sessionStorage.getItem(this.sessionStorageKey),
        );
        this.isFiltered = true;
      }
      if (this.isFiltered) {
        this.listParams["search"] = formManager.getQuery(
          this.listParams["search"],
        );
      }
      this.setKeyword();
      adminManager.list("training", false, this.listParams).then((data) => {
        this.columns = sortableTableManager.getEntriesColumns(
          data.columns,
          this.columns,
        );
        if (this.columns && this.columns[3]) {
          this.columns[3].sortable = false;
        }
        this.datas = data.data;
        this.maxResult = data.count;
        this.loading = false;
      });
    },

    getFilters() {
      this.filterModels = [];
      this.modalType = "search";
      this.sessionStorageKey = "studea-admin-trainings";
      this.modalTitle = this.$tc("global.search.title", 2, {
        name: this.$tc("admin.training.title", 2).toLowerCase(),
      });
      adminManager.form("training", null, {}, false, true).then((data) => {
        this.filterModels = data;
      });
    },

    openSearch() {
      this.getFilters();
      this.isSearchOpen = true;
    },

    resetSearch() {
      this.listParams = [];
      this.$refs.sortTable.activePage = 1;
      this.datas = [];
      this.columns = [];
      this.isFiltered = false;
      window.sessionStorage.removeItem(this.sessionStorageKey);
      window.sessionStorage.removeItem(this.sessionStorageKey + "-keywords");
      this.keyword = "";
      this.init();
    },

    generateTriadTest() {
      notificationManager
        .showAlert(
          "question",
          this.$t("admin.training.generate_triad_test_confirmation"),
          null,
          true,
          true,
        )
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;
            adminManager.generateTriadTest().then(() => {
              notificationManager.showNotification(
                "success",
                this.$t("admin.training.genere_triad_test_success"),
              );
              this.loading = false;
              this.init();
            });
          }
        });
    },

    generateTrainingTest() {
      this.loading = true;
      adminManager.generateTrainingTest().then(() => {
        notificationManager.showNotification(
          "success",
          this.$t("admin.training.genere_training_test_success"),
        );
        this.loading = false;
        this.init();
      });
    },

    exportForm() {
      this.modalType = "export";
      this.sessionStorageKey = "studea-admin-trainings-export";
      this.modalTitle = this.$tc("global.export.title", 2, {
        name: this.$tc("admin.training.title", 2).toLowerCase(),
      });
      adminManager.exportForm("training").then((models) => {
        this.filterModels = models;
      });
    },

    toggleAction() {
      this.isFullScreen = !this.isFullScreen;
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-heading {
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &.-list {
    cursor: initial;
  }

  svg {
    width: 18px;
    height: 18px;
  }

  &.collapsed {
    padding-bottom: 0;
  }
}

.widget {
  padding-bottom: 20px;
}

.widget-content {
  padding-bottom: 0;
}

.accordion-button {
  padding: 20px;
}

.container-widget {
  @include up-to-lg {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
  }
  padding-right: 38px;
  transition: width ease-in-out 300ms;
}

.full-screen {
  padding-right: 38px;
  width: 100%;
}

.action__full-screen {
  transition: all ease-in-out 300ms;
}

.accordion-item {
  position: relative;
  z-index: 2;
}

.toggle_icon {
  transform: rotate(0deg);
  transition: all ease-in-out 300ms;

  &.rotate {
    transform: rotate(180deg);
  }
}

.toggle_action {
  position: absolute;
  display: block;
  z-index: 0;
  top: 0;
  left: -30px;
  padding: 2px 20px 2px 10px;
  border: 1px solid #e0e6ed;
  background-color: $green;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  border-radius: 6px;
  color: $white;
  height: 67px;
  transition: background-color ease-in-out 300ms;

  &.-on {
    color: #212529;
    background-color: $white;
  }

  @include up-to-lg {
    display: none;
  }
}

.accordion {
  padding-left: 0;
}
</style>
