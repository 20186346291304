<template>
  <h1 class="">
    <span class="fw-normal fs-6">{{ $t("loginTo") }}</span
    ><br />
    <span class="brand-name textStyle">{{ name }}</span>
  </h1>
  <div v-if="message" class="badge badge-danger me-1 mt-1 w-100">
    {{ message }}
  </div>
  <form class="text-start">
    <div class="form">
      <div id="username-field" class="field-wrapper input">
        <svg
          class="feather feather-user svgStyle z-4"
          fill="none"
          height="24"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
          <circle cx="12" cy="7" r="4"></circle>
        </svg>
        <input
          v-model="login"
          :placeholder="$t('username')"
          class="form-control"
          type="text"
          @input="checkInput('login')"
        />
        <div v-if="loginError" class="invalid-feedback d-block">
          {{ loginError }}
        </div>
      </div>
      <div id="password-field" class="field-wrapper input mb-2">
        <svg
          class="feather feather-lock svgStyle"
          fill="none"
          height="24"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect height="11" rx="2" ry="2" width="18" x="3" y="11"></rect>
          <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
        </svg>
        <PasswordTypeComponent
          :password="password"
          @checkInput="checkInput('password')"
          @changePassword="changePassword"
        />
        <div v-if="passwordError" class="invalid-feedback d-block">
          {{ passwordError }}
        </div>
      </div>
      <div class="d-sm-flex justify-content-center">
        <div class="field-wrapper text-center">
          <button
            class="btn btn-primary blockStyle"
            type="submit"
            @click="loginCheck($event)"
          >
            {{ $t("login") }}
          </button>
        </div>
      </div>

      <div class="field-wrapper text-center keep-logged-in">
        <div class="checkbox-outline-primary custom-control custom-checkbox">
          <input
            id="chkRemember"
            v-model="stayConnected"
            class="custom-control-input"
            type="checkbox"
            value="true"
          />
          <label class="custom-control-label" for="chkRemember">{{
            $t("keepMeLoggedIn")
          }}</label>
        </div>
      </div>

      <div class="field-wrapper">
        <router-link
          class="forgot-pass-link"
          :to="{ name: 'loginResetPassword' }"
          >{{ $t("forgotPasswordLink") }}
        </router-link>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState, mapActions } from "pinia";
import "@/assets/sass/authentication/auth.scss";
import { loginManager } from "@/services/security/login-manager";
import { useUserStore } from "@/store/user/user";
import { usePlatformStore } from "@/store/platform/platform";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { useMainStore } from "@/store/main/main";
import PasswordTypeComponent from "@/components/form/PasswordTypeComponent.vue";

export default {
  name: "WebLoginView",
  components: { PasswordTypeComponent },
  data() {
    return {
      login: "",
      loginError: "",
      password: "",
      message: "",
      passwordError: "",
      stayConnected: false,
    };
  },
  computed: {
    ...mapState(usePlatformStore, {
      design: (store) => store.design,
      name: (store) => store.name,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourses: (store) => store.trainingCourseList,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
  },
  methods: {
    loginCheck(e) {
      e.preventDefault();
      if (this.login && this.password) {
        loginManager
          .login(this.login, this.password, this.stayConnected)
          .then((response) => {
            if (!response.success) {
              this.message = response.message;
            } else {
              this.setAppLoading(true);
              window.localStorage.removeItem("studea-oldTriad");
              window.localStorage.removeItem("studea-oldTrainingCourse");
              this.message = "";
              this.fetchUserInfos().then(() => {
                this.fetchTrainingCourseList({}).then(() => {
                  if (this.$route.query.referer) {
                    this.setAppLoading(false);
                    return this.$router.push(this.$route.query.referer);
                  }
                  if (
                    this.user &&
                    this.user.roles &&
                    this.user.roles.includes("ROLE_STUDEA_ADMIN")
                  ) {
                    this.setAppLoading(false);
                    window.localStorage.setItem(
                      "studea-returnPathName",
                      JSON.stringify({ name: "TrainingCourseList" }),
                    );
                    this.$router.push({ name: "adminHome" });
                  } else if (Object.keys(this.trainingCourses).length > 1) {
                    this.setAppLoading(false);
                    this.$router.push({ name: "TrainingCourseList" });
                  } else {
                    this.setAppLoading(false);
                    this.$router.push({ name: "Dashboard" });
                  }
                });
              });
            }
          });
      } else {
        if (!this.login) {
          this.loginError = this.$t("requiredField");
        } else {
          this.loginError = "";
        }

        if (!this.password) {
          this.passwordError = this.$t("requiredField");
        } else {
          this.passwordError = "";
        }
      }
    },

    checkInput(input) {
      if (!this.$data[`${input}`]) {
        this.$data[`${input}Error`] = this.$t("requiredField");
      } else {
        this.$data[`${input}Error`] = "";
      }
    },

    changePassword(password) {
      this.password = password;
    },

    ...mapActions(useMainStore, { setAppLoading: "setAppLoading" }),
    ...mapActions(useUserStore, { fetchUserInfos: "fetchUserInfos" }),
    ...mapActions(useTrainingCourseStore, {
      fetchTrainingCourseList: "fetchTrainingCourseList",
    }),
  },
};
</script>

<style lang="scss" scoped>
.handle__password {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 25px !important;
  left: unset !important;
}
</style>
