<template>
  <div v-if="type === 'questionnaire'">
    <AccordionTriadsRepliesComponent
      :crew="crew"
      :questionnaire="questionnaire"
      :triad="triad"
      @manage="manage"
    />
  </div>
  <div v-else-if="type === 'note'" class="note">
    <AccordionNoteReplyComponent
      :crew="crew"
      :questionnaire="questionnaire"
      :triad="triad"
    />
  </div>
  <div v-else-if="type === 'skill'">
    <AccordionSkillRepliesComponent
      :crew="crew"
      :questionnaire="questionnaire"
      :triad="triad"
    />
  </div>
</template>

<script>
import AccordionTriadsRepliesComponent from "@/components/table/accordion/AccordionTriadRepliesComponent.vue";
import AccordionNoteReplyComponent from "@/components/table/accordion/AccordionNoteReplyComponent.vue";
import AccordionSkillRepliesComponent from "@/components/table/accordion/AccordionTriadSkillRepliesComponent.vue";

export default {
  name: "AccordionCellComponent",
  components: {
    AccordionSkillRepliesComponent,
    AccordionTriadsRepliesComponent,
    AccordionNoteReplyComponent,
  },
  props: {
    type: {
      type: String,
      require: true,
    },
    crew: {
      type: Array,
      required: true,
    },
    questionnaire: {
      type: Array,
      required: true,
    },
    triad: {
      type: Array,
      required: true,
    },
  },
  methods: {
    manage(payload) {
      this.$emit("manage", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.note {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-bottom: 15px;
}
</style>
