<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="loader-block">
          <div class="panel-body pill-justify-centered tabs">
            <div class="widget">
              <div class="widget-content">
                <div class="list">
                  <div class="list__triad -heading">
                    <div
                      class="list__column textStyle -sortable"
                      v-if="
                        apprenticeProfileLabel && apprenticeProfileLabel.title
                      "
                    >
                      {{ $t("studea_manager.triad.list.name") }} :
                      {{ apprenticeProfileLabel.title }}
                    </div>
                    <div
                      class="list__column textStyle -sortable"
                      v-if="tutorProfileLabel && tutorProfileLabel.title"
                    >
                      {{ $t("studea_manager.triad.list.name") }} :
                      {{ tutorProfileLabel.title }}
                    </div>
                    <div
                      class="list__column textStyle"
                      v-if="
                        apprenticeMasterProfileLabel &&
                        apprenticeMasterProfileLabel.title
                      "
                    >
                      {{ $t("studea_manager.triad.list.name") }} :
                      {{ apprenticeMasterProfileLabel.title }}
                    </div>
                    <div
                      class="list__column textStyle"
                      v-if="
                        companyManagerProfileLabel &&
                        companyManagerProfileLabel.title
                      "
                    >
                      {{ $t("studea_manager.triad.list.name") }} :
                      {{ companyManagerProfileLabel.title }}
                    </div>
                    <div class="list__column -action"></div>
                  </div>
                  <div
                    v-for="triad in triads"
                    :key="triad.id"
                    :class="
                      'list__triad' + (triad.active ? '' : ' -disableBgColor')
                    "
                  >
                    <div class="list__column">
                      <div
                        class="d-flex align-items-start w-100"
                        v-if="triad.apprentice"
                      >
                        <div
                          class="badge -apprentice"
                          v-if="
                            apprenticeProfileLabel &&
                            apprenticeProfileLabel.titleAbbreviation
                          "
                        >
                          {{ apprenticeProfileLabel.titleAbbreviation }}
                        </div>
                        <button
                          class="list__button"
                          v-if="triad.apprentice"
                          @click="switchUser(triad.apprentice.id)"
                          v-tooltip="{
                            title:
                              $t('studea_manager.triad.usurp.title') +
                              ' ' +
                              triad.apprentice.fullName,
                          }"
                          v-access:[permissions]="{
                            access: accessManager().ALL,
                            code: studeaManagerManager()
                              .STUDEA_MANAGER_ALLOWED_TO_SWITCH,
                          }"
                        >
                          <FontAwesomeIcon
                            icon="fa-light fa-user"
                            class="me-1"
                          />
                        </button>
                        <div class="d-flex justify-content-between w-100">
                          <div class="name" v-if="triad.apprentice">
                            <router-link
                              v-if="
                                user &&
                                user.roles &&
                                user.roles.includes('ROLE_STUDEA_ADMIN')
                              "
                              v-tooltip="{
                                title:
                                  $t('admin.modify') +
                                  triad.apprentice.fullName,
                              }"
                              v-access:[permissions]="accessManager().EDIT"
                              :to="{
                                name: 'profile',
                                params: { id: triad.apprentice.cryptedId },
                              }"
                              class="userTriad"
                            >
                              {{ triad.apprentice.lastName.toUpperCase() }}
                              {{
                                fistLetterToUppercase(
                                  triad.apprentice.firstName,
                                )
                              }}
                            </router-link>
                            <span v-else>
                              {{ triad.apprentice.lastName.toUpperCase() }}
                              {{
                                fistLetterToUppercase(
                                  triad.apprentice.firstName,
                                )
                              }}
                            </span>
                          </div>
                          <div class="accordion-item">
                            <button
                              v-if="
                                triad.triadUsers &&
                                findUserType(1, triad).length
                              "
                              class="button_accordion collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              :data-bs-target="
                                '#panelsStayOpen-collapse-' +
                                triad.apprentice.id
                              "
                              aria-expanded="true"
                              :aria-controls="
                                'panelsStayOpen-collapse-' + triad.apprentice.id
                              "
                            >
                              <FontAwesomeIcon
                                class="ms-2"
                                icon="fa-light fa-chevron-down"
                              />
                            </button>
                          </div>
                          <div
                            v-if="triad.contractRuptureDate"
                            class="d-flex align-items-center"
                          >
                            <FontAwesomeIcon
                              v-tooltip="{
                                title:
                                  $t(
                                    'studea_manager.contract.contract_rupture_date',
                                  ) + format(triad.contractRuptureDate),
                              }"
                              class="text-danger"
                              icon="fa-light fa-link-slash"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="triad.apprentice"
                        :id="'panelsStayOpen-collapse-' + triad.apprentice.id"
                        class="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div class="accordion-body">
                          <div
                            class="d-flex align-items-center w-100"
                            v-for="user in findUserType(1, triad)"
                            :key="user.id"
                          >
                            <div
                              class="d-flex justify-content-between w-100"
                              v-if="user.user && user.user.fullName"
                            >
                              <span class="name">
                                {{ user.user.fullName }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="list__column">
                      <div
                        v-if="triad.tutor"
                        class="d-flex justify-content-between align-items-start"
                      >
                        <div class="d-flex align-items-start">
                          <div
                            class="badge -tutor"
                            v-if="
                              tutorProfileLabel &&
                              tutorProfileLabel.titleAbbreviation
                            "
                          >
                            {{ tutorProfileLabel.titleAbbreviation }}
                          </div>
                          <button
                            class="list__button"
                            @click="switchUser(triad.tutor.id)"
                            v-access:[permissions]="{
                              access: accessManager().ALL,
                              code: studeaManagerManager()
                                .STUDEA_MANAGER_ALLOWED_TO_SWITCH,
                            }"
                            v-tooltip="{
                              title:
                                $t('studea_manager.triad.usurp.title') +
                                ' ' +
                                triad.tutor.fullName,
                            }"
                          >
                            <FontAwesomeIcon
                              icon="fa-light fa-user"
                              class="me-1"
                            />
                          </button>
                          <div class="d-flex justify-content-between w-100">
                            <span class="name">
                              <router-link
                                v-if="
                                  user &&
                                  user.roles &&
                                  user.roles.includes('ROLE_STUDEA_ADMIN')
                                "
                                v-tooltip="{
                                  title:
                                    $t('admin.modify') + triad.tutor.fullName,
                                }"
                                :to="{
                                  name: 'profile',
                                  params: { id: triad.tutor.cryptedId },
                                }"
                                class="userTriad"
                              >
                                {{ triad.tutor.lastName.toUpperCase() }}
                                {{
                                  fistLetterToUppercase(triad.tutor.firstName)
                                }}
                              </router-link>
                              <span v-else
                                >{{ triad.tutor.lastName.toUpperCase() }}
                                {{
                                  fistLetterToUppercase(triad.tutor.firstName)
                                }}</span
                              >
                            </span>
                            <div class="accordion-item">
                              <button
                                v-if="
                                  triad.triadUsers &&
                                  findUserType(2, triad).length
                                "
                                class="button_accordion collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                :data-bs-target="
                                  '#panelsStayOpen-collapse-' + triad.tutor.id
                                "
                                aria-expanded="true"
                                :aria-controls="
                                  'panelsStayOpen-collapse-' + triad.tutor.id
                                "
                              >
                                <FontAwesomeIcon
                                  class="ms-2"
                                  icon="fa-light fa-chevron-down"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="triad.tutor"
                        :id="'panelsStayOpen-collapse-' + triad.tutor.id"
                        class="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div class="accordion-body">
                          <div
                            class="d-flex align-items-center w-100"
                            v-for="user in findUserType(2, triad)"
                            :key="user.id"
                          >
                            <button
                              class="list__button"
                              v-if="user.user && user.user.fullName"
                              @click="switchUser(user.user.id)"
                              v-access:[permissions]="{
                                access: accessManager().ALL,
                                code: studeaManagerManager()
                                  .STUDEA_MANAGER_ALLOWED_TO_SWITCH,
                              }"
                              v-tooltip="{
                                title:
                                  $t('studea_manager.triad.usurp.title') +
                                  ' ' +
                                  user.user.fullName,
                              }"
                            >
                              <FontAwesomeIcon
                                icon="fa-light fa-user"
                                class="me-1"
                              />
                            </button>
                            <div
                              class="d-flex justify-content-between w-100"
                              v-if="user.user && user.user.fullName"
                            >
                              <span class="name">
                                {{ user.user.fullName }}
                              </span>
                              <div class="d-flex">
                                <button
                                  class="list__secondary_button"
                                  v-tooltip="{
                                    title: $t(
                                      'studea_manager.triad.edit.tutor',
                                    ),
                                  }"
                                  @click="modifyTriadUser(user)"
                                  v-access:[permissions]="accessManager().EDIT"
                                >
                                  <FontAwesomeIcon icon="fa-light fa-edit" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="list__column">
                      <div
                        v-if="triad.apprenticeMaster"
                        class="d-flex justify-content-between align-items-start"
                      >
                        <div class="d-flex align-items-start">
                          <div
                            class="badge -apprenticeMaster"
                            v-if="
                              apprenticeMasterProfileLabel &&
                              apprenticeMasterProfileLabel.titleAbbreviation
                            "
                          >
                            {{ apprenticeMasterProfileLabel.titleAbbreviation }}
                          </div>
                          <div class="d-flex align-items-start">
                            <button
                              class="list__button"
                              v-tooltip="{
                                title:
                                  $t('studea_manager.triad.usurp.title') +
                                  ' ' +
                                  triad.apprenticeMaster.fullName,
                              }"
                              @click="switchUser(triad.apprenticeMaster.id)"
                              v-access:[permissions]="{
                                access: accessManager().ALL,
                                code: studeaManagerManager()
                                  .STUDEA_MANAGER_ALLOWED_TO_SWITCH,
                              }"
                            >
                              <FontAwesomeIcon
                                class="me-1"
                                icon="fa-light fa-user"
                              />
                            </button>
                            <span class="d-flex align-items-start">
                              <span class="name">
                                <router-link
                                  v-if="
                                    user &&
                                    user.roles &&
                                    user.roles.includes('ROLE_STUDEA_ADMIN')
                                  "
                                  v-tooltip="{
                                    title:
                                      $t('admin.modify') +
                                      triad.apprenticeMaster.fullName,
                                  }"
                                  :to="{
                                    name: 'profile',
                                    params: {
                                      id: triad.apprenticeMaster.cryptedId,
                                    },
                                  }"
                                  class="userTriad"
                                >
                                  {{
                                    triad.apprenticeMaster.lastName.toUpperCase()
                                  }}
                                  {{
                                    fistLetterToUppercase(
                                      triad.apprenticeMaster.firstName,
                                    )
                                  }}
                                </router-link>
                                <span v-else
                                  >{{
                                    triad.apprenticeMaster.lastName.toUpperCase()
                                  }}
                                  {{
                                    fistLetterToUppercase(
                                      triad.apprenticeMaster.firstName,
                                    )
                                  }}</span
                                >
                              </span>
                              <button
                                :aria-controls="'flush-collapse' + triad.id"
                                :data-bs-target="'#flush-collapse' + triad.id"
                                aria-expanded="false"
                                data-bs-toggle="collapse"
                                v-if="
                                  triad.activeContract &&
                                  triad.activeContract.apprenticeMaster2
                                "
                                class="button_accordion accordion collapsed"
                                style="
                                  border: none;
                                  background-color: transparent;
                                  color: var(--primary-color);
                                "
                              >
                                <FontAwesomeIcon
                                  class="ms-2"
                                  icon="fa-light fa-chevron-down"
                                />
                              </button>
                            </span>
                          </div>
                          <div
                            v-if="
                              triad.activeContract &&
                              triad.activeContract.apprenticeMaster &&
                              triad.apprenticeMaster.id &&
                              triad.apprenticeMaster.id !==
                                triad.activeContract.apprenticeMaster.id
                            "
                          >
                            <button
                              class="list__button"
                              v-tooltip="{
                                title:
                                  $t('studea_manager.triad.usurp.title') +
                                  ' ' +
                                  triad.activeContract.apprenticeMaster
                                    .fullName,
                              }"
                              @click="
                                switchUser(
                                  triad.activeContract.apprenticeMaster.id,
                                )
                              "
                              v-access:[permissions]="{
                                access: accessManager().ALL,
                                code: studeaManagerManager()
                                  .STUDEA_MANAGER_ALLOWED_TO_SWITCH,
                              }"
                            >
                              <FontAwesomeIcon
                                class="me-1"
                                icon="fa-light fa-user"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="
                          triad.activeContract &&
                          triad.activeContract.apprenticeMaster2
                        "
                        :id="'flush-collapse' + triad.id"
                        aria-labelledby="flush-headingOne"
                        class="accordion-collapse collapse -offset"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <button
                          v-tooltip="{
                            title:
                              $t('studea_manager.triad.usurp.title') +
                              ' ' +
                              triad.activeContract.apprenticeMaster2.fullName,
                          }"
                          class="list__button"
                          @click="
                            switchUser(
                              triad.activeContract.apprenticeMaster2.id,
                            )
                          "
                          v-access:[permissions]="{
                            access: accessManager().ALL,
                            code: studeaManagerManager()
                              .STUDEA_MANAGER_ALLOWED_TO_SWITCH,
                          }"
                        >
                          <FontAwesomeIcon
                            class="me-1"
                            icon="fa-light fa-user"
                          />
                        </button>
                        <router-link
                          v-if="
                            user &&
                            user.roles &&
                            user.roles.includes('ROLE_STUDEA_ADMIN')
                          "
                          v-tooltip="{
                            title:
                              $t('admin.modify') +
                              triad.activeContract.apprenticeMaster2.fullName,
                          }"
                          :to="{
                            name: 'profile',
                            params: {
                              id: triad.activeContract.apprenticeMaster2
                                .cryptedId,
                            },
                          }"
                          class="userTriad"
                        >
                          {{
                            triad.activeContract.apprenticeMaster2.lastName.toUpperCase()
                          }}
                          {{
                            fistLetterToUppercase(
                              triad.activeContract.apprenticeMaster2.firstName,
                            )
                          }}
                        </router-link>
                        <span v-else
                          >{{
                            triad.activeContract.apprenticeMaster2.lastName.toUpperCase()
                          }}
                          {{
                            fistLetterToUppercase(
                              triad.activeContract.apprenticeMaster2.firstName,
                            )
                          }}</span
                        >
                      </div>
                    </div>
                    <div class="list__column -manager">
                      {{
                        triad.activeContract && triad.activeContract.manager
                          ? triad.activeContract.manager.fullName
                          : "--"
                      }}
                    </div>
                    <div class="list__column -action">
                      <div class="list__action">
                        <router-link
                          :to="{
                            name: 'studeaManagerTriadEdit',
                            params: {
                              id: triad.id,
                              alias: 'triad',
                              trainingCourseId: triad.trainingCourse.id,
                            },
                          }"
                          class="list__button"
                          v-tooltip="{
                            title: $tc('global.edit.title', 1, {
                              name: this.$tc(
                                'studea_manager.triad.title',
                                1,
                              ).toLowerCase(),
                            }),
                          }"
                        >
                          <FontAwesomeIcon icon="fa-light fa-edit" />
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <BlockLoader :loading="loading" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { accessManager } from "@/services/security/access-manager";
import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/store/user/user";
import { useMainStore } from "@/store/main/main";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { userManager } from "@/services/security/user-manager";
import { dateFormatter } from "@/services/utilities/date-formatter";
import { usePlatformStore } from "@/store/platform/platform";

export default {
  name: "studeaManagerStatisticIncompleteTriadView",
  components: {
    FontAwesomeIcon,
    BlockLoader,
  },
  data() {
    return {
      triads: [],
      index: null,
      loading: false,
    };
  },
  computed: {
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
      user: (store) => store.user,
    }),
    ...mapState(usePlatformStore, {
      apprenticeProfileLabel: (store) => store.apprenticeProfileLabel,
      tutorProfileLabel: (store) => store.tutorProfileLabel,
      apprenticeMasterProfileLabel: (store) =>
        store.apprenticeMasterProfileLabel,
      companyManagerProfileLabel: (store) => store.companyManagerProfileLabel,
    }),
  },
  mounted() {
    this.init();
  },
  methods: {
    accessManager() {
      return accessManager;
    },
    init(loading = true) {
      this.loading = loading;
      studeaManagerManager
        .list("statistic", null, false, {}, this.$route.params.index)
        .then((res) => {
          this.triads = res;
          this.loading = false;
        });
    },
    ...mapActions(useMainStore, { setAppLoading: "setAppLoading" }),
    studeaManagerManager() {
      return studeaManagerManager;
    },
    fistLetterToUppercase(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    findUserType(userType, triad) {
      return userManager.findUserType(userType, triad);
    },

    format(date) {
      return dateFormatter.format(date, "short");
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;
}

.list {
  &__button {
    background: none;
    border: none;
    font-size: 16px;
    position: relative;
    color: var(--primary-color);
  }

  &__button + &__button {
    padding-left: 10px;
    margin-left: 5px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 12px;
      width: 1px;
      background: $white;
    }
  }

  &__document {
    padding: 6px 0;
    border-bottom: 1px dashed $light-grey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: all 300ms ease-in-out;

    &.-heading {
      font-weight: bold;
      color: var(--primary-color);

      @include up-to-md {
        display: none;
      }
    }

    &:hover {
      background: $lighter-grey;
    }

    &:last-child {
      border: none;
    }
  }

  &__column {
    width: 22%;
    padding-right: 10px;

    &.-sortable {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    &.-action {
      width: 15%;
    }
  }

  &__action {
    text-align: right;
    min-width: 50px;
  }
}

.uploader {
  color: $m-color_8;
  font-size: 12px;
}

.list {
  &__button {
    background: none;
    border: none;
    font-size: 16px;
    position: relative;
    color: var(--primary-color);
  }

  &__button + &__button {
    padding-left: 10px;
    margin-left: 5px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 12px;
      width: 1px;
      background: $white;
    }
  }

  &__triad {
    padding: 6px 0;
    border-bottom: 1px dashed $light-grey;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    animation: all 300ms ease-in-out;

    @include up-to-md {
      flex-direction: column;
    }

    &.-disableBgColor {
      background-color: $light-red;
    }

    &.-heading {
      font-weight: bold;
      color: var(--primary-color);

      @include up-to-md {
        display: none;
      }
    }

    &:hover {
      background: $lighter-grey;
    }

    &:last-child {
      border: none;
    }
  }

  &__column {
    width: 22%;
    padding-right: 10px;

    @include up-to-md {
      width: 100%;
      margin-bottom: 10px;
    }

    &.-sortable {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      cursor: pointer;
    }

    &.-action {
      width: 15%;

      @include up-to-md {
        width: 100%;
      }
    }
  }

  &__action {
    text-align: right;
    min-width: 50px;
  }

  .badge {
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    min-width: 23px;
    max-width: 23px;
    height: 23px;
    font-size: 10px;
    background: $orange;
    display: none;

    @include up-to-md {
      display: flex;
    }

    &.-apprentice {
      background: $green;
    }

    &.-tutor {
      background: $purple;
    }

    &.-apprenticeMaster {
      background: $red;
    }
  }

  .accordion {
    svg {
      transform: rotate(-180deg);
      transition: all 300ms ease-in-out;
    }

    &.collapsed {
      svg {
        transform: rotate(0deg);
      }
    }
  }

  .list__secondary_button {
    border: none;
    background-color: inherit;
    color: var(--primary-color);
    font-size: 16px;
  }
}

.-offset {
  @include up-to-md {
    margin-left: 23px;
  }
  margin-left: 0;
}

.-manager {
  @include up-to-md {
    display: none;
  }
}

.accordion {
  margin-bottom: 0 !important;
}

.name {
  margin-top: 3px;
}

.userTriad:hover {
  text-decoration: underline;
}
</style>
