<template>
  <li role="presentation">
    <hr
      v-if="separator"
      role="separator"
      aria-orientation="horizontal"
      size="0"
      class="dropdown-divider"
    />
  </li>
  <li role="presentation">
    <a
      :href="message.action ? apiPath + message.action : ''"
      class="dropdown-item"
      :class="{ disabled: !message.action }"
      v-tooltip="{
        title: $t('header.notification.download'),
        position: 'left',
      }"
    >
      <div class="media file-upload">
        <div class="media-aside align-self-center">
          <FontAwesomeIcon
            :icon="
              'fa-light fa-' +
              (message.fileTypeIcon ? message.fileTypeIcon : 'file')
            "
          />
        </div>
        <div class="media-body align-items-center">
          <div class="data-info">
            <h6 class="d-flex flex-column">
              {{
                message.label ? message.label : $t("header.notification.file")
              }}
              <span>
                <span class="labelStatus" v-if="message.labelStatus">{{
                  message.labelStatus
                }}</span>
                <span class="date" v-if="message.completedAt"
                  >({{ $t("on") }}
                  {{
                    dateFormatter().format(message.completedAt, "short_time")
                  }})
                </span>
              </span>
            </h6>
          </div>
          <div
            class="icon-status"
            :class="{
              '-success':
                message.status === messengerMessageManager().STATUS_SUCCESS,
              '-waiting':
                message.status === messengerMessageManager().STATUS_WAITING ||
                message.status === messengerMessageManager().STATUS_PROCESSING,
              '-error':
                message.status === messengerMessageManager().STATUS_FAILED ||
                message.status === messengerMessageManager().STATUS_ERROR,
              '-progress': message.totalIterations && message.currentIterations,
            }"
          >
            <font-awesome-icon :icon="['fal', message.icon]" />
            <NotificationProgressComponent
              v-if="message.totalIterations && message.currentIterations"
              :message="message"
            />
          </div>
        </div>
      </div>
    </a>
  </li>
</template>

<script>
import { messengerMessageManager } from "@/services/messenger-message/messenger-message-manager";
import { fileManager } from "@/services/file/file-manager";
import NotificationProgressComponent from "@/components/notifications/NotificationProgressComponent.vue";
import { dateFormatter } from "@/services/utilities/date-formatter";

export default {
  name: "NotificationComponent",
  components: { NotificationProgressComponent },
  props: {
    message: {
      type: Object,
      required: true,
    },
    separator: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      apiPath: fileManager.getApiPath()
        ? fileManager.getApiPath().slice(0, -4)
        : null,
    };
  },
  methods: {
    dateFormatter() {
      return dateFormatter;
    },
    messengerMessageManager() {
      return messengerMessageManager;
    },
  },
};
</script>

<style lang="scss" scoped>
h6 {
  color: var(--primary-color);

  &:hover {
    color: var(--primary-color) !important;
  }
}

.dropdown-item {
  &:hover {
    h6 {
      color: var(--primary-color) !important;
    }
  }
}

.icon-status {
  background: var(--icon-color);
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 26px;

  &.-success {
    --icon-color: #6ac7a4;
  }

  &.-error {
    --icon-color: #da5164;
  }

  &.-waiting {
    --icon-color: #00275e;
  }

  svg {
    color: $white !important;
    height: 16px !important;
    width: auto !important;
  }

  &.-progress {
    background: $white;

    svg {
      color: $blue !important;
    }
  }
}

.progressBar {
  inset: 0;
  border-radius: 50%;
  border: 2px solid $blue;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 28px;
  height: 28px;
}

div.dropdown-item {
  cursor: initial;

  &:hover {
    cursor: initial;
  }
}

.action {
  cursor: pointer;
  font-size: 14px;
}

.date {
  font-style: italic;
  font-size: 12px;
  color: $m-color_6;
  margin-left: 5px;
}

.labelStatus {
  font-style: italic;
  font-size: 12px;
  color: $m-color_6;
}
</style>
