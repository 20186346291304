import { i18n } from "@/i18n";
import GlobalLayoutView from "@/views/Global/GlobalLayoutView.vue";
import StatisticIncompleteTriadView from "@/views/StudeaManager/Statistic/StatisticIncompleteTriadView.vue";

export default {
  path: "/studea-manager/statistic",
  name: "studeaManagerStatistic",
  component: GlobalLayoutView,
  meta: {
    authRequired: true,
    title: i18n.global.t("studea_manager.document.shared_documents"),
    breadcrumb: true,
  },
  children: [
    {
      path: "list/:index",
      name: "studeaManagerStatisticIncompleteTriad",
      component: StatisticIncompleteTriadView,
      meta: {
        title: i18n.global.tc("global.list.title", 1, {
          name: i18n.global
            .tc("studea_manager.statistic.shared_documents", 2)
            .toLowerCase(),
        }),
        breadcrumb: true,
      },
    },
  ],
};
