<template>
  <div
    id="questionnaire-settings-modal"
    ref="modal"
    aria-hidden="true"
    aria-labelledby="questionnaire-settings-modal"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="exampleModalLabel" class="modal-title">{{ title }}</h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            data-dismiss="modal"
            type="button"
          ></button>
        </div>
        <div v-if="Object.keys(models).length" class="modal-body">
          <div class="row">
            <div v-if="models.access" class="form-group col-12">
              <CheckboxTypeComponent
                :key="key"
                :model="models.access"
                @update="updateAccess"
              />
            </div>
            <div v-if="models.openedQuestions" class="form-group col-12">
              <SwitchTypeComponent
                :key="key"
                :model="models.openedQuestions"
                @update="updateOpenedQuestion"
              />
            </div>
            <div
              v-if="modalItem.openedQuestions && models.openedQuestionsAccess"
              class="form-group col-lg-6"
            >
              <CheckboxTypeComponent
                :key="key"
                :model="models.openedQuestionsAccess"
                @update="updateOpenedQuestionsAccess"
              />
            </div>
            <div
              v-if="modalItem.openedQuestions && models.openedQuestionsType"
              class="form-group col-lg-6"
            >
              <RadioTypeComponent
                :key="key"
                :model="models.openedQuestionsType"
                @update="updateOpenedQuestionType"
              />
            </div>
            <div v-if="models.comments" class="form-group col-12">
              <SwitchTypeComponent
                :key="key"
                :model="models.comments"
                @update="updateComments"
              />
            </div>
            <div
              v-if="modalItem.comments && models.commentsLabel"
              class="form-group col-lg-6"
            >
              <InputTypeComponent
                :model="models.commentsLabel"
                @update="updateCommentsLabel"
              />
            </div>
            <div
              v-if="modalItem.comments && models.commentsType"
              class="form-group col-lg-6"
            >
              <RadioTypeComponent
                :key="key"
                :model="models.commentsType"
                @update="updateCommentsType"
              />
            </div>
            <div v-if="models.displayRadar" class="form-group col-lg-12">
              <SwitchTypeComponent
                :key="key"
                :model="models.displayRadar"
                @update="updateDisplayRadar"
              />
            </div>
            <div v-if="models.displayMark" class="form-group col-12">
              <SwitchTypeComponent
                :key="key"
                :model="models.displayMark"
                @update="updateDisplayMark"
              />
            </div>
            <div
              v-if="models.maximalMark && modalItem.displayMark"
              class="form-group col-12"
            >
              <InputTypeComponent
                :model="models.maximalMark"
                @update="updateMaximalMark"
              />
            </div>
            <div
              v-if="type === 'skills'"
              class="d-flex justify-content-between align-items-end"
            >
              <div v-if="models.searchSkill" class="form-group w-100 mb-0 me-2">
                <SelectTypeComponent
                  :model="models.searchSkill"
                  @update="updateSearchSkill"
                />
              </div>
              <button
                class="btn btn-success btnStyle"
                :disabled="skillAddDisabled"
                @click="addFieldSkill"
                v-tooltip="{
                  title: $t('studea_manager.questionnaire.add_skill'),
                }"
              >
                <FontAwesomeIcon icon="fa-light fa-plus" />
              </button>
            </div>
            <div v-if="models.fieldSkills" class="form-group w-100 mt-2 me-2">
              <label class="form-label">{{
                models.fieldSkills.vars.label
              }}</label>
              <div class="row pb-2">
                <div class="col-4 fw-bold textStyle">
                  {{ $t("studea_manager.questionnaire.skill_category_name") }}
                </div>
                <div class="col-3 fw-bold textStyle">
                  {{ $t("studea_manager.questionnaire.skill_name") }}
                </div>
                <div class="col-3 fw-bold textStyle">
                  {{ $t("studea_manager.questionnaire.coefficient") }}
                </div>
                <div class="col-2"></div>
              </div>
              <div
                class="fieldSkill row align-items-center"
                v-for="(fieldSkill, index) in models.fieldSkills.vars.value"
                :key="fieldSkill"
              >
                <div class="col-4">
                  {{ fieldSkill.skill.skillCategory.name }}
                </div>
                <div class="col-3">
                  {{ fieldSkill.skill.name }}
                </div>
                <div class="col-3">
                  <input
                    class="form-control"
                    type="number"
                    v-model="fieldSkill.coefficient"
                  />
                </div>
                <div class="col-2 text-end">
                  <button class="btn-icon btn" @click="removeFieldSkill(index)">
                    <FontAwesomeIcon icon="fa-light fa-trash" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-primary btnStyle"
            type="button"
            @click="submit"
          >
            {{ $t("studea_manager.questionnaire.confirm") }}
          </button>
          <button
            class="btn btn-danger"
            data-bs-dismiss="modal"
            data-dismiss="modal"
            type="button"
          >
            <i class="flaticon-cancel-12"></i
            >{{ $t("studea_manager.questionnaire.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import CheckboxTypeComponent from "@/components/form/CheckboxTypeComponent.vue";
import InputTypeComponent from "@/components/form/InputTypeComponent.vue";
import SwitchTypeComponent from "@/components/form/SwitchTypeComponent.vue";
import RadioTypeComponent from "@/components/form/RadioTypeComponent.vue";
import { mapActions, mapState } from "pinia";
import SelectTypeComponent from "@/components/form/SelectTypeComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { notificationManager } from "@/services/utilities/notification-manager";
import { questionnaireManager } from "@/services/questionnaire/questionnaire-manager";
import { useStudeaManagerQuestionnaireStore } from "@/store/studea_manager/studea-manager-questionnaire";
import { formValidator } from "@/services/form/form-validator";

export default {
  name: "QuestionnaireModalComponent",
  components: {
    FontAwesomeIcon,
    SelectTypeComponent,
    RadioTypeComponent,
    SwitchTypeComponent,
    InputTypeComponent,
    CheckboxTypeComponent,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    parent: {
      type: Object,
      required: false,
      default: () => null,
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      modalItem: [],
      parentItem: [],
      title: "",
      models: [],
      key: 0,
      skillAddDisabled: true,
      openingDatas: null,
      submitted: false,
    };
  },
  watch: {
    item() {
      this.key++;
      this.modalItem = this.item;
      this.parentItem = this.parent;
      if (Object.keys(this.modalItem).length) {
        const container = this.$refs.modal;
        container.addEventListener("click", this.stop);
        const modal = new Modal(container);
        this.title = this.getTitle;
        this.models = this.getModels;
        this.openingDatas = JSON.stringify(this.questionnaire);
        modal.show();
        this.$emit("opening");
        this.skillAddDisabled = true;
      }
    },
  },
  mounted() {
    this.$refs.modal.addEventListener("hidden.bs.modal", this.hidden);
  },
  computed: {
    getTitle() {
      return this.modalItem.itemType === "cluster"
        ? this.$t("studea_manager.questionnaire.cluster_settings")
        : this.$t("studea_manager.questionnaire.question_settings");
    },

    isChoice() {
      return [2, 3, 4].includes(this.modalItem.type);
    },

    getModels() {
      let model = [];
      let availableProfilesAccess = [];
      let availableProfilesOpenedQuestionAccess = [];
      Object.values(this.questionnaire.signedBy).forEach((profile) => {
        if (profile.checked) {
          const clone = Object.assign({}, profile);
          clone.checked =
            this.modalItem.access &&
            (this.modalItem.access.includes(clone.data) ||
              (this.modalItem.access &&
                Object.values(this.modalItem.access).find(
                  (item) => item.data === profile.data,
                )));
          availableProfilesAccess.push(clone);
        }

        if (profile.checked) {
          const clone = Object.assign({}, profile);
          clone.checked =
            this.modalItem.openedQuestions &&
            this.modalItem.openedQuestionsAccess &&
            this.modalItem.openedQuestionsAccess.includes(clone.data);
          availableProfilesOpenedQuestionAccess.push(clone);
        }
      });

      if (this.type === "rights") {
        this.updateAccess(availableProfilesAccess);
        model["access"] = {
          vars: {
            value: this.modalItem.access,
            label: this.$t("studea_manager.questionnaire.access_profil"),
            name: "access",
            id: "access",
            block_prefixes: ["", "choice"],
            required: true,
            multiple: true,
            expanded: true,
            choices: availableProfilesAccess,
          },
        };
      }
      if (this.type === "skills") {
        model["fieldSkills"] = {
          vars: {
            value: this.modalItem.fieldSkills ? this.modalItem.fieldSkills : [],
            label: this.$t("studea_manager.questionnaire.field_skill"),
            name: "fieldSkill",
            id: "fieldSkill",
            block_prefixes: ["", "collection"],
            required: false,
          },
        };
        model["searchSkill"] = {
          vars: {
            value: "",
            label: this.$t("studea_manager.questionnaire.search_skill"),
            name: "searchSkill",
            id: "searchSkill",
            block_prefixes: ["", "choice", "entity"],
            expanded: false,
            multiple: false,
            attr: {
              "data-ajax-url":
                "/app/studea-manager/" +
                this.$route.params.trainingCourseId +
                "/load/skills",
            },
            required: false,
          },
        };
      }

      if (this.modalItem.itemType === "cluster") {
        this.updateOpenedQuestionsAccess(availableProfilesOpenedQuestionAccess);
        model["openedQuestionsAccess"] = {
          vars: {
            value: this.modalItem.openedQuestionsAccess,
            label: this.$t(
              "studea_manager.questionnaire.opened_question_access",
            ),
            name: "openedQuestionsAccess",
            id: "openedQuestionsAccess",
            block_prefixes: ["", "choice"],
            required: false,
            multiple: true,
            expanded: true,
            choices: availableProfilesOpenedQuestionAccess,
          },
        };
        model["openedQuestions"] = {
          vars: {
            value: this.modalItem.openedQuestions,
            label: this.$t("studea_manager.questionnaire.opened_questions"),
            name: "openedQuestions",
            id: "openedQuestions",
            block_prefixes: ["", "choice"],
            required: false,
          },
        };
        model["openedQuestionsType"] = {
          vars: {
            value: this.modalItem.openedQuestionsType
              ? this.modalItem.openedQuestionsType
              : 1,
            label: this.$t(
              "studea_manager.questionnaire.opened_questions_type",
            ),
            name: "openedQuestionsType",
            id: "openedQuestionsType",
            expanded: true,
            block_prefixes: ["", "choice"],
            required: false,
            choices: [
              {
                label: this.$t("studea_manager.questionnaire.one_line"),
                value: 1,
                data: 1,
              },
              {
                label: this.$t("studea_manager.questionnaire.multi_line"),
                value: 2,
                data: 2,
              },
            ],
          },
        };
      }

      if (
        (this.isChoice && this.modalItem.itemType === "field" && !this.type) ||
        this.modalItem.itemType === "cluster"
      ) {
        model["comments"] = {
          vars: {
            value: this.modalItem.comments,
            label: this.$t("studea_manager.questionnaire.comments_enabled"),
            name: "comments",
            id: "comments",
            block_prefixes: ["", "choice"],
            required: false,
          },
        };
        model["commentsLabel"] = {
          vars: {
            value:
              this.modalItem && this.modalItem.commentsLabel
                ? this.modalItem.commentsLabel
                : "",
            label: this.$t("studea_manager.questionnaire.comments_label"),
            name: "commentsLabel",
            id: "commentsLabel",
            block_prefixes: ["", "text"],
            required: false,
          },
        };
        model["commentsType"] = {
          vars: {
            value: this.modalItem.commentsType
              ? this.modalItem.commentsType
              : 1,
            label: this.$t("studea_manager.questionnaire.comments_type"),
            name: "commentsType",
            id: "commentsType",
            block_prefixes: ["", "choice"],
            required: false,
            choices: [
              {
                label: this.$t("studea_manager.questionnaire.one_line"),
                value: "1",
                data: 1,
              },
              {
                label: this.$t("studea_manager.questionnaire.multi_line"),
                value: "2",
                data: 2,
              },
            ],
          },
        };
      }

      if (this.isChoice && this.modalItem.itemType && !this.type) {
        model["displayMark"] = {
          vars: {
            value:
              this.modalItem && this.modalItem.displayMark
                ? this.modalItem.displayMark
                : false,
            label: this.$t("studea_manager.questionnaire.display_mark"),
            name: "displayMark",
            id: "displayMark",
            block_prefixes: ["", "choice"],
            required: false,
          },
        };
        model["maximalMark"] = {
          vars: {
            value:
              this.modalItem && this.modalItem.maximalMark
                ? this.modalItem.maximalMark
                : "",
            label: this.$t("studea_manager.questionnaire.maximal_mark"),
            name: "maximalMark",
            id: "maximalMark",
            block_prefixes: ["", "number"],
            required: false,
          },
        };
        if (this.modalItem.itemType === "cluster") {
          model["displayRadar"] = {
            vars: {
              value: this.modalItem.displayRadar,
              label: this.$t("studea_manager.questionnaire.display_radar"),
              name: "displayRadar",
              id: "displayRadar",
              block_prefixes: ["", "choice"],
              required: false,
            },
          };
        }
      }
      return model;
    },

    ...mapState(useStudeaManagerQuestionnaireStore, {
      questionnaire: (store) => store.questionnaire,
    }),
  },
  methods: {
    save() {
      this.$emit("update");
    },

    hidden() {
      if (!this.submitted) {
        this.questionnaireUpdate({
          questionnaire: JSON.parse(this.openingDatas),
          save: !this.$route.params.id,
        });
      }
      this.submitted = false;
      this.models = [];
      this.modalItem = [];
      this.$emit("hidden");
      const container = this.$refs.modal;
      container.removeEventListener("click", this.stop);
    },

    submit() {
      const errors = formValidator.validForm(this.models);
      if (!errors.length) {
        this.submitted = true;
        if (this.$refs.modal) {
          const modal = Modal.getOrCreateInstance(this.$refs.modal);
          modal.hide();
        }
      }
    },

    updateAccess(value) {
      const checked = [];
      Object.values(value).forEach(function (choice) {
        if (choice.checked) {
          checked.push(choice.data);
        }
      });
      this.modalItem.access = checked;
      this.$emit("update");
      if (!this.front) {
        questionnaireManager.validQuestion(
          this.parentItem ? this.parentItem : this.modalItem,
        );
      }
    },

    updateOpenedQuestionsAccess(value) {
      const checked = [];
      Object.values(value).forEach(function (choice) {
        if (choice.checked) {
          checked.push(choice.data);
        }
      });

      this.modalItem.openedQuestionsAccess = checked;
      this.$emit("update");
    },

    stop(e) {
      e.stopPropagation();
    },

    updateComments(value) {
      this.modalItem.comments = value;
      this.key++;
      this.$emit("update");
    },

    updateMaximalMark(value) {
      this.modalItem.maximalMark = value;
      this.key++;
      this.$emit("update");
    },

    updateSearchSkill(value) {
      this.skillAddDisabled = !value;
    },

    addFieldSkill() {
      if (this.models.searchSkill.vars.value) {
        const exist = this.models.fieldSkills.vars.value.find(
          (item) => item.skill.id === this.models.searchSkill.vars.value.value,
        );

        if (exist) {
          notificationManager.showNotification(
            "error",
            this.$t("studea_manager.questionnaire.field_skill_used"),
          );
        } else {
          this.models.fieldSkills.vars.value.push({
            id: null,
            coefficient: 1,
            skill: {
              id: this.models.searchSkill.vars.value.value,
              name: this.models.searchSkill.vars.value.label,
              skillCategory: {
                name: this.models.searchSkill.vars.value.skillCategory,
              },
            },
            field: {
              id: this.modalItem.id,
            },
          });
          this.models.searchSkill.vars.value = null;
        }
      }
    },

    removeFieldSkill(index) {
      notificationManager
        .showAlert(
          "error",
          this.$t("studea_manager.questionnaire.remove_field_skill"),
          this.$t("studea_manager.questionnaire.remove_field_skill_sub"),
          true,
          true,
        )
        .then((res) => {
          if (res && res.isConfirmed) {
            this.models.fieldSkills.vars.value.splice(index, 1);
          }
        });
    },

    updateDisplayMark(value) {
      if (!value)
        Object.values(this.modalItem.valueList).forEach((choice) => {
          choice.value = "";
        });
      this.modalItem.displayMark = value;
      this.key++;
      this.$emit("update");
    },

    updateDisplayRadar(value) {
      this.modalItem.displayRadar = value;
      this.key++;
      this.$emit("update");
    },

    updateCommentsLabel(value) {
      this.modalItem.commentsLabel = value;
      this.key++;
      this.$emit("update");
    },

    updateCommentsType(value) {
      this.modalItem.commentsType = parseInt(value);
      this.key++;
      this.$emit("update");
    },

    updateOpenedQuestion(value) {
      this.modalItem.openedQuestions = value;
      this.key++;
      if (!value) {
        this.modalItem.openedQuestionsType = 1;
      }
      this.$emit("update");
    },

    updateOpenedQuestionType(value) {
      this.key++;
      this.modalItem.openedQuestionsType = parseInt(value);
      this.$emit("update");
    },

    ...mapActions(useStudeaManagerQuestionnaireStore, {
      questionnaireUpdate: "questionnaireUpdate",
    }),
  },
};
</script>

<style lang="scss" scoped>
.fieldSkill {
  border-top: 1px dashed $m-color_6;
  padding: 8px 0;
}
</style>
