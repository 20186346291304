<template>
  <label
    v-if="itemModel.vars.label"
    :class="
      'form-label' +
      (itemModel.vars.label_attr && itemModel.vars.label_attr.class
        ? ' ' + itemModel.vars.label_attr.class
        : '') +
      (itemModel.vars.required ? ' required' : '')
    "
  >
    {{ index ? index + ". " : "" }}{{ itemModel.vars.label
    }}{{ itemModel.vars.required ? "*" : "" }}
    <span
      v-if="itemModel.vars.help && !isMobile()"
      v-tooltip="{ title: itemModel.vars.help }"
    >
      <FontAwesomeIcon icon="fa-light fa-circle-question" />
    </span>
  </label>
  <div v-if="isMobile() && itemModel.vars.help" class="form-info">
    <FontAwesomeIcon icon="fa-light fa-circle-question" />
    {{ itemModel.vars.help }}
  </div>
  <FlatPickr
    :id="itemModel.vars.id"
    v-model="itemModel.vars.value"
    v-if="!itemModel.vars.hide"
    :class="
      (itemModel.vars.error
        ? 'form-control flatpickr active is-invalid'
        : 'form-control flatpickr active') +
      (itemModel.vars.attr && itemModel.vars.attr.class
        ? ' ' + itemModel.vars.attr.class
        : '')
    "
    :config="config()"
    :disabled="itemModel.vars.disabled === true"
    :name="itemModel.vars.name"
    altFormat="d/m/Y"
    @onChange="change"
    @onClose="close"
    @onOpen="open"
  ></FlatPickr>
  <div v-if="itemModel.vars.error" class="invalid-feedback d-block">
    {{ itemModel.vars.error }}
  </div>
</template>

<script>
import FlatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import "@/assets/sass/forms/custom-flatpickr.css";
import { formValidator } from "@/services/form/form-validator";
import { mapState } from "pinia";
import { French } from "flatpickr/dist/l10n/fr";
import { Spanish } from "flatpickr/dist/l10n/es";
import { useThemeStore } from "@/store/theme/theme";

export default {
  name: "DateTypeComponent",
  data() {
    return {
      itemModel: this.model,
    };
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: false,
      default: null,
    },
  },
  watch: {
    "itemModel.vars.error"() {
      const instance = document.getElementById(this.itemModel.vars.id);
      this.getClass(instance.nextSibling);
    },
  },
  emits: ["draft", "focus", "update", "dateChanged"],
  computed: {
    dateFormat() {
      let format = "";
      if (
        this.itemModel.vars.block_prefixes[1] === "date" ||
        this.itemModel.vars.block_prefixes[1] === "datetime"
      ) {
        format = format.concat("Y-m-d");

        if (
          this.itemModel.vars.block_prefixes[2] === "datetime" ||
          this.itemModel.vars.block_prefixes[1] === "datetime"
        ) {
          format = format.concat(" H:i:00");
        }
      }
      if (this.itemModel.vars.block_prefixes[1] === "time") {
        format = "H:i:00";
      }
      return format;
    },

    altFormat() {
      let format = "";
      if (
        this.itemModel.vars.block_prefixes[1] === "date" ||
        this.itemModel.vars.block_prefixes[1] === "datetime"
      ) {
        format = format.concat("d/m/Y");

        if (
          this.itemModel.vars.block_prefixes[2] === "datetime" ||
          this.itemModel.vars.block_prefixes[1] === "datetime"
        ) {
          format = format.concat(" H:i");
        }
      }
      if (this.itemModel.vars.block_prefixes[1] === "time") {
        format = "H:i";
      }

      return format;
    },

    minuteIncrement() {
      return this.itemModel.vars.attr &&
        this.itemModel.vars.attr["data-minute-increment"]
        ? this.itemModel.vars.attr["data-minute-increment"]
        : 1;
    },

    ...mapState(useThemeStore, {
      locale: (store) => store.locale,
    }),
  },
  mounted() {
    let value = "";
    if (typeof this.itemModel.vars.value === "object") {
      if (
        (this.itemModel.vars.value &&
          this.itemModel.vars.value.year &&
          this.itemModel.vars.value.month &&
          this.itemModel.vars.value.day) ||
        (this.itemModel.vars.value &&
          this.itemModel.vars.value.date &&
          this.itemModel.vars.value.date.year &&
          this.itemModel.vars.value.date.month &&
          this.itemModel.vars.value.date.day)
      ) {
        value =
          (this.itemModel.vars.value.date
            ? this.itemModel.vars.value.date.year
            : this.itemModel.vars.value.year
          ).padStart(4, "0") +
          "-" +
          (this.itemModel.vars.value.date
            ? this.itemModel.vars.value.date.month
            : this.itemModel.vars.value.month
          ).padStart(2, "0") +
          "-" +
          (this.itemModel.vars.value.date
            ? this.itemModel.vars.value.date.day
            : this.itemModel.vars.value.day
          ).padStart(2, "0");

        if (
          (this.itemModel.vars.value &&
            this.itemModel.vars.value.hour &&
            this.itemModel.vars.value.minute) ||
          (this.itemModel.vars.value &&
            this.itemModel.vars.value.time &&
            this.itemModel.vars.value.time.hour &&
            this.itemModel.vars.value.time.minute)
        ) {
          value = value.concat(" ");
        }
      }
      if (
        (this.itemModel.vars.value &&
          this.itemModel.vars.value.hour &&
          this.itemModel.vars.value.minute) ||
        (this.itemModel.vars.value &&
          this.itemModel.vars.value.time &&
          this.itemModel.vars.value.time.hour &&
          this.itemModel.vars.value.time.minute)
      ) {
        value = value.concat(
          (this.itemModel.vars.value.time
            ? this.itemModel.vars.value.time.hour
            : this.itemModel.vars.value.hour
          ).padStart(2, "0") +
            " " +
            (this.itemModel.vars.value.time
              ? this.itemModel.vars.value.time.minute
              : this.itemModel.vars.value.minute
            ).padStart(2, "0"),
        );
      }
      this.itemModel.vars.value = value;
    }
  },
  methods: {
    getClass(instance) {
      if (this.itemModel.vars.required && !this.itemModel.vars.value) {
        instance.classList.add("is-invalid");
        instance.classList.remove("is-valid");
      } else if (this.itemModel.vars.required && this.itemModel.vars.value) {
        instance.classList.add("is-valid");
        instance.classList.remove("is-invalid");
      }
    },
    close(selectedDates, dateStr, instance) {
      this.setDate(selectedDates, dateStr, instance);
      this.$emit("draft", this.itemModel);
    },
    change(selectedDates, dateStr, instance) {
      this.setDate(selectedDates, dateStr, instance, false);
    },

    minDate() {
      return this.itemModel.vars.attr &&
        this.itemModel.vars.attr["data-min-date"]
        ? this.itemModel.vars.attr["data-min-date"]
        : null;
    },

    maxDate() {
      return this.itemModel.vars.attr &&
        this.itemModel.vars.attr["data-max-date"]
        ? this.itemModel.vars.attr["data-max-date"]
        : null;
    },

    setDate(selectedDates, dateStr, instance, valid = true) {
      this.itemModel.vars.value = dateStr;
      this.$emit("update", this.itemModel.vars.value);

      if (
        this.itemModel.vars.attr &&
        (this.itemModel.vars.attr["data-day"] ||
          this.itemModel.vars.attr["data-hour"])
      ) {
        this.$emit("dateChanged", this.itemModel);
      }

      if (valid) {
        this.getClass(instance.altInput);
      }
      if (this.itemModel.vars.required && valid) {
        formValidator.validModel(this.itemModel);
      }
    },
    isMobile() {
      return window.innerWidth < 992;
    },

    open() {
      this.$emit("focus");
    },

    getLanguage() {
      if (this.locale === "fr") {
        return French;
      } else if (this.locale === "es") {
        return Spanish;
      }
    },

    config() {
      return {
        noCalendar: this.itemModel.vars.block_prefixes[1] === "time",
        enableTime:
          this.itemModel.vars.block_prefixes[2] === "datetime" ||
          this.itemModel.vars.block_prefixes[1] === "datetime" ||
          this.itemModel.vars.block_prefixes[1] === "time",
        dateFormat: this.dateFormat,
        time_24hr: true,
        altInput: true,
        altFormat: this.altFormat,
        allowInput: true,
        locale: this.getLanguage(),
        minuteIncrement: this.minuteIncrement,
        defaultHour: 8,
        minDate: this.minDate(),
        maxDate: this.maxDate(),
        static: true,
      };
    },
  },
  components: {
    FlatPickr,
  },
};
</script>

<style lang="scss" scoped>
.non-empty {
  overflow: auto;
}
</style>
