<template>
  <div
    ref="messengerMessageModal"
    aria-hidden="true"
    aria-labelledby="messengerMessageModal"
    class="modal show fade"
    role="dialog"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content position-relative">
        <div class="modal-header">
          <h5 class="modal-title textStyle">
            {{ $t("header.notification.list") }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            data-dismiss="modal"
            type="button"
          ></button>
        </div>
        <div class="modal-body">
          <ul class="message-list">
            <NotificationComponent
              :message="message"
              v-for="(message, index) in list"
              :key="message.id"
              :separator="!!index"
            />
            <li role="presentation" v-if="!list || !Object.keys(list).length">
              <div class="dropdown-item">
                <div class="media file-upload">
                  <div class="media-body">
                    <div class="data-info">
                      <h6>{{ $t("header.notification.empty") }}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <BlockLoader :loading="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import { mapActions, mapState } from "pinia";
import { useNotificationStore } from "@/store/notifications/notification";
import NotificationComponent from "@/components/notifications/NotificationComponent.vue";
import { messengerMessageManager } from "@/services/messenger-message/messenger-message-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";

export default {
  name: "NotificationListModalComponent",
  components: { BlockLoader, NotificationComponent },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(useNotificationStore, {
      listOpened: (store) => store.listOpened,
      list: (store) => store.list,
    }),
  },
  watch: {
    listOpened() {
      const container = this.$refs.messengerMessageModal;
      const modal = new Modal(container);
      this.refreshList();
      if (this.listOpened) {
        modal.show();
      } else {
        modal.hide();
        this.setListOpened(false);
      }
    },
  },
  mounted() {
    this.$refs.messengerMessageModal.addEventListener(
      "hidden.bs.modal",
      this.hidden,
    );
  },
  methods: {
    hidden() {
      this.$emit("hidden");
      const container = this.$refs.messengerMessageModal;
      container.removeEventListener("click", this.stop);
      this.setListOpened(false);
    },

    stop(e) {
      e.stopPropagation();
    },

    refreshList() {
      this.loading = true;
      messengerMessageManager.get().then(() => {
        this.loading = false;
      });
    },

    ...mapActions(useNotificationStore, { setListOpened: "setListOpened" }),
  },
};
</script>

<style lang="scss" scoped></style>
