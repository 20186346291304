<template>
  <TabsTitleComponent :items="tabs" />
  <div class="row">
    <div class="col-lg-9 order-1 order-lg-0">
      <div class="widget p-4">
        <BlockLoader :loading="loading" />
        <SortTableComponent
          v-if="referentials && Object.values(referentials)"
          :apiFilters="true"
          :columns="columns"
          :datas="referentials"
          :idKey="3"
          :loading="loading"
          :actions="actions"
          :isSearchAvailable="true"
          :defaultLimit="10"
          :searchInput="true"
          type="skillReferential"
          ref="sortTable"
          @refresh="refresh"
          @remove="deleteReferential"
        />
      </div>
    </div>
    <div class="col-lg-3 order-0 order-lg-1">
      <div class="sticky">
        <div id="action-blocks">
          <ActionsBlock
            :actions="referentialActions"
            :mobileCollapsable="true"
            :collapsable="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { mapActions, mapState } from "pinia";
import { useTriadStore } from "@/store/triad/triad";
import { accessManager } from "@/services/security/access-manager";
import TabsTitleComponent from "@/components/blocks/TabsTitleComponent.vue";
import SortTableComponent from "@/components/table/sortTable/SortTableComponent.vue";
import { formManager } from "@/services/form/form-manager";
import { sortableTableManager } from "@/services/utilities/sortable-table-manager";
import { adminManager } from "@/services/admin/admin-manager";
import { notificationManager } from "@/services/utilities/notification-manager";
import { apiConnection } from "@/services/api-connection";

export default {
  name: "SkillReferentialListView",
  components: {
    SortTableComponent,
    TabsTitleComponent,
    BlockLoader,
    ActionsBlock,
  },
  data() {
    return {
      tabs: [
        {
          id: 0,
          title: this.$t("studea_manager.skill_configuration.title"),
          icon: "gear",
          disabled: false,
          path: "adminModuleSkillConfiguration",
        },
        {
          id: 1,
          title: this.$t("studea_manager.skill_referential.title"),
          icon: "book",
          disabled: false,
          path: "adminModuleSkillReferential",
        },
        {
          id: 2,
          title: this.$t("admin.skill_session.generate"),
          icon: "medal",
          disabled: false,
          path: "adminModuleSkillGenerateSession",
        },
      ],
      referentialActions: [
        {
          translation: "global.add.title",
          translationGender: 1,
          translationParams: {
            name: this.$tc(
              "studea_manager.skillReferential.title",
              1,
            ).toLowerCase(),
          },
          icon: "plus",
          type: "success",
          actionType: "router",
          action: "adminSkillReferentialAdd",
          cfaStyle: false,
          params: { alias: "skill-referential" },
          access: accessManager.EDIT,
        },
      ],
      referentials: [],
      columns: [],
      showModal: false,
      models: [],
      list: {},
      categories: [],
      years: {},
      skills: [],
      loading: false,
      listParams: [],
      actions: [
        {
          translation: "admin.modify",
          translationParams: { name: "name", id: "id" },
          icon: "pen",
          type: "success",
          actionType: "router",
          action: "adminModuleSkillReferentialEdition",
          cfaStyle: false,
          access: accessManager.EDIT,
        },
        {
          translation: "admin.delete",
          translationParams: { name: "name", id: "id" },
          icon: "trash",
          type: "danger",
          action: "remove",
          cfaStyle: false,
          access: accessManager.DELETE,
        },
      ],
    };
  },
  computed: {
    ...mapState(useTriadStore, {
      currentReferential: (store) => store.currentReferential,
    }),
  },
  mounted() {
    this.init();
  },
  watch: {},
  methods: {
    init() {
      this.setParams();
      this.getReferentialList();
    },

    setParams() {
      if (!this.listParams || !Object.keys(this.listParams).length) {
        this.listParams = {
          search: "",
          sortByField: 0,
          sortOrder: "ASC",
          limit: 10,
          offset: 0,
        };
      }
    },

    refresh(params) {
      this.listParams = params;
      this.init();
    },

    getReferentialList() {
      this.listParams["search"] = formManager.getQuery(
        this.listParams["search"],
      );

      this.loading = true;
      adminManager
        .list("skill/referential", false, this.listParams)
        .then((response) => {
          this.loading = false;
          if (response) {
            this.columns = sortableTableManager.getEntriesColumns(
              response.columns,
              this.columns,
            );
            this.referentials = response.data;
          }
        });
    },

    accessManager() {
      return accessManager;
    },

    deleteReferential(payload) {
      notificationManager
        .showAlert(
          "error",
          this.$t("studea_manager.delete_referential_confirmation"),
          "",
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            const url =
              "/app/admin/generic/skill-referential/delete/" + payload.id;
            apiConnection.delete(url).then(() => {
              this.loading = true;
              this.init();
            });
          }
        });
    },

    ...mapActions(useTriadStore, ["setCurrentReferential"]),
  },
};
</script>

<style lang="scss" scoped>
.referential {
  &__list {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    background-color: #f1f2f3;
    color: var(--primary-color);
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    @include up-to-md {
      margin-right: 0;
      margin-bottom: 10px;
      width: 100%;
    }

    &.-active {
      color: white;
      background-color: var(--primary-color);

      .btn-icon {
        color: white;
      }
    }
  }

  &__button {
    text-align: left;
    border: none;
    background-color: inherit;
    color: inherit;

    @include mobile {
      width: 70%;
    }
  }
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;
  padding: 20px;
}

.empty-session {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.list__button.btn-icon {
  svg {
    width: 22px;
    height: 22px;
  }
}
</style>
